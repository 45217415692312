import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Button,TextField, SwitchField, View, Text } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';
import axios from 'axios'

export default function Checkr4({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [count, setCount] = useState(0);
  const [requestCopy, updateRequestCopy] = React.useState(false);
  const [full_name, updateFullname] = React.useState("");
  const [todays_date, updateTodaysDate] = React.useState("");
  const [checkr_authorization_ip, updateCheckrAuthorizationIp] = React.useState("");

  async function fetchTermsAgreed() {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"checkr4"
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateRes(res)
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
    }
    updateIsLoading(false)
  }

  async function getIp()
  {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    updateCheckrAuthorizationIp(res.data.IPv4)
  }

  async function setCurrentDate()
  {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    updateTodaysDate(date);
  }

  useEffect(() => {
    fetchTermsAgreed();
    setCurrentDate();
    getIp();
  },[count])

  async function setTermsAgree()
  {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "confirm_terms_agree":true,
            "api_endpoint":"checkr4",
            "full_name":full_name,
            "todays_date":todays_date,
            "requestCopy":requestCopy,
            "checkr_authorization_ip":checkr_authorization_ip
        }};
        const res = await API.get('adminWebTools','/ago',myInit);
        console.log('setTermsAgree response: ' + JSON.stringify(res));
        if(typeof isHomepage==='boolean')
        {
          if(isHomepage)
          {
            window.location.reload();
          }
        }
        fetchTermsAgreed();
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
      updateIsLoading(false)
    }
  }
  const renderTermsAgreed = () => {
    if(is_loading){
      return (
        <p>Loading...</p>
      )
    }
    if(!res['terms_agreed_string']){
      return (
        <p>Please review and confirm agreement.</p>
      )
    }
    return (
     <p>{res['terms_agreed_string']}</p>
    )
  }

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Provide E-Signature</Heading>
        {renderTermsAgreed()}
        <br/>
        {res['terms_agreed'] ? undefined : 
        <div style={{paddingTop:50, paddingBottom:15, paddingLeft:'7%', paddingRight:'7%', backgroundColor:'#e6e7ed', maxHeight:700, overflow:'scroll', width:'95%'}}>
            <SwitchField
              onChange={(e) => {
                updateRequestCopy(e.target.checked);
              }}
              value={requestCopy} 
              label={<p style={{fontSize:"0.8em", width:"50%"}}>I would{requestCopy ? undefined : " NOT"} like to receive a copy of an investigative consumer report or consumer credit report at no charge if one is obtained by the Company</p>}
            />
            <br/>
            <br/>
            <p>By providing your E-Signature below, you are electronically signing the document on the previous page of this app, acknowledging and authorizing that Ago Technologies LLC perform a background check on your person.</p>
            <p><TextField
                placeholder='your full name'
                onInput={(e) => updateFullname(e.target.value)}
              ></TextField></p>
            <p><TextField value={todays_date}></TextField></p>
        </div>}
        <br/><br/>
        {res['terms_agreed'] ? undefined : <p><Button onClick={() => setTermsAgree()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5, width:'100%'}}>E-SIGN</Button></p>}
        {is_loading ? <Loading /> : undefined}
    </main>
  );
}