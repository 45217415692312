import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Button } from '@aws-amplify/ui-react';
//import { Button,Text,View,TextField} from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
//import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
//import TableContainer from '../components/tablecontainer';
import ImageUploading from 'react-images-uploading';
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';

export default function UploadLicenseBack({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"Loading...",rows:[]})
  const [count, setCount] = useState(0);
  const [imageUploaded, setImageUploaded] = useState(0);
  const [images, setImages] = React.useState([]);
  const [existing_image_exists, updateExistingImageExists] = React.useState(false);
  const [must_confirm_license, updateMustConfirmLicense] = React.useState(false);
  const [license_number, updateLicenseNumber] = React.useState(false);
  const [existing_image_url, updateExistingImageUrl] = React.useState("");
  const [presigned_url, updatePresignedUrl] = React.useState("");
  const [new_document_name, updateNewDocumentName] = React.useState("");
  const [display_license_on_file, updateDisplayLicenseOnFile] = React.useState(false);
  const [display_update_license, updateDisplayUpdateLicense] = React.useState(false);
  const [display_update_license_alt, updateDisplayUpdateLicenseAlt] = React.useState(false);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const onImageUpload = () => {
    setImageUploaded(1);
  };

    async function fetchExistingUpload() {
        updateIsLoading(true);
        if(typeof isHomepage==='boolean')
        {
          if(isHomepage)
          {
            updateDisplayUpdateLicense(true);
          }
        }
        /*
        const session =  await Auth.currentSession();
        var userEmail = session.idToken.payload.email;
        */
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{api_endpoint:"licenseuploadback", back_end: process.env.REACT_APP_BACKEND, jwt:jwt}};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updatePresignedUrl(res["new_presigned_url"]);
            updateNewDocumentName(res["new_document_name"]);
            if(res["copy_exists"]==true)
            {
                updateExistingImageExists(true);
                updateExistingImageUrl(res["presigned_url"]);
            }
            updateMustConfirmLicense(res["must_confirm_license"]);
            updateLicenseNumber(res["license_number"]);
            updateRes(res)
        }
        catch (e) {
            const res = {status:"Error: " + JSON.stringify(e)}
            updateRes(res)
        }
        updateIsLoading(false)
    }

    useEffect(() => {
        fetchExistingUpload();
    },[count])

    
    async function confirmLicenseNumber(licenseNumber) {
          updateIsLoading(true)
          const res = {status:"loading...",rows:[]};
          updateRes(res);
          let session = await Auth.currentSession()      
          let accessToken = session.getAccessToken()
          let jwt = accessToken.getJwtToken()
          const myInit = {queryStringParameters:{ api_endpoint:"licenseuploadback", back_end: process.env.REACT_APP_BACKEND, jwt:jwt, license_number_confirmed:licenseNumber}};
          try {
            API.get('adminWebTools','/ago',myInit).then((res5) => {
                updateRes(res5);
                fetchExistingUpload();
                return
            });
            if(typeof isHomepage==='boolean')
            {
              if(isHomepage)
              {
                window.location.reload();
              }
            }
          }
          catch (e) {
              var statusString = "Error: " + e;
              updateRes(statusString);
              return
          }
          updateIsLoading(false)

    }
    function toggleDisplayLicenseOnFile()
    {
        if(display_license_on_file)
        {
            updateDisplayLicenseOnFile(false);
        } else {
            updateDisplayLicenseOnFile(true);
        }
    }
    function processSubmitUpload(imageList)
    {
        updateIsLoading(true)
        submitUpload(imageList)
    }
    async function submitUpload(imageList) {
        updateIsLoading(true)
        let session = await Auth.currentSession() 
        const res = {status:"loading...",rows:[]};
        updateRes(res);
        fetch(presigned_url, {
            method:'PUT',
            body:imageList[0]['file'],
            headers: {
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Origin': '*'
            }
        }).then((res) => {
            let accessToken = session.getAccessToken()
            let jwt = accessToken.getJwtToken()
            const myInit = {queryStringParameters:{ api_endpoint:"licenseuploadback", back_end: process.env.REACT_APP_BACKEND, jwt:jwt, document_name:new_document_name}};
            try {
                API.get('adminWebTools','/ago',myInit).then((res3) => {
                    updateRes(res3);
                    window.location.reload();
                    /*
                    fetchExistingUpload();
                    updateMustConfirmLicense(true);
                    updateLicenseNumber(res3["license_number"]);
                    */
                });
            } catch (e) {
                var statusString = "Error uploading image: " + e;
                updateRes(statusString);
            }
        })
        updateIsLoading(false)
  }

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Your Driver's License Back</Heading>
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
        >
            {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors
            }) => (
            // write your building UI
            <div className="upload__image-wrapper">
                {/*must_confirm_license === true ? <div><p>Please confirm your driver's license number:</p><p><input type='text' placeholder='driver license #' name='license_number' value={license_number} onChange={e => updateLicenseNumber(e.target.value)} /></p><p><button onClick={() => confirmLicenseNumber(license_number)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}}>Submit License Number</button></p><br/></div> : existing_image_exists ? <p>We already have a copy of your driver's license back on file, but you can update it if you'd like.</p> : errors ? res['status'] + " - " + JSON.stringify(errors) + JSON.stringify(res) : is_loading ? <p>Loading...</p> : <p>Please submit a picture of the back of your driver's license</p>*/}
                {existing_image_exists ? <p>We already have a copy of your driver's license back on file, but you can use this page to update it if you'd like.</p> : errors ? res['status'] + " - " + JSON.stringify(errors) + JSON.stringify(res) : is_loading ? <p>Loading...</p> : <p>Please submit a picture of the back of your driver's license</p>}
                
                {imageList.length > 0 ? undefined : display_update_license ? <div><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplayUpdateLicense(false)}>Update license (back)</Button><Heading level={4}>To update your license back:</Heading><button
                style={isDragging ? { color: 'red', paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#44BECB",color:"#fff", borderRadius:5 } : { paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5 }}
                onClick={onImageUpload}
                {...dragProps}
                >
                Click or Drop a Photo <br/>of the back of <br/>your Driver's License
                </button></div> : display_update_license_alt ? <div>
    <Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplayUpdateLicenseAlt(false)}>Update license (back)</Button>
    <Heading level={4}>To update your license back:</Heading><br/><button
                style={isDragging ? { color: 'red', paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#44BECB",color:"#fff", borderRadius:5 } : { paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5 }}
                onClick={onImageUpload}
                {...dragProps}
                >
                Click or Drop a Photo <br/>of the back of <br/>your Driver's License
                </button>
  </div> : <Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplayUpdateLicenseAlt(true)}>Update license (back)</Button>}

                {imageList.map((image, index) => (
                <div key={index} className="image-item"><br/><br/>
                    <Heading level={4}>Review and Submit your Upload</Heading>
                    <p>If your upload doesn't match the picture on file, review it and press Submit Picture to upload it.</p>
                    <img src={image['data_url']} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                        <button onClick={() => onImageUpdate(index)}>Update</button>&nbsp;
                        <button onClick={() => onImageRemove(index)}>Remove</button>
                    </div>
                </div>
                ))}

                {imageList.length > 0 ? <div><br/><button onClick={() => processSubmitUpload(imageList)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}}>Submit Picture</button><br/></div> : undefined}
                <br/><br/>

                {existing_image_exists ? display_license_on_file ? <div><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => toggleDisplayLicenseOnFile()}>View license (back) on file</Button><h3>License on File:</h3><img src={existing_image_url} alt='error' width='92%'/></div> : <div><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => toggleDisplayLicenseOnFile()}>View license (back) on file</Button></div>: undefined}
                <br/>
                {/*JSON.stringify(res)*/}
                {/*JSON.stringify(imageList)*/}
                {/*JSON.stringify(presigned_url)*/}
            </div>
            )}
        </ImageUploading>
        {is_loading ? <Loading /> : undefined}
          {/*window.location.pathname === "/" && existing_image_exists ? <p><button style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}} onClick={() => window.location.reload()}>CONTINUE!</button></p> : undefined*/}
        
    </main>
  );
}