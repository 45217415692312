import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
//import { Button,Text,View,TextField} from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
//import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
//import TableContainer from '../components/tablecontainer';
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';

export default function UploadSchedule() {
    let params = useParams();
    const [is_loading, updateIsLoading] = React.useState(false);
    const [res, updateRes] = useState({status:"Loading...",rows:[]});
    const [presigned_url, updatePresignedUrl] = React.useState("");
    const [new_document_name, updateNewDocumentName] = React.useState("");
	const [selectedFile, setSelectedFile] = useState();
	const [schedule_year, updateScheduleYear] = useState("2023");
	const [schedule_month, updateScheduleMonth] = useState("");
	const [isSelected, setIsSelected] = useState(false);
    const [count, setCount] = useState(0);


	const updateFile = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

    async function getPresignedUrl() {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{
            back_end: process.env.REACT_APP_BACKEND, 
            jwt:jwt,
            "api_endpoint":"uploadschedule"
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updatePresignedUrl(res["new_presigned_url"]);
            updateNewDocumentName(res["new_document_name"]);
            updateRes(res)
        }
        catch (e) {
            const res = {status:"Error: " + JSON.stringify(e)}
            updateRes(res)
        }
        updateIsLoading(false)
    }

    useEffect(() => {
        getPresignedUrl();
    },[count])

    async function submitForm()
    {
        updateIsLoading(true);
        const res = {status:"loading..."};
        updateRes(res);
        if(schedule_month!=='')
        {
            if(isSelected)
            {
                let session = await Auth.currentSession() 
                fetch(presigned_url, {
                    method:'PUT',
                    body:selectedFile,
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((res) => {
                    let accessToken = session.getAccessToken()
                    let jwt = accessToken.getJwtToken()
                    var yearMonth = schedule_year + "-" + schedule_month;
                    const myInit = {queryStringParameters:{ 
                        back_end: process.env.REACT_APP_BACKEND, 
                        jwt:jwt, document_name:new_document_name, 
                        "api_endpoint":"uploadschedule",
                        schedule_yyyy_mm:yearMonth
                    }};
                    try {
                        API.get('adminWebTools','/ago',myInit).then((res3) => {
                            updateRes(res3);
                        });
                    } catch (e) {
                        var statusString = "Error uploading file: " + e;
                        updateRes(statusString);
                    }
                })
            } else {
                //  MUST UPLOAD A FILE
                var statusString = "Please select a file for upload before submitting";
                updateRes(statusString);
            }
        } else {
            //  MUST SELECT MONTH
            var statusString = "Please select a month and year before submitting";
            updateRes(statusString);
        }
        updateIsLoading(false);
    }

    function handleUpdateScheduleMonth(value)
    {
        console.log(value);
        updateScheduleMonth(value);
    }
    function handleUpdateScheduleYear(value)
    {
        console.log(value);
        updateScheduleYear(value);
    }
    return (
        <main style={{ padding: "1rem" }}>
            <Heading level={2}>Upload Schedule File</Heading>
            <p>Upload an excel xls of your schedule and we'll integrate it into our system.</p>
            <p><a href='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/fleetScheduleUpload.xlsx' target='_blank'>Download example xls</a></p>
            <Heading level={4}>What year and month is this data for?</Heading>
            <table>
                <tr>
                    <td style={{padding:'10px'}}>Year:</td>
                    <td style={{padding:'10px'}}><select name='schedule_year' onChange={(event) => handleUpdateScheduleYear(event.target.value)} defaultValue='2023'>
                        <option value='2025'>2027</option>
                        <option value='2025'>2026</option>
                        <option value='2025'>2025</option>
                        <option value='2024'>2024</option>
                        <option value='2023'>2023</option>
                        <option value='2022'>2022</option>
                    </select></td>
                </tr>
                <tr>
                    <td style={{padding:'10px'}}>Month:</td>
                    <td style={{padding:'10px'}}><select name='schedule_month' onChange={(event) => handleUpdateScheduleMonth(event.target.value)}>
                        <option value=''>-PLEASE SELECT-</option>
                        <option value='01'>January</option>
                        <option value='02'>February</option>
                        <option value='03'>March</option>
                        <option value='04'>April</option>
                        <option value='05'>May</option>
                        <option value='06'>June</option>
                        <option value='07'>July</option>
                        <option value='08'>August</option>
                        <option value='09'>September</option>
                        <option value='10'>October</option>
                        <option value='11'>November</option>
                        <option value='12'>December</option>
                    </select></td>
                </tr>
            </table><br/>
            <Heading level={4}>Select the file to upload</Heading>
            <p><input type="file" name="file" onChange={updateFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /></p>
            {isSelected ? (
				<div style={{padding:'5px', backgroundColor:'#e6ebf2'}}>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<div style={{padding:'5px', backgroundColor:'#e6ebf2'}}><p>Select a file to show details</p></div>
			)}
            <br/><p><button onClick={() => submitForm()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5, width:'100%'}}>Submit</button></p>
            {is_loading ? <Loading /> : undefined}
            {res['status']==='success' ? undefined : res['status']}
        </main>
    );
}