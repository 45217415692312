import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import TableContainer from '../components/tablecontainer';
import Loading from '../components/loading';
import { Heading, Text } from '@aws-amplify/ui-react';
import { NavLink} from "react-router-dom";

export default function Fleetschedule() {
    let params = useParams();
    const [is_loading, updateIsLoading] = React.useState(false);
    const [res, updateRes] = useState({status:"Loading...",rows:[]});
    const [count, setCount] = useState(0);

    const tableColumns = [
        {
            Header: 'Car VIN',
            accessor: 'vehicle_vin',
        },
        {
            Header: 'Car License',
            accessor: 'vehicle_license',
        },
        {
            Header: 'Driver',
            accessor: 'driver_name',
        },
        {
            Header: 'start_string',
            accessor: 'start_string',
        },
        {
            Header: 'end_string',
            accessor: 'end_string',
        },
        {
            Header: 'status',
            accessor: 'status',
        },
        {
            Header: 'version',
            accessor: 'version',
        },
        {
            Header: 'time_zone',
            accessor: 'time_zone',
        },
        {
            Header: 'external_res_id',
            accessor: 'external_res_id',
        },
        {
            Header: 'res_id',
            accessor: 'res_id',
            Cell: (tableInfo) => {
                    let val = tableInfo.data[tableInfo.row.index].res_id
                    let link = '/fleetscheduleentry/' + String(val) 
                    return( <NavLink to={link} index = {val}>{val}</NavLink>)
                },
            filter: 'text'
        }
    ]

    async function getScheduleUploads() {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{api_endpoint:"fleetschedule", back_end: process.env.REACT_APP_BACKEND, jwt:jwt}};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updateRes(res)
        }
        catch (e) {
            const res = {status:"Error: " + JSON.stringify(e)}
            updateRes(res)
        }
        updateIsLoading(false)
    }

    useEffect(() => {
        getScheduleUploads();
    },[count])

    return (
        <main style={{ padding: "1rem" }}>
            <Heading level={2}>Fleet Schedule</Heading>
            {res['status']==="success" && res['rows'].length > 0 ? <p>View your uploaded schedule.</p> : <p>Loading...</p>}
            <div>
                <button onClick={() => setCount(count + 1)}>
                    PRESS TO GET LATEST UPDATES
                </button>
            </div>
            <Text>
                {res.status !== "success" ? `${res.status}`:``}
            </Text>
            <br/>
            <TableContainer columns={tableColumns} data = {res['rows']} defaultSort = 'end_string'/>
            {is_loading ? <Loading /> : undefined}
            {res['status']==='success' ? undefined : res['status']}
        </main>
    );
}