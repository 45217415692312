import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { View, Heading } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import UploadLicense from '../routes/uploadlicense';
import UploadLicenseBack from '../routes/uploadlicenseback';
import GigIntegrations from '../routes/gigintegrations';
import ChatMessages from '../routes/chatmessages';
import Alerts from '../routes/alerts';
import Dashboard from '../routes/dashboard';
import Dashboardinsurer from '../routes/dashboardinsurer';
import Terms from '../routes/terms';
import Checkr from '../routes/checkr1';
import Checkr4 from '../routes/checkr4';
//import Selfie from '../routes/selfie';
import Indevelopment from '../routes/indevelopment';
import FleetAssignment from './fleetassignment';
import Loading from '../components/loading';

//added here for email verification
import { CognitoIdentityProviderClient, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";
import Profile from './profile'
import AccountInfo from './accountinfo';

export default function Homepage() {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [userGroup, updateUserGroup] = useState('driver');
  //const [userMail, updateUserMail] = useState('');

  //added in here for email updating
  const [email_verified, updateEmailVerified] = useState(false);
  //

  const [existing_image_exists_license_front, updateExistingImageExistsLicenseFront] = useState(false);
  const [existing_image_exists_license_back, updateExistingImageExistsLicenseBack] = useState(false);
  const [argyle_at_least_one, updateArgyleAtLeastOne] = useState(false);
  const [fleet_at_least_one, updateFleetAtLeastOne] = useState(false);
  const [must_agree_terms, updateMustAgreeTerms] = useState(false);
  const [must_agree_checkr_1, updateMustAgreeCheckr1] = useState(false);
  const [must_agree_checkr_2, updateMustAgreeCheckr2] = useState(false);
  const [must_agree_checkr_3, updateMustAgreeCheckr3] = useState(false);
  const [must_agree_checkr_4, updateMustAgreeCheckr4] = useState(false);
  const [selfie_verified, updateSelfieVerified] = useState(false);
  const [must_confirm_info, updateMustConfirmInfo] = useState(false);
  //const [test_display, updateTestDisplay] = useState('');
  const [must_upload_license_front, updateMustUploadLicenseFront] = useState(true);
  const [must_upload_license_back, updateMustUploadLicenseBack] = useState(true);
  const [must_verify_selfie, updateMustVerifySelfie] = useState(true);

  async function fetchExistingLicenseUpload() {
    updateIsLoading(true);
    try {

        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()

        //this is added for email verification
        //this doesnt refresh so switched to cognito - lower level api 
        //let idToken =  session.getIdToken()
        //console.log(idToken)
        //console.log(idToken.payload.email_verified)

        let d  = {
          "AccessToken":jwt,
        } 
        const client = new CognitoIdentityProviderClient({ region: "us-east-2" });
        const command = new GetUserCommand(d);
        const response = await client.send(command);

        const e_v = response.UserAttributes.find(item => item.Name === "email_verified").Value === 'true'
        //console.log(e_v)
        updateEmailVerified(e_v)

        //

        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"licenseupload"
        }};
        const res = await API.get('adminWebTools','/ago',myInit);
        //console.log("account lu: " + JSON.stringify(res));
        if(res["copy_exists"]===true && res["must_confirm_license"]===false)
        {
          updateExistingImageExistsLicenseFront(true);
        }
        if(res["copy_exists_back"]===true )
        {
          updateExistingImageExistsLicenseBack(true);
        }
        if(res["argyle_at_least_one"]===true)
        {
          updateArgyleAtLeastOne(true);
        }
        if(res["fleet_at_least_one"]===true)
        {
          updateFleetAtLeastOne(true);
        }
        if(res["must_agree_terms"]===true)
        {
          updateMustAgreeTerms(true);
        }
        if(res["must_agree_checkr_1"]===true)
        {
          updateMustAgreeCheckr1(true);
        }
        if(res["must_agree_checkr_2"]===true)
        {
          updateMustAgreeCheckr2(true);
        }
        if(res["must_agree_checkr_3"]===true)
        {
          updateMustAgreeCheckr3(true);
        }
        if(res["must_agree_checkr_4"]===true)
        {
          updateMustAgreeCheckr4(true);
        }
        if(res["selfie_verified"]===true)
        {
          updateSelfieVerified(true);
        }
        if(res["must_confirm_info"]===true)
        {
          updateMustConfirmInfo(true);
        }
        if('drivers_must_upload_license_front' in res)
        {
          if(res["drivers_must_upload_license_front"]===true||res["drivers_must_upload_license_front"]==='true'||res["drivers_must_verify_selfie"]===true||res["drivers_must_verify_selfie"]==='true')
          {
            updateMustUploadLicenseFront(true);
          } else {
            updateMustUploadLicenseFront(false);
          }
        }
        if('drivers_must_upload_license_back' in res)
        {
          if(res["drivers_must_upload_license_back"]===true||res["drivers_must_upload_license_back"]==='true'||res["drivers_must_verify_selfie"]===true||res["drivers_must_verify_selfie"]==='true')
          {
            updateMustUploadLicenseBack(true);
          } else {
            updateMustUploadLicenseBack(false);
          }
        }
        if('drivers_must_verify_selfie' in res)
        {
          if(res["drivers_must_verify_selfie"]===true||res["drivers_must_verify_selfie"]==='true')
          {
            updateMustVerifySelfie(true);
          } else {
            updateMustVerifySelfie(false);
          }
        }

    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
    }
    updateIsLoading(false);
  }

  useEffect(() => {    
    const fetchGroup = async ()=> {
      const session =  await Auth.currentSession();
      const groups = session.idToken.payload["cognito:groups"]
      //updateUserMail(session.idToken.payload.email)
      var group = 'driver'
      if (groups !== undefined) {
        group = groups[0]
      } else {
        fetchExistingLicenseUpload()
      }
      updateUserGroup(group)
    }

    fetchGroup()

  },[userGroup])


  var LoadingDiv = <main style={{ padding: "1rem" }}><Heading level={2}>Loading...</Heading><p>One moment while we analyze your account...</p></main>

  var DriverHomepageContent = 
    <View>
          { is_loading === true ? LoadingDiv : 
            email_verified === false ? <Profile isHomepage={true} /> :
            must_agree_terms === true ? <Terms isHomepage={true} /> : 
            fleet_at_least_one === false ? <FleetAssignment isHomepage={true} /> :
            must_agree_checkr_1 === true ? <Checkr isHomepage={"fcra"} /> : 
            must_agree_checkr_2 === true ? <Checkr isHomepage={"disclosure"} /> : 
            must_agree_checkr_3 === true ? <Checkr isHomepage={"authorization"} /> : 
            must_agree_checkr_4 === true ? <Checkr4 isHomepage={true} /> : 
            existing_image_exists_license_front === false && must_upload_license_front === true ? <UploadLicense isHomepage={true} /> : 
            existing_image_exists_license_back === false && must_upload_license_back === true  ? <UploadLicenseBack isHomepage={true} /> : 
            selfie_verified === false  && must_verify_selfie === true ? <Indevelopment isHomepage={true} /> : 
            must_confirm_info === true ? <AccountInfo isHomepage={true} /> : 
            argyle_at_least_one === false ? <GigIntegrations isHomepage={true} /> : <ChatMessages />
          }
    </View>

  var AdminHomepageContent = <View>
    { is_loading === true ? LoadingDiv : 
      <View>
        <div style={{padding:"1rem"}}>
          <Heading level={2}>Welcome back, admin</Heading>
          <p>Explore the menu on the left.</p>
        </div>
      </View> 
    }
  </View>

  var InsuranceHomepageContent = <View>
    { is_loading === true ? LoadingDiv : <Dashboardinsurer />}
</View>


  var SupportHomepageContent = <View>
    { is_loading === true ? LoadingDiv : <Dashboard />}
  </View>

  var FleetHomepageContent = <View>
    { is_loading === true ? LoadingDiv : <Dashboard /> }
  </View>

  return (
      <main style={{ }}>
      {is_loading ? <Loading /> : userGroup==='driver' ? DriverHomepageContent : 
        userGroup==='fleet' ? FleetHomepageContent :
        userGroup==='admin' ? AdminHomepageContent :
        userGroup==='insurer' ? InsuranceHomepageContent :
        userGroup==='support' ? SupportHomepageContent : DriverHomepageContent}
      </main>
    );
}

