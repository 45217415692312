import React, { Component } from 'react'
import {ActivityIndicator, StyleSheet, View} from 'react-native';

class Loadingmini extends Component {
  render () {
    return (
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#092F50" />
        </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});
export default Loadingmini;