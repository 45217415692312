import React from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View} from '@aws-amplify/ui-react';

export default function Vehiclemap({vehicle}) {
  
  function localFormatTime(seconds) {
    console.log('vehiclemap seconds vehicle'+vehicle+' = '+seconds)
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let result = "";
    if (days > 0) {
      result += `${days} day${days > 1 ? "s" : ""}, `;
    }
    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""}, `;
    }
    if (minutes > 0) {
      result += `${minutes} minute${minutes > 1 ? "s" : ""}, `;
    }
    if (remainingSeconds > 0) {
      result += `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`;
    }
  
    return result;
  }

  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
          <Divider />
          {
              vehicle ?
                vehicle.resp ?
                  vehicle.resp[0] ?  
                      <Flex direction="column">
                        {
                            vehicle.map==="" ? 
                              /* TO DO: great place to add check_fix_telem */
                              <Flex direction="column">
                                <Heading level={2}>No Telemetry Data</Heading>
                                <Text>This vehicle isn't sending telemetry yet.</Text>
                                <Text>Contact support for assistance enrolling your vehicle.</Text>
                              </Flex>
                            :
                              <Flex direction="column">
                                <Heading level={2}>GPS 48 hours from Last Known Position</Heading>
                                <Flex direction="column">
                                  <Flex wrap="wrap" justifyContent="center" gap="1rem">
                                    <img src={ vehicle.map_zoom } alt="map" />
                                    <img src={ vehicle.map } alt="map" />
                                  </Flex>
                                  <Flex direction="column">
                                    {"distance" in vehicle.resp[0] ? <Text>{vehicle.resp[0]["distance"]} miles from home</Text> : <Text>unknown distance from home</Text> }
                                    {"position" in vehicle === false ? <Text>Position unknown</Text>
                                      : vehicle.search_url==="" ? <Text>{vehicle.position}</Text>
                                      : <a href={vehicle.search_url} target="_blank" rel='noreferrer'><Text>{vehicle.position}</Text></a>
                                    }
                                    {/*"since_update" in vehicle.resp[0] ? <Text>{localFormatTime(vehicle.resp[0]["since_update"])} since last ping (including non-GPS, non-telemetry data packets)</Text> : undefined*/}
                                  </Flex>
                                </Flex>
                              </Flex>
                          }
                      </Flex>
                  : 
                    <Flex direction="column">
                      <Text>Error loading vehicle data</Text>
                      <Text>No data found for vehicle</Text>
                    </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading vehicle data</Text>
                    <Text>No vehicle data</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading vehicle data</Text>
                  <Text>Vehicle not found</Text>
                </Flex>
            }
        </View>
      </Flex>
  );
}
