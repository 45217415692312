import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button, SelectField} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Userstatushistory({userJwt, refreshJwt, userId, fleetCode, displayAdministrativeFunctions}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const [driverCurrentStatus, updateDriverCurrentStatus] = useState("");
  const [showUpdateDriverStatusForm, updateShowUpdateDriverStatusForm] = useState(false);
  const [newStatus, updateNewStatus] = useState("enabled");
  

  const columns = [
        {
            headerName: 'Status',
            field: 'user_status',
            filter: true
        },
        {
            headerName: 'Datetime',
            field: 'created',
            filter: true
        }
  ];
  

  async function fetchPageData() {
    if(userId > 0)
    {
        updateIsLoading(true)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"driverstatus",
            "driver_id":userId,
            "fleet_code":fleetCode,
            "request_type":"GET"
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('driverstatus response: ' + JSON.stringify(res))
            updateRes(res)
            updateDriverCurrentStatus(res.current_status)
            if('detail' in res)
            {
                if(res['detail'].includes("token"))
                {
                    refreshJwt()
                }
            }
            updateIsLoading(false)
        }
        catch (e) {
            console.log('driverstatushistory error: '+toString(e));
            if(e.response.status === 401)
            {
                refreshJwt()
            }
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }

  async function sendUpdate() {
    if(userId > 0)
    {
        updateIsLoading(true)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"driverstatus",
            "driver_id":userId,
            "fleet_code":fleetCode,
            "request_type":"POST",
            "driver_status":newStatus
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('driverstatus response: ' + JSON.stringify(res))
            updateRes(res)
            updateDriverCurrentStatus(res.current_status)
            if('detail' in res)
            {
                if(res['detail'].includes("token"))
                {
                    refreshJwt()
                }
            }
            updateIsLoading(false)
        }
        catch (e) {
            console.log('driverstatushistory error: '+toString(e));
            if(e.response.status === 401)
            {
                refreshJwt()
            }
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }

  async function hideDriverStatusUpdateForm() {
    updateShowUpdateDriverStatusForm(false)
  }
  async function revealDriverStatusUpdateForm() {
    updateShowUpdateDriverStatusForm(true)
  }


  useEffect(() => {
    fetchPageData()
    },[count, userId, userJwt, fleetCode])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Driver Status History</Heading>
                    <Text style={{width:'100%'}}>This is the history of changes to the driver's status (default active).</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <Heading level={4}>Current Status: {driverCurrentStatus}</Heading>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 500 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    {
                    !showUpdateDriverStatusForm ?
                        displayAdministrativeFunctions === false ? undefined :
                        <View>
                            <Button onClick={() => revealDriverStatusUpdateForm()}>
                                UPDATE STATUS
                            </Button>
                        </View>
                    :
                        <View>
                            <Heading level={4}>Update Driver Status</Heading>
                            <Text>Enabled users can use the system as per usual.</Text>
                            <Text>Suspended users are locked out of the system and unable to take any action in the mobile app.</Text>
                            <Text>Quiet suspended users are unable to create reservations or take certain actions.</Text>
                            <SelectField
                                descriptiveText="Select new status"
                                size="small"
                                value={newStatus}
                                onChange={(e) => updateNewStatus(e.target.value)}
                            >
                                <option value="enabled">enabled</option>
                                <option value="suspended">suspended</option>
                                <option value="quiet_suspended">quiet_suspended</option>
                            </SelectField>
                            <View style={{marginTop:15}}>
                                <Button onClick={() => sendUpdate()}>
                                    SUBMIT
                                </Button>
                            </View>
                            <View style={{marginTop:15}}>
                                <Button onClick={() => hideDriverStatusUpdateForm()}>
                                    CLOSE
                                </Button>
                            </View>
                        </View>
                    }
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
