
import { NavLink} from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { Flex, Divider, View, Text, Heading, Button } from '@aws-amplify/ui-react';
import Loadingmini from '../components/loadingmini';
import { API, Auth } from 'aws-amplify';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Vehicle from './vehicle';
import Fleetselector from "../components/fleetselector";
import Vehicleonboard from "../components/vehicleonboard";


export default function Vehicles() {
    const [vehicles, updateVehicles] = useState({status:"If this message stays - email us at info@joinago.com",rows:[]})
    const [is_loading, updateIsLoading] = React.useState(false);
    const [displaySideDock, updateDisplaySideDock] = React.useState(false);
    const [selectedObjectId, updateSelectedObjectId] = React.useState(false);
    const [tableColumnsView, updateTableColumnsView] = React.useState('default');
    const [fleet_codes, updateFleetCodes] = React.useState(null);
    const [selected_fleet, updateSelectedFleet] = React.useState(false);
    const [displayAdministrativeFunctions, updateDisplayAdministrativeFunctions] = React.useState(false);
    const [displaySuperAdministrativeFunctions, updateDisplaySuperAdministrativeFunctions] = React.useState(false);

    async function selectObject(val) {
        updateDisplaySideDock(false);
        await updateSelectedObjectId(val);
        updateDisplaySideDock(true);
    }

    const columns = [
        {
            headerName: 'ID',
            width: 85,
            field: 'car_id',
            filter: true,
            pinned: 'left',
            cellRenderer: params => {
                let val = params.value
                if(displaySideDock===false)
                {
                    let link = '/vehicle/' + String(val) 
                    return( <NavLink to={link} index = {val}>{val}</NavLink>)
                } else {
                    return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => selectObject(val)}>{val}</Button></Text>)
                }
            }
        },
        {
            headerName: 'Status',
            field: 'vehicle_status',
            filter: true,
            width: 115
        },
        {
            headerName: 'Name',
            field: 'name',
            filter: true
        },
        {
            headerName: 'VIN',
            field: 'vin',
            filter: true
        },
        {
            headerName: 'License Plates',
            field: 'license',
            filter: true
        },
        {
            headerName: 'Region',
            field: 'region',
            filter: true
        },
        {
            headerName: 'Since last Ping',
            field: 'since_update_string',
            filter: true
        },
        {
            headerName: 'Distance from Parking',
            field: 'distance',
            filter: true
        },
        {
            headerName: 'Health Status',
            field:'body.health',
            filter: true
        },
        {
            headerName: 'Health Description',
            field:'body.healthDescription',
            filter: true
        },
        {
            headerName: 'Position Timestamp',
            field:'body.positionStamp',
            filter: true
        },
        {
            headerName: 'Total Insurance Costs',
            field: 'total_insurance_costs',
            filter: true
        },
        {
            headerName: 'Total Miles last 7 days',
            field: 'total_miles',
            filter: true
        },
        {
            headerName: 'Total Hours last 7 days',
            field: 'total_hours',
            filter: true
        },
        {
            headerName: 'Gig Liability Insured Miles',
            field: 'gig_liab_insured_mls',
            filter: true
        },
        {
            headerName: 'Gig Physical Damage Insured Miles',
            field: 'gig_phys_dam_insured_mls',
            filter: true
        },
        {
            headerName: 'Safety Bucket',
            field: 'safety_bucket',
            filter: true
        },
        {
            headerName: 'Safety Score',
            field: 'safety_score',
            filter: true
        },
        {
            headerName: 'Sent Telemetry',
            field: 'car_sent_telemetry',
            filter: true
        },
        {
            headerName: 'Rented in past 7 days',
            field: 'check_if_rented',
            filter: true
        }
    ];

    const columns_alt = [
        {
            headerName: 'ID',
            width: 85,
            field: 'car_id',
            filter: true,
            pinned: 'left',
            cellRenderer: params => {
                let val = params.value
                if(displaySideDock===false)
                {
                    let link = '/vehicle/' + String(val) 
                    return( <NavLink to={link} index = {val}>{val}</NavLink>)
                } else {
                    return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => selectObject(val)}>{val}</Button></Text>)
                }
            }
        },
        {
            headerName: 'Status',
            field: 'vehicle_status',
            filter: true,
            width: 115
        },
        {
            headerName: 'Name',
            field: 'name',
            filter: true
        },
        {
            headerName: 'VIN',
            field: 'vin',
            filter: true
        },
        {
            headerName: 'License Plates',
            field: 'license',
            filter: true
        },
        {
            headerName: 'Region',
            field: 'region',
            filter: true
        },
        {
            headerName: 'Total Insurance Costs',
            field: 'total_insurance_costs',
            filter: true
        },
        {
            headerName: 'Total Miles last 7 days',
            field: 'total_miles',
            filter: true
        },
        {
            headerName: 'Total Hours last 7 days',
            field: 'total_hours',
            filter: true
        },
        {
            headerName: 'Gig Liability Insured Miles',
            field: 'gig_liab_insured_mls',
            filter: true
        },
        {
            headerName: 'Gig Physical Damage Insured Miles',
            field: 'gig_phys_dam_insured_mls',
            filter: true
        },
        {
            headerName: 'Safety Bucket',
            field: 'safety_bucket',
            filter: true
        },
        {
            headerName: 'Safety Score',
            field: 'safety_score',
            filter: true
        },
        {
            headerName: 'Sent Telemetry',
            field: 'car_sent_telemetry',
            filter: true
        },
        {
            headerName: 'Rented in past 7 days',
            field: 'check_if_rented',
            filter: true
        }
    ];

    async function selectNewFleet(val)
    {
        updateSelectedFleet(val)
        fetchPageData(val)
    }

    async function fetchPageData(tSelectedFleet) {
        updateIsLoading(true)
        /*
        let currentAuthUser = await Auth.currentAuthenticatedUser()
        if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"])
        {
            if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='admin'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='support'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='fleet')
            {
                updateDisplayAdministrativeFunctions(true)
                if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='admin')
                {
                    updateDisplaySuperAdministrativeFunctions(true)
                }
            } else {
                updateTableColumnsView("alt")
            }
        }
        */
        var thisFleet = false     
        if(!tSelectedFleet)
        {
            thisFleet = selected_fleet
        } else {
            if(tSelectedFleet==="")
            {
                thisFleet = selected_fleet
            } else {
                thisFleet = tSelectedFleet
            }
        }
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        if(accessToken.payload["cognito:groups"])
        {
            if(accessToken.payload["cognito:groups"][0]==='admin'||accessToken.payload["cognito:groups"][0]==='support'||accessToken.payload["cognito:groups"][0]==='fleet')
            {
                updateDisplayAdministrativeFunctions(true)
                if(accessToken.payload["cognito:groups"][0]==='admin')
                {
                    updateDisplaySuperAdministrativeFunctions(true)
                }
            } else {
                updateTableColumnsView("alt")
            }
        }
        var jwt = await accessToken.getJwtToken()
        var myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"vehiclesget",
        }};
        if(thisFleet)
        {
            myInit = {queryStringParameters:{ 
                "back_end": process.env.REACT_APP_BACKEND,
                "jwt":jwt,
                "api_endpoint":"vehiclesget",
                "fleet_code":thisFleet
            }};
        }
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log(JSON.stringify(res))
            updateVehicles(res)
            updateFleetCodes(res.fleet_codes)
            updateSelectedFleet(res.fleet_code)
            updateIsLoading(false)
        }
        catch (e) {
            const res = {status:"Error retrieving Vehicles or User not permissioned - email us at info@joinago.com: "+toString(e),rows:[]}
            updateVehicles(res)
            updateIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPageData()
      },[])

    return(
        <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
            direction='column'      //direction={{ base: 'column', large: 'row' }}
            //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
            //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
            //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
            wrap="wrap" //nowrap
            gap="1rem"
        >
            <View style={{padding:"1rem"}}>
                <Flex 
                    direction='column'
                >
                    <Heading level={1}>Vehicles</Heading>
                    <Text>These are all the vehicles in your fleet, even if they haven't been on the road yet. Click the blue link to see detailed diagnostics on any vehicle.</Text>
                    <View>
                        <Button onClick={() => fetchPageData()}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Text>
                        {/*vehicles.status !== "success" ? `${vehicles.status}`:``*/}
                    </Text>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    {
                        is_loading ? <Loadingmini /> : 
                        <Flex direction="column">
                            <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem" style={displaySideDock ? {width:"25vw"} : {width:"80vw",maxWidth:"2200px"}}>
                                <Fleetselector fleet_codes={fleet_codes} selected_fleet={selected_fleet} updateParentSelectedFleet={selectNewFleet}/>
                                {
                                    displayAdministrativeFunctions === false ? undefined :
                                    tableColumnsView === 'default' ?
                                        <View>
                                            <Button onClick={() => updateTableColumnsView('alt')}>
                                                DEFAULT VIEW
                                            </Button>
                                        </View>
                                    :
                                        <View>
                                            <Button onClick={() => updateTableColumnsView('default')}>
                                                INSURANCE VIEW
                                            </Button>
                                        </View>
                                }
                                {
                                    displaySuperAdministrativeFunctions === false ? undefined :
                                    <Vehicleonboard fleetCode={selected_fleet} refreshParent={fetchPageData}/>
                                }
                            </Flex>
                            <Flex wrap="wrap" gap="2rem" >
                                <Flex direction="column" style={displaySideDock ? {width:"25vw"} : {width:"80vw",maxWidth:"2200px"}}>
                                    <Divider />
                                    <View 
                                        className="ag-theme-quartz" // applying the grid theme
                                        style={{ height: 750 }} // the grid will fill the size of the parent container
                                    >
                                            <AgGridReact
                                                rowData={vehicles.resp}
                                                columnDefs={tableColumnsView==='default' ? columns : columns_alt}
                                                rowSelection={'multiple'}
                                                paginationPageSize={50}
                                                paginationPageSizeSelector={[10, 25, 50]}
                                                enableCellTextSelection={true}
                                            />
                                    </View>
                                    <Divider />
                                </Flex>
                                {
                                    displaySideDock === false ? 
                                    <Flex direction="column">
                                        <Divider />
                                        <Button onClick={() => updateDisplaySideDock(true)}>
                                            ACTIVATE SIDE DOCK
                                        </Button>
                                        <Divider />
                                    </Flex> 
                                    :
                                    selectedObjectId===false || selectedObjectId < 1 ? <Text><i>Please select a vehicle by clicking its ID in the table...</i></Text> :
                                    <Flex direction="column" style={{width:"55vw"}}>
                                        <Divider />
                                        <Heading level={2}>Vehicle Details</Heading>
                                        <Text><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplaySideDock(false)}>To open vehicles in full-screen mode, click here to hide the side dock.</Button></Text>
                                        <Vehicle paramVehicleId={selectedObjectId} paramFleet={selected_fleet} />
                                        <Divider />
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </View>
        </Flex> 
    );
}
