//import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import Loading from '../components/loading';
import { FaExclamationTriangle, FaUndo, FaWindowClose, FaArrowAltCircleUp, FaArrowAltCircleDown, FaTrashAlt, FaShareSquare, FaPen, FaPlusSquare, FaRegClone, FaRegWindowClose } from 'react-icons/fa';
//import { Alert } from "reactstrap";
import { Button, TextField, SelectField, View, Text, Heading } from '@aws-amplify/ui-react';
//import Chart from 'chart.js/auto';
import {Line} from 'react-chartjs-2';

export default function Widgets({userJwt}) {
  //let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [dashboardArray, updateDashboardArray] = useState([])
  //const [deleteWidgetResults, updateDeleteWidgetResults] = useState({status:"loading...",rows:[]})
  const [resRows, updateResRows] = useState({status:"loading...",rows:[]})
  const [count, setCount] = useState(0);
  const [show_confirm, updateShowConfirm] = React.useState(false);
  const [confirm_text, updateConfirmText] = React.useState("Please confirm.")
  const [confirm_index, updateConfirmIndex] = React.useState(-1)
  const [confirm_action, updateConfirmAction] = React.useState("")
  const [show_create_widget_form, updateShowCreateWidgetForm] = React.useState(false);
  const [show_bottom_banner, updateShowBottomBanner] = React.useState(false);
  const [show_change_to_recent_schedule_view_button, updateShowChangeToRecentScheduleViewButton] = React.useState(false);
  const [show_change_to_default_view_button, updateShowChangeToDefaultViewButton] = React.useState(false);
  const [bottom_banner_header, updateBottomBannerHeader] = React.useState("")
  const [bottom_banner_paragraph_1, updateBottomBannerParagraph1] = React.useState("")
  const [bottom_banner_paragraph_2, updateBottomBannerParagraph2] = React.useState("")
  const [bottom_banner_paragraph_3, updateBottomBannerParagraph3] = React.useState("")
  const [show_edit_widget_form, updateShowEditWidgetForm] = React.useState(false);
  const [schedule_synced, updateScheduleSynced] = React.useState(true);
  const [new_widget_name, updateNewWidgetName] = React.useState("")
  const [new_widget_type, updateNewWidgetType] = React.useState("")
  const [new_widget_source, updateNewWidgetSource] = React.useState("")
  const [new_widget_column, updateNewWidgetColumn] = React.useState("")
  const [new_widget_sort_reverse, updateNewWidgetSortReverse] = React.useState("False")
  const [view_type, updateViewType] = React.useState("default")
  const [fleetwide_data_dictionary, updateFleetwideDataDictionary] = useState([])
  const [vehicle_costs_data_dictionary, updateVehicleCostsDataDictionary] = useState([])
  const [driver_costs_data_dictionary, updateDriverCostsDataDictionary] = useState([])
  const [vehicle_telematics_data_dictionary, updateVehicleTelematicsDataDictionary] = useState([])
  const [billing_history_data_dictionary, updateBillingHistoryDataDictionary] = useState([])
  const [widget_index, updateWidgetIndex] = React.useState(-1)
  const [schedule_period_start_overall, updateSchedulePeriodStartOverall] = React.useState("")
  const [schedule_period_end_overall, updateSchedulePeriodEndOverall] = React.useState("")

  async function fetchPageData() {
    updateIsLoading(true);
    updateViewType("default");
    try {
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":"userwidgets"
        }};
        const res = await API.get('adminWebTools','/ago',myInit)


        
        updateRes(res)
        updateDashboardArray(res['dashboard_array'])
        updateFleetwideDataDictionary(res['fleetwide_data_dictionary'])
        updateVehicleCostsDataDictionary(res['vehicle_costs_data_dictionary'])
        updateDriverCostsDataDictionary(res['driver_costs_data_dictionary'])
        updateVehicleTelematicsDataDictionary(res['vehicle_telematics_data_dictionary'])
        updateBillingHistoryDataDictionary(res['billing_history_data_dictionary'])
        //  CHECK IF FLEET OWNER'S SCHEDULE IS IN SYNC
        if(res['schedule_synced']===false||res['schedule_synced']==='false')
        {
          updateScheduleSynced(false);
          //showBottomBanner("schedule_sync");
        }
        updateSchedulePeriodStartOverall(res['schedulePeriodStart']);
        updateSchedulePeriodEndOverall(res['schedulePeriodEnd']);
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res) 
    }
    updateIsLoading(false)
  }



  useEffect(() => {
    fetchPageData();
  },[count, userJwt])



  
  const renderNewWidgetColumnSelect = () => {
    if(is_loading){
      return (
        <tr>
          <th></th>
          <td>Loading...</td>
          <td></td>
        </tr>
      )
    }
    if(!dashboardArray[0]){
      return (
        <tr>
          <th></th>
          <td>No data points to display.</td>
          <td></td>
        </tr>
      )
    }
    switch(new_widget_source)
    {
      case 'section_header':
        return(
          <option value="section_header">Section Header</option>
        )
      case 'fleetwide':
        return fleetwide_data_dictionary.map(function(object, index){
            return (
              <option value={object['Tag']}>{object['Label']}</option>
            )
        });
      case 'vehicle_costs':
        return vehicle_costs_data_dictionary.map(function(object, index){
            return (
              <option value={object['Tag']}>{object['Label']}</option>
            )
        });
      case 'drivers':
        return driver_costs_data_dictionary.map(function(object, index){
            return (
              <option value={object['Tag']}>{object['Label']}</option>
            )
        });
      case 'vehicle_telematics':
        return vehicle_telematics_data_dictionary.map(function(object, index){
            return (
              <option value={object['Tag']}>{object['Label']}</option>
            )
        });
      case 'billing_history':
        return billing_history_data_dictionary.map(function(object, index){
            return (
              <option value={object['Tag']}>{object['Label']}</option>
            )
        });
      default:
        return(
          <option value="">-- ERROR: INVALID WIDGET SOURCE --</option>
        )
    }
  }
  const renderWidgetTable = (tableData, sort_container, column_name, widget_link) => {
    if(is_loading){
      return (
        <tr>
          <th></th>
          <td>Loading...</td>
          <td></td>
        </tr>
      )
    }
    if(!tableData){
      return (
        <tr>
          <th></th>
          <td>No data to display.</td>
          <td></td>
        </tr>
      )
    }
    return tableData.map(function(object, index){
      var widgetSublink = widget_link;
      if(object['id'])
      {
        widgetSublink = widgetSublink + "/" + object['id'];
      } else {
        if(object['car_id'])
        {
          widgetSublink = "vehicle/" + object['car_id'];
        } else {
          if(object['driver_id'])
          {
            widgetSublink = "user/" + object['driver_id'];
          } else {
            if(object['billing_period_start_end'])
            {
              widgetSublink = "billingentry/" + object['billing_period_start_end'];
            }
          }
        }
      }
        return (
          <tr style={{borderBottom:'1px solid rgba(9, 47, 80, .3)', height:'50px'}}>
            <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px'}}>{object['name_full'] ? object['license'] ? object['name_full'] + " | " + object['license'] : object['name_full'] : object['name_first'] ? object['license'] ? object['name_first'] + " " + object['name_last'] + " | " + object['license'] : object['name_first'] + " " + object['name_last'] : object['name'] ? object['license'] ? object['vin'] ? object['name']  + " | " + object['license'] + " | " + object['vin'].substr(object['vin'].length - 5): object['name'] + " " + object['license'] : object['name'] : object['name_first'] ? object['name_first'] + " " + object['name_last'] : object['license'] ? object['license'] : object['vin'] ? object['vin'] : object['cognito_id'] ? object['cognito_id'] : object['id'] ? "ID " + object['id'] : object['driver_id'] ? "ID " + object['driver_id'] : object['car_id'] ? "ID " + object['car_id'] : object['vehicle_id'] ? "ID " + object['vehicle_id'] : object['billing_period_start'] ? object['billing_period_start'] + " - " + object['billing_period_end'] : 'N/A'}</td>
            <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px'}}>{object[sort_container][column_name]}</td>
            <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px', textAlign:'right'}}><a href={"./" + widgetSublink} target='_blank' rel='noreferrer'><FaShareSquare style={{cursor: 'pointer', color:'#092F50', height:22, width:22}}/></a></td>
          </tr>
        )
    })
  }




  async function createWidget()
  {
    updateIsLoading(true)
    try {
        // We call it bottom_five, but on the back end it's handled via sort_reverse
        // We do this to make the UI/UX better for the end user - less selecting
        var final_new_widget_type = "";
        if(new_widget_type==="bottom_five")
        {
          final_new_widget_type = "top_five";
        } else {
          final_new_widget_type = new_widget_type;
        }
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":'widgetcreate',
            "widget_column":new_widget_column,
            "widget_source":new_widget_source,
            "widget_name":new_widget_name,
            "widget_type":final_new_widget_type,
            'widget_sort_reverse':new_widget_sort_reverse
        }};
        await API.get('adminWebTools','/ago',myInit);
        fetchPageData();
        clearCreateWidgetPopup();
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
        updateIsLoading(false);
    }
  }

  async function editWidget()
  {
    updateIsLoading(true)
    try {
        // We call it bottom_five, but on the back end it's handled via sort_reverse
        // We do this to make the UI/UX better for the end user - less selecting
        var final_new_widget_type = ""
        if(new_widget_type==="bottom_five")
        {
          final_new_widget_type = "top_five";
        } else {
          final_new_widget_type = new_widget_type;
        }
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":'widgetedit',
            "widget_index":widget_index,
            "widget_column":new_widget_column,
            "widget_source":new_widget_source,
            "widget_name":new_widget_name,
            "widget_type":final_new_widget_type,
            'widget_sort_reverse':new_widget_sort_reverse
        }};
        await API.get('adminWebTools','/ago',myInit);
        fetchPageData();
        clearEditWidgetPopup();
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
        updateIsLoading(false);
    }
  }

  function resetWidgets()
  {
      updateShowConfirm(true);
      updateConfirmText("Are you sure you'd like to reset your widgets to default?");
      updateConfirmAction("reset");
      updateConfirmIndex(-1)
  }
  function deleteWidget(widgetIndex)
  {
      updateShowConfirm(true);
      updateConfirmText("Are you sure you'd like to delete this widget?");
      updateConfirmIndex(widgetIndex);
      updateConfirmAction("delete");
  }
  async function moveWidget(widgetIndex,direction)
  {
      // no confirmation necessary
      hideConfirmation()
      try {
          const myInit = {queryStringParameters:{ 
              "back_end": process.env.REACT_APP_BACKEND,
              "jwt":userJwt,
              "api_endpoint":'widgetmove',
              "widget_index":widgetIndex,
              'direction':direction
          }};
          await API.get('adminWebTools','/ago',myInit);
          //updateDeleteWidgetResults(res3);
          //fetchPageData();
          var widgetIndexTarget = 0
          if(direction==='up')
          {
            // up means subtract
            widgetIndexTarget = widgetIndex - 1;
          } else {
            widgetIndexTarget = widgetIndex + 1;
          }
          if(widgetIndexTarget < 0)
          {
            widgetIndexTarget = 0;
          }
          if(widgetIndexTarget >= dashboardArray.length)
          {
            widgetIndexTarget = dashboardArray.length - 1;
          }
          var widgetStartValue = dashboardArray[widgetIndex];
          var widgetTargetValue = dashboardArray[widgetIndexTarget];
          dashboardArray[widgetIndex] = widgetTargetValue;
          dashboardArray[widgetIndexTarget] = widgetStartValue;
          updateDashboardArray(dashboardArray);
          updateConfirmIndex(-1)
          updateConfirmAction("");
          updateConfirmText("Are you sure?");
      }
      catch (e) {
          //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
          //updateRes(res)
      }
  }
  function cancelConfirmation()
  {
    updateShowConfirm(false);
    updateConfirmText("Are you sure?");
    updateConfirmIndex(-1);
    updateConfirmAction("");
  }
  function hideConfirmation()
  {
    updateShowConfirm(false);
  }
  function confirm()
  {
    switch(confirm_action)
    {
      case 'delete':
        confirmedDeleteWidget();
      break;
      case 'reset':
        confirmedResetWidgets();
      break;
      default:
        alert('Error - invalid action.');
      break;
    }
  }
  async function confirmedResetWidgets()
  {
    hideConfirmation()
    try {
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":'widgetsreset'
        }};
        //console.log("deleting widget index " + confirm_index);
        await API.get('adminWebTools','/ago',myInit)
        updateConfirmIndex(-1)
        updateConfirmAction("");
        updateConfirmText("Are you sure?");
        fetchPageData();
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
        updateConfirmIndex(-1)
        updateConfirmAction("");
        updateConfirmText("Are you sure?");
    }
  }
  async function confirmedDeleteWidget()
  {
    hideConfirmation()
    try {
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":'widgetremove',
            "widget_index":confirm_index
        }};
        //console.log("deleting widget index " + confirm_index);
        await API.get('adminWebTools','/ago',myInit)
        //console.log('results: ' + JSON.stringify(res3))
        //updateDeleteWidgetResults(res3)
        dashboardArray.splice(confirm_index,1)
        updateDashboardArray(dashboardArray);
        updateConfirmIndex(-1)
        updateConfirmAction("");
        updateConfirmText("Are you sure?");
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
        updateConfirmIndex(-1)
        updateConfirmAction("");
        updateConfirmText("Are you sure?");
    }
  }

  function hideEditWidgetForm()
  {
    updateShowEditWidgetForm(false);
  }
  function clearEditWidgetPopup()
  {
    updateShowEditWidgetForm(false);
    updateNewWidgetColumn("")
    updateNewWidgetName("");
    updateNewWidgetSortReverse("False");
    updateNewWidgetSource("");
    updateNewWidgetType("");
  }
  function showEditWidgetForm(widgetIndex, widgetName, widgetType, widgetSource, widgetColumn, widgetSortReverse)
  {
    console.log('widgetName: ' + widgetName);
    console.log('widgetType: ' + widgetType);
    console.log('widgetSource: ' + widgetSource);
    console.log('widgetColumn: ' + widgetColumn);
    console.log('widgetSortReverse: ' + widgetSortReverse);
    console.log('widgetIndex: ' + widgetIndex);
    updateWidgetIndex(widgetIndex);
    updateNewWidgetName(widgetName);
    if(widgetType==="top_five" && (widgetSortReverse==="False" || widgetSortReverse==="false" || widgetSortReverse===false))
    {
      widgetType = "bottom_five"
    }
    handleUpdateNewWidgetType(widgetType);
    updateNewWidgetSource(widgetSource);
    updateNewWidgetSortReverse(widgetSortReverse);
    updateNewWidgetColumn(widgetColumn);
    updateShowEditWidgetForm(true);
  }





  function showBottomBanner(bannerType)
  {
    switch(bannerType)
    {
      case 'schedule_sync':
        if(schedule_synced)
        {
          updateBottomBannerHeader("Your schedule is in sync.");
          updateBottomBannerParagraph1("Your schedule data is up to date.");
          updateBottomBannerParagraph2("To upload a schedule, open the navigation drawer on the left. Click Schedules, then Upload Schedule.");
          updateBottomBannerParagraph3("Ago also integrates with rental software to automate this process.");
          updateShowChangeToRecentScheduleViewButton(false);
        } else {
          if(view_type==="default")
          {
            updateBottomBannerHeader("Your schedule is out of sync.");
            updateBottomBannerParagraph1("Your schedule data isn't up to date, so we're displaying data from two time periods:");
            //updateBottomBannerParagraph2("Your widgets are currently displaying the most recent data. To change your view to a 7-day trailing view starting from your most recent schedule data, click the button below.");
            //updateBottomBannerParagraph3("Submit your most recent schedule data to get your schedule in sync.");
            var verbiage = "Every widget with a red icon is showing data trailing from the end of your most recent schedule entry. (" + schedule_period_start_overall + " - " + schedule_period_end_overall + ")";
            updateBottomBannerParagraph2(verbiage);
            updateBottomBannerParagraph3("Widgets without red icons are showing most recent data, since they do not require a schedule to calculate.");
            //updateShowChangeToRecentScheduleViewButton(true);
            updateShowChangeToDefaultViewButton(false);
          } else {
            updateBottomBannerHeader("Your schedule is out of sync.");
            updateBottomBannerParagraph1("Your schedule data isn't up to date.");
            updateBottomBannerParagraph2("Your widgets are currently displaying a 7-day trailing view starting from your most recent schedule data. To switch to the most recent data, even if there's no schedule data to match it, click the button below.");
            updateBottomBannerParagraph3("Vehicle readings like 12v, tire pressure, oil, etc. all still reflect their current values.");
            updateShowChangeToRecentScheduleViewButton(false);
            updateShowChangeToDefaultViewButton(true);
          }
        }
      break;
      default:
        updateBottomBannerHeader("Error");
        updateBottomBannerParagraph1("Invalid banner type selection " + bannerType);
        updateBottomBannerParagraph2("");
        updateBottomBannerParagraph3("");
        updateShowChangeToRecentScheduleViewButton(false);
      break;
    }
    updateShowBottomBanner(true);
  }
  function hideBottomBanner()
  {
    updateShowBottomBanner(false);
    updateBottomBannerParagraph1("");
    updateBottomBannerParagraph2("");
    updateBottomBannerParagraph3("");
    updateShowChangeToRecentScheduleViewButton(false);
  }




  function hideCreateWidgetForm()
  {
    updateShowCreateWidgetForm(false);
  }
  function clearCreateWidgetPopup()
  {
    updateShowCreateWidgetForm(false);
    updateNewWidgetColumn("")
    updateNewWidgetName("");
    updateNewWidgetSortReverse("False");
    updateNewWidgetSource("");
    updateNewWidgetType("");
  }

  function showCreateWidgetForm()
  {
    updateShowCreateWidgetForm(true);
  }

  function handleUpdateNewWidgetType(value)
  {
    updateNewWidgetSource("")
    updateNewWidgetColumn("")
    updateNewWidgetType(value);
    if(value==='top_five')
    {
      updateNewWidgetSortReverse('True');
    } else {
      updateNewWidgetSortReverse('False');
    }
    if(value==='single_value')
    {
      updateNewWidgetSource("fleetwide");
    }
    if(value==='section_header')
    {
      updateNewWidgetSource("section_header");
      updateNewWidgetColumn("section_header");
    }
  }

  const renderWidgets = () => {
    if(is_loading){
      return (
        <p>Loading...</p>
      )
    }
    if(dashboardArray)
    {
      if(!dashboardArray[0]){
        return (
          <p>You have no widgets configured.</p>
        )
      }
      var i = 0;
      return dashboardArray.map(function(widget, index){
        var thisI = i;
        if(widget!==undefined)
        {
          i = i + 1;
          var flag = false;
          if(widget['widget_success']===true||widget['widget_success']==="true")
          {
            switch(widget['widget_type'])
            {
              case 'single_value':
                if(widget['schedule_synced']===true||widget['schedule_synced']==="true")
                {
                  flag = false;
                } else {
                  if(widget['currentOrSchedule']==='schedule')
                  {
                     flag = true;
                  } else {
                    flag = false;
                  }
                  //  NOT SYNCED - ARE WE DISPLAYING MOST RECENT, OR TRAILING FROM?
                  /*
                  if(view_type==='default')
                  {
                    //  WE ARE VIEWING MOST RECENT DATA, SO FLAG widget_requires_recent_schedule_data
                    if(widget['widget_requires_recent_schedule_data']===true||widget['widget_requires_recent_schedule_data']==='true')
                    {
                      flag = true;
                    }
                  } else {
                    //  WE ARE VIEWING TRAILING DATA, SO FLAG widget_requires_recent_telemetry_data AND anything that is from source TELEMETRY (with a separate message)
                    //if(widget['widget_source']==='vehicle_telematics'||widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    //  ACTUALLY - VEHICLE TELEMATICS WILL ALWAYS BE THEIR MOST RECENT VALUE
                    if(widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    {
                      flag = true;
                    }
                  }
                  */
                }
                return (
                  <div style={{width:'350px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'300px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px', position:'relative'}}>
                          <div style={{width:'100%', height:'50px', borderBottom:'1px solid rgba(256, 256, 256, .3)'}}>
                            <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                            <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                            {flag ? <FaExclamationTriangle onClick={() => showBottomBanner("schedule_sync")} style={{cursor: 'pointer', color:'#FF6666', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/> : undefined }
                            {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                            {thisI > dashboardArray.length - 2 ? <FaArrowAltCircleDown style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/> : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                          </div>
                          <a href={"./" + widget['widget_link']} target='_blank' rel='noreferrer' style={{textDecoration:'none'}}><p style={{color:'white', textAlign:'center', paddingTop:'15px',fontSize:24, paddingLeft:'10px', paddingRight:'10px', height:'2em'}}>{widget['widget_name']}</p></a>
                          <p style={{color:'white', textAlign:'center', paddingTop:'45px',fontSize:55,fontWeight:'bold'}}>{widget['widget_value']}</p>
                          <a href={"./" + widget['widget_link']} target='_blank' rel='noreferrer' style={{textDecoration:'none'}}><p style={{color:'white', textAlign:'center', paddingTop:'45px',fontSize:24}}>VIEW MORE</p></a>
                          {/*<p style={{color:'white', textAlign:'center', paddingTop:'0px',fontSize:24}}>{widget['currentOrScheduleString']}</p>*/}
                        </div>
                      </div>
                  </div>
                )
              break;
              case 'top_five':
                if(widget['schedule_synced']===true||widget['schedule_synced']==="true")
                {
                  flag = false;
                } else {
                  if(widget['currentOrSchedule']==='schedule')
                  {
                     flag = true;
                  } else {
                    flag = false;
                  }
                  //  NOT SYNCED - ARE WE DISPLAYING MOST RECENT, OR TRAILING FROM?
                  /*
                  if(view_type==='default')
                  {
                    //  WE ARE VIEWING MOST RECENT DATA, SO FLAG widget_requires_recent_schedule_data
                    if(widget['widget_requires_recent_schedule_data']===true||widget['widget_requires_recent_schedule_data']==='true')
                    {
                      flag = true;
                    }
                  } else {
                    //  WE ARE VIEWING TRAILING DATA, SO FLAG widget_requires_recent_telemetry_data AND anything that is from source TELEMETRY (with a separate message)
                    //if(widget['widget_source']==='vehicle_telematics'||widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    //  ACTUALLY - VEHICLE TELEMATICS WILL ALWAYS BE THEIR MOST RECENT VALUE
                    if(widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    {
                      flag = true;
                    }
                  }
                  */
                }
                return (
                  <div style={{width:'700px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'650px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
                          <div style={{width:'100%', height:'50px'}}>
                            <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                            <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                            {flag ? <FaExclamationTriangle onClick={() => showBottomBanner("schedule_sync")} style={{cursor: 'pointer', color:'#FF6666', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/> : undefined }
                            {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                            {thisI > dashboardArray.length - 2 ? <FaArrowAltCircleDown style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/> : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                            {thisI < 1 ? <FaArrowAltCircleUp style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/> : undefined}
                          </div>
                          <div style={{justifyContent:'center', alignItems:'center', marginTop:'-50px', height:'50px', width:'100%', marginBottom:'15px' }}>
                            <a href={"./" + widget['widget_link']} target='_blank' style={{overflow:'hidden', textDecoration:'none', textAlign:'center'}}><p style={{overflow:'hidden', height:'50px', color:'white', textAlign:'center', paddingTop:'20px',fontSize:24}}>{widget['widget_name']}</p></a>
                          </div>
                          <div style={{width:'95%', height:'75%', backgroundColor:'white', borderRadius:'15px', marginLeft:'2.5%', padding:'10px', overflow:'scroll'}}>
                            <table style={{width:'100%'}}>
                              <tr><th style={{textDecoration:'none',color:'black'}}>{widget['widget_value'][0] ? widget['widget_value'][0]['name_full'] ? widget['widget_value'][0]['license'] ? "Name Full | License" : "Name Full" : widget['widget_value'][0]['name_first'] ? widget['widget_value'][0]['license'] ? "Name | License" : "Name" : widget['widget_value'][0]['name'] ? widget['widget_value'][0]['license'] ? widget['widget_value'][0]['vin'] ? "Name | License | VIN" : "Name | License " : "Name" : widget['widget_value'][0]['name'] ? "Name" : widget['widget_value'][0]['license'] ? "License" : widget['widget_value'][0]['vin'] ? "VIN" : widget['widget_value'][0]['cognito_id'] ? "Cognito ID" : widget['widget_value'][0]['id'] ? "ID" : widget['widget_value'][0]['driver_id'] ? "Driver ID" : widget['widget_value'][0]['car_id'] ? "Car ID" : widget['widget_value'][0]['vehicle_id'] ? "Vehicle ID" : widget['widget_value'][0]['billing_period_start'] ? "Billing Period" : "Identifier" : "Identifier"}</th><th style={{textDecoration:'none',color:'black'}}>{widget['column_name']}</th></tr>
                              {renderWidgetTable(widget['widget_value'],widget['sort_container'],widget['column_name'],widget['widget_link'])}
                            </table>
                          </div>
                        </div>
                      </div>
                  </div>
                )
              break;
              case 'graph_line_time':
                if(widget['schedule_synced']===true||widget['schedule_synced']==="true")
                {
                  flag = false;
                } else {
                  if(widget['currentOrSchedule']==='schedule')
                  {
                     flag = true;
                  } else {
                    flag = false;
                  }
                  //  NOT SYNCED - ARE WE DISPLAYING MOST RECENT, OR TRAILING FROM?
                  /*
                  if(view_type==='default')
                  {
                    //  WE ARE VIEWING MOST RECENT DATA, SO FLAG widget_requires_recent_schedule_data
                    if(widget['widget_requires_recent_schedule_data']===true||widget['widget_requires_recent_schedule_data']==='true')
                    {
                      flag = true;
                    }
                  } else {
                    //  WE ARE VIEWING TRAILING DATA, SO FLAG widget_requires_recent_telemetry_data AND anything that is from source TELEMETRY (with a separate message)
                    //if(widget['widget_source']==='vehicle_telematics'||widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    //  ACTUALLY - VEHICLE TELEMATICS WILL ALWAYS BE THEIR MOST RECENT VALUE
                    if(widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    {
                      flag = true;
                    }
                  }
                  */
                }
                return (
                  <div style={{width:'700px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'650px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
  
  
                          <div style={{width:'100%', height:'50px'}}>
                            <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                            <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                            {flag ? <FaExclamationTriangle onClick={() => showBottomBanner("schedule_sync")} style={{cursor: 'pointer', color:'#FF6666', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/> : undefined }
                            {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                            {thisI > dashboardArray.length - 2 ? <FaArrowAltCircleDown style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/> : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                            {thisI < 1 ? <FaArrowAltCircleUp style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/> : undefined}
                          </div>
                          <div style={{justifyContent:'center', alignItems:'center', marginTop:'-50px', height:'50px', width:'100%', marginBottom:'15px' }}>
                            <a href={"./" + widget['widget_link']} target='_blank' style={{overflow:'hidden', textDecoration:'none', textAlign:'center'}}><p style={{overflow:'hidden', height:'50px', color:'white', textAlign:'center', paddingTop:'20px',fontSize:24}}>{widget['widget_name']}</p></a>
                          </div>
                          <div style={{width:'95%', height:'75%', backgroundColor:'white', borderRadius:'15px', marginLeft:'2.5%', padding:'10px', overflow:'scroll', paddingLeft:'75px'}}>
                            {/*WIDGET_VALUE MUST BE LIKE:      
                              {
                                labels:['January','February','March','April','May'],
                                datasets:[
                                  {
                                    label:'widgetcolumnname', 
                                    fill:false, 
                                    lineTension:0.5, 
                                    backgroundColor:'#092F50', 
                                    borderWidth:2, 
                                    data:[65, 59, 80, 81, 56]
                                  }
                                ]
                              }      
                            */}
                            <Line data={widget['widget_value']}  options={{
                                    title:{
                                      display:false,
                                      text:'',
                                      fontSize:20
                                    },
                                    legend:{
                                      display:false,
                                      position:'right'
                                    }
                                }}/>
                          </div>
                        </div>
                      </div>
                  </div>
                )
              break;
              default:
                if(widget['schedule_synced']===true||widget['schedule_synced']==="true")
                {
                  flag = false;
                } else {
                  if(widget['currentOrSchedule']==='schedule')
                  {
                     flag = true;
                  } else {
                    flag = false;
                  }
                  //  NOT SYNCED - ARE WE DISPLAYING MOST RECENT, OR TRAILING FROM?
                  /*
                  if(view_type==='default')
                  {
                    //  WE ARE VIEWING MOST RECENT DATA, SO FLAG widget_requires_recent_schedule_data
                    if(widget['widget_requires_recent_schedule_data']===true||widget['widget_requires_recent_schedule_data']==='true')
                    {
                      flag = true;
                    }
                  } else {
                    //  WE ARE VIEWING TRAILING DATA, SO FLAG widget_requires_recent_telemetry_data AND anything that is from source TELEMETRY (with a separate message)
                    //if(widget['widget_source']==='vehicle_telematics'||widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    //  ACTUALLY - VEHICLE TELEMATICS WILL ALWAYS BE THEIR MOST RECENT VALUE
                    if(widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                    {
                      flag = true;
                    }
                  }
                  */
                }
                return (
                    <div style={{width:'700px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'650px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
                          <div style={{width:'100%', height:'50px'}}>
                            <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                            <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                            {flag ? <FaExclamationTriangle onClick={() => showBottomBanner("schedule_sync")} style={{cursor: 'pointer', color:'#FF6666', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/> : undefined }
                            {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                            {thisI > dashboardArray.length - 2 ? <FaArrowAltCircleDown style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/> : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                            {thisI < 1 ? <FaArrowAltCircleUp style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/> : undefined}
                          </div>
                          <a href={"./" + widget['widget_link']} target='_blank' rel='noreferrer' style={{overflow:'hidden', height:'50px', width:'65%', textDecoration:'none', marginTop:'-50px'}}><p style={{overflow:'hidden', height:'50px', width:'65%', color:'white', textAlign:'center', paddingTop:'20px',fontSize:24, marginTop:'-50px'}}>{widget['widget_name']}</p></a>
                          <div style={{width:'95%', height:'75%', backgroundColor:'white', borderRadius:'15px', marginLeft:'2.5%', padding:'10px', overflow:'scroll'}}>
                            <table style={{width:'100%'}}>
                              <tr>
                                <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px'}}>{widget['column_name']}</td>
                                <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px'}}>{widget['error']}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                )
              break;
            }
          } else {

            if(widget['widget_type']==="section_header_1" )
            {
              return (
                  <div>
                    <br/><br/>
                    <div style={{width:'650px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                      <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", borderRadius:'15px', backgroundColor:'#4e5254', width:'650px', height:'75px',marginLeft:'25px'}}>
                        <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                        <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                        {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                        {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                      </div>
                      <p style={{overflow:'hidden', height:'100px', width:'480px', color:'white', textAlign:'center', paddingTop:'20px',fontSize:28, marginTop:'-83px', overflow:'hidden'}}>{widget['widget_name']}</p>
                    </div>
                  </div>
                )
            } else {
              if(widget['widget_type']==="section_header_2")
              {
                return (
                    <div>
                      <br/><br/>
                      <div style={{width:'1350px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", borderRadius:'15px', backgroundColor:'#4e5254', width:'1350px', height:'75px',marginLeft:'25px'}}>
                          <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                          <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                          {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                          {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                        </div>
                        <p style={{overflow:'hidden', height:'100px', width:'1180px', color:'white', textAlign:'center', paddingTop:'20px',fontSize:28, marginTop:'-83px', overflow:'hidden'}}>{widget['widget_name']}</p>
                      </div>
                    </div>
                  )
              } else {
                if(widget['widget_type']==="section_header_3")
                {
                  return (
                      <div>
                        <br/><br/>
                        <div style={{width:'2050px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                          <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", borderRadius:'15px', backgroundColor:'#4e5254', width:'2050px', height:'75px',marginLeft:'25px'}}>
                            <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                            <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                            {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                            {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                          </div>
                          <p style={{overflow:'hidden', height:'100px', width:'1875px', color:'white', textAlign:'center', paddingTop:'20px',fontSize:28, marginTop:'-83px', overflow:'hidden'}}>{widget['widget_name']}</p>
                        </div>
                      </div>
                    )
                } else {
                  if(widget['widget_type']==="section_header_1_alt" || widget['widget_type']=='section_header')
                  {
                    return (
                        <div>
                          <br/><br/>
                          <div style={{width:'650px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                            <div style={{bbackgroundColor:'white', width:'650px', height:'75px',marginLeft:'25px'}}>
                              <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                              <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                              {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                              {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                            </div>
                            <p style={{overflow:'hidden', height:'75px', width:'480px', color:'#092F50', textAlign:'left', paddingTop:'20px',fontSize:28, marginTop:'-83px', overflow:'hidden', paddingLeft:'25px', borderBottom:'1px solid #092F50'}}>{widget['widget_name']}</p>
                          </div>
                        </div>
                      )
                  } else {
                    if(widget['widget_type']==="section_header_2_alt")
                    {
                      return (
                          <div>
                            <br/><br/>
                            <div style={{width:'1350px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                              <div style={{backgroundColor:'white', width:'1350px', height:'75px',marginLeft:'25px'}}>
                                <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                                <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                                {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                                {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                              </div>
                              <p style={{overflow:'hidden', height:'75px', width:'1180px', color:'#092F50', textAlign:'left', paddingTop:'20px',fontSize:36, marginTop:'-83px', overflow:'hidden', paddingLeft:'25px', borderBottom:'1px solid #092F50'}}>{widget['widget_name']}</p>
                            </div>
                          </div>
                        )
                    } else {
                      if(widget['widget_type']==="section_header_3_alt")
                      {
                        return (
                            <div>
                              <br/><br/>
                              <div style={{width:'2050px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                                <div style={{backgroundColor:'white', width:'2050px', height:'75px',marginLeft:'25px'}}>
                                  <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                                  <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                                  {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                                  {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                                </div>
                                <p style={{overflow:'hidden', height:'75px', width:'1875px', color:'#092F50', textAlign:'left', paddingTop:'20px',fontSize:36, marginTop:'-83px', overflow:'hidden', paddingLeft:'25px', borderBottom:'1px solid #092F50'}}>{widget['widget_name']}</p>
                              </div>
                            </div>
                          )
                      } else {
                        if(widget['widget_type']==="section_header_1_alt_2")
                        {
                          return (
                              <div>
                                <br/><br/>
                                <div style={{width:'650px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                                  <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", borderRadius:'15px', backgroundColor:'white', width:'650px', height:'75px',marginLeft:'25px'}}>
                                    <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                                    <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                                    {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                                    {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                                  </div>
                                  <p style={{overflow:'hidden', height:'100px', width:'480px', color:'#092F50', textAlign:'center', paddingTop:'20px',fontSize:28, marginTop:'-83px', overflow:'hidden'}}>{widget['widget_name']}</p>
                                </div>
                              </div>
                            )
                        } else {
                          if(widget['widget_type']==="section_header_2_alt_2")
                          {
                            return (
                                <div>
                                  <br/><br/>
                                  <div style={{width:'1350px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                                    <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", borderRadius:'15px', backgroundColor:'white', width:'1350px', height:'75px',marginLeft:'25px'}}>
                                      <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                                      <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                                      {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                                      {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                                    </div>
                                    <p style={{overflow:'hidden', height:'100px', width:'1180px', color:'#092F50', textAlign:'center', paddingTop:'20px',fontSize:36, marginTop:'-83px', overflow:'hidden'}}>{widget['widget_name']}</p>
                                  </div>
                                </div>
                              )
                          } else {
                            if(widget['widget_type']==="section_header_3_alt_2")
                            {
                              return (
                                  <div>
                                    <br/><br/>
                                    <div style={{width:'2050px',height:'120px', display:'inline-block', verticalAlign:'top'}}>
                                      <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", borderRadius:'15px', backgroundColor:'white', width:'2050px', height:'75px',marginLeft:'25px'}}>
                                        <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                                        <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                                        {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                                        {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                                      </div>
                                      <p style={{overflow:'hidden', height:'100px', width:'1875px', color:'#092F50', textAlign:'center', paddingTop:'20px',fontSize:36, marginTop:'-83px', overflow:'hidden'}}>{widget['widget_name']}</p>
                                    </div>
                                  </div>
                                )
                            } else {
                              if(widget['schedule_synced']===true||widget['schedule_synced']==="true")
                              {
                                flag = false;
                              } else {
                                if(widget['currentOrSchedule']==='schedule')
                                {
                                    flag = true;
                                } else {
                                  flag = false;
                                }
                                //  NOT SYNCED - ARE WE DISPLAYING MOST RECENT, OR TRAILING FROM?
                                /*
                                if(view_type==='default')
                                {
                                  //  WE ARE VIEWING MOST RECENT DATA, SO FLAG widget_requires_recent_schedule_data
                                  if(widget['widget_requires_recent_schedule_data']===true||widget['widget_requires_recent_schedule_data']==='true')
                                  {
                                    flag = true;
                                  }
                                } else {
                                  //  WE ARE VIEWING TRAILING DATA, SO FLAG widget_requires_recent_telemetry_data AND anything that is from source TELEMETRY (with a separate message)
                                  //if(widget['widget_source']==='vehicle_telematics'||widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                                  //  ACTUALLY - VEHICLE TELEMATICS WILL ALWAYS BE THEIR MOST RECENT VALUE
                                  if(widget['widget_requires_recent_telemetry_data']===true||widget['widget_requires_recent_telemetry_data']==='true')
                                  {
                                    flag = true;
                                  }
                                }
                                */
                              }
                              return (
                                <div style={{width:'700px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                                  <div style={{width:'650px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                                    <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
                                      <div style={{width:'100%', height:'50px'}}>
                                        <FaTrashAlt onClick={() => deleteWidget(thisI)} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'15px', height:23, width:23, float:'right'}}/>
                                        <FaPen onClick={() => showEditWidgetForm(thisI, widget['widget_name'], widget['widget_type'], widget['widget_source'], widget['column_name'], widget['widget_sort_reverse'])} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/>
                                        {flag ? <FaExclamationTriangle onClick={() => showBottomBanner("schedule_sync")} style={{cursor: 'pointer', color:'#FF6666', marginTop:'15px', marginRight:'20px', height:23, width:23, float:'right'}}/> : undefined }
                                        {thisI < 1 ? undefined : <FaArrowAltCircleUp onClick={() => moveWidget(thisI,"up")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/>}
                                        {thisI > dashboardArray.length - 2 ? undefined : <FaArrowAltCircleDown onClick={() => moveWidget(thisI,"down")} style={{cursor: 'pointer', color:'white', marginTop:'15px', marginLeft:'20px', height:23, width:23, float:'left'}}/>}
                                        {thisI < 1 ? <FaArrowAltCircleUp style={{cursor: 'pointer', color:'#092F50', marginTop:'15px', marginLeft:'15px', height:23, width:23, float:'left'}}/> : undefined}
                                      </div>
                                      <a href={"./" + widget['widget_link']} target='_blank' rel='noreferrer' style={{overflow:'hidden', height:'50px', width:'65%', textDecoration:'none', marginTop:'-50px'}}><p style={{overflow:'hidden', height:'50px', width:'65%', color:'white', textAlign:'center', paddingTop:'20px',fontSize:24, marginTop:'-50px'}}>{widget['widget_name']}</p></a>
                                      <div style={{width:'95%', height:'75%', backgroundColor:'white', borderRadius:'15px', marginLeft:'2.5%', padding:'10px', overflow:'scroll'}}>
                                        <table style={{width:'100%'}}>
                                          <tr>
                                            <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px'}}>{widget['column_name']}</td>
                                            <td style={{textDecoration:'none',color:'black',paddingRight:'20px', fontSize:18, maxWidth:'350px'}}>{widget['error']}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              )
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            
          }
        }
      })
    } else {
      return (
        <p>You have no widgets configured.</p>
      )
    }
  }


  return (
    <main style={{ padding: "1rem" }}>
      {
        is_loading ? <Loadingmini /> : 
        <div>
          {<div style={{width:'100%'}}>{renderWidgets()}
                  {is_loading ? undefined : <div style={{width:'350px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'300px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
                          <div style={{width:'100%', height:'50px', borderBottom:'1px solid rgba(256, 256, 256, .3)'}}>
                          </div>
                          <p style={{color:'white', textAlign:'center', paddingTop:'15px',fontSize:24, paddingLeft:'10px', paddingRight:'10px', height:'2em'}}>ADD A WIDGET</p>
                          <p style={{color:'white', textAlign:'center', paddingTop:'45px',fontSize:55,fontWeight:'bold'}}><FaPlusSquare onClick={() => showCreateWidgetForm()} style={{cursor: 'pointer', color:'white', height:80, width:80}}/></p>
                        </div>
                      </div>
                  </div>}
                  {is_loading ? undefined : <div style={{width:'350px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'300px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
                          <div style={{width:'100%', height:'50px', borderBottom:'1px solid rgba(256, 256, 256, .3)'}}>
                          </div>
                          <p style={{color:'white', textAlign:'center', paddingTop:'15px',fontSize:24, paddingLeft:'10px', paddingRight:'10px', height:'2em'}}>SYNC SCHEDULE</p>
                          <p style={{color:'white', textAlign:'center', paddingTop:'45px',fontSize:55,fontWeight:'bold'}}><FaRegClone onClick={() => showBottomBanner('schedule_sync')} style={{cursor: 'pointer', color:'white', height:80, width:80}}/></p>
                        </div>
                      </div>
                  </div>}
                  {is_loading ? undefined : <div style={{width:'350px',height:'400px',margin:'auto',display:'inline-block',verticalAlign:'top'}}>
                      <div style={{width:'300px',height:'350px',display:'inline-block',marginLeft:'25px',marginRight:'25px',marginBottom:'25px'}}>
                        <div style={{boxShadow:"rgba(0, 0, 0, 5) 0px 5px 15px", width:'100%', height:'102%', backgroundColor:'#092F50', borderRadius:'15px'}}>
                          <div style={{width:'100%', height:'50px', borderBottom:'1px solid rgba(256, 256, 256, .3)'}}>
                          </div>
                          <p style={{color:'white', textAlign:'center', paddingTop:'15px',fontSize:24, paddingLeft:'10px', paddingRight:'10px', height:'2em'}}>RESET WIDGETS</p>
                          <p style={{color:'white', textAlign:'center', paddingTop:'45px',fontSize:55,fontWeight:'bold'}}><FaUndo onClick={() => resetWidgets()} style={{cursor: 'pointer', color:'white', height:80, width:80}}/></p>
                        </div>
                      </div>
                  </div>}</div>}
          <br/>
          <p style={{maxWidth:'500px'}}>{/*JSON.stringify(deleteWidgetResults)*/}</p>
          {show_create_widget_form ? 
            <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:50}}>
              <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px', paddingLeft:'20px', paddingRight:'20px' }}>
                <p style={{textAlign:'center', fontWeight:'bold', borderBottom:'1px solid #092F50'}}>CREATE A NEW WIDGET</p>
                {/*{"column": "TotIns$", "source": "fleetwide", "required": "False", "widget_name": "7-DAY TOTAL COST", "widget_type": "single_value", "sort_reverse": "False"}*/}
                <p style={{textAlign:'center'}}><TextField
                  value={new_widget_name}
                  label="Widget Name"
                  descriptiveText="Name your widget, minding the length."
                  hasError={new_widget_name==="" ? true : false}
                  labelHidden={new_widget_name==="" ? false : true}
                  errorMessage="This is a required field"
                  placeholder="Widget Name"
                  onChange={(e) => updateNewWidgetName(e.currentTarget.value)}
                /></p>
                <p style={{textAlign:'center'}}><SelectField
                  value={new_widget_type}
                  label="Widget Type"
                  descriptiveText="Select a widget type, how the data is displayed."
                  hasError={new_widget_type==="" ? true : false}
                  labelHidden={new_widget_type==="" ? false : true}
                  errorMessage="This is a required field"
                  onChange={(e) => handleUpdateNewWidgetType(e.currentTarget.value)}
                >
                  <option value="">-- SELECT TYPE --</option>
                  <option value="single_value">Single Value</option>
                  <option value="top_five">Top 5</option>
                  <option value="bottom_five">Bottom 5</option>
                  <option value="section_header">Section Header</option>
                </SelectField></p>
                { new_widget_type==="" ? undefined : 
                  <div>
                    <p style={{textAlign:'center'}}>
                      {
                        new_widget_type==='single_value' ? 
                                    <SelectField
                                      value={new_widget_source}
                                      label="Source"
                                      descriptiveText="Select the type of data you're looking for."
                                      hasError={new_widget_source==="" ? true : false}
                                      labelHidden={new_widget_source==="" ? false : true}
                                      errorMessage="This is a required field"
                                      onChange={(e) => updateNewWidgetSource(e.currentTarget.value)}
                                    >
                                      <option value="">-- PLEASE SELECT --</option>
                                      <option value="fleetwide">Fleetwide Stats</option>
                                    </SelectField>
                        : new_widget_type==='top_five' || new_widget_type==='bottom_five' ? 
                                    <SelectField
                                      value={new_widget_source}
                                      label="Source"
                                      descriptiveText="Select the type of data you're looking for."
                                      hasError={new_widget_source==="" ? true : false}
                                      labelHidden={new_widget_source==="" ? false : true}
                                      errorMessage="This is a required field"
                                      onChange={(e) => updateNewWidgetSource(e.currentTarget.value)}
                                    >
                                      <option value="">-- PLEASE SELECT --</option>
                                      <option value="vehicle_costs">Cars - Insurance Costs & Risk</option>
                                      <option value="vehicle_telematics">Cars - Telematics</option>
                                      <option value="drivers">Drivers</option>
                                      <option value="billing_history">Billing History</option>
                                    </SelectField>
                        : new_widget_type==='section_header' ? undefined
                        :
                                    <SelectField
                                      value={new_widget_source}
                                      label="Source"
                                      descriptiveText="Select the type of data you're looking for."
                                      hasError={new_widget_source==="" ? true : false}
                                      labelHidden={new_widget_source==="" ? false : true}
                                      errorMessage="This is a required field"
                                      onChange={(e) => updateNewWidgetSource(e.currentTarget.value)}
                                    >
                                      <option value="">-- SELECT TYPE FIRST --</option>
                                    </SelectField>
                      }
                    </p>
                    {
                      new_widget_source === "" ? undefined : 
                      <div>
                        {new_widget_source=== "section_header" ? undefined :
                        <p style={{textAlign:'center'}}><SelectField
                          value={new_widget_column}
                          label="Data"
                          descriptiveText="Select the data point to return or sort by."
                          hasError={new_widget_column==="" ? true : false}
                          labelHidden={new_widget_column==="" ? false : true}
                          errorMessage="This is a required field"
                          onChange={(e) => updateNewWidgetColumn(e.currentTarget.value)}
                        >
                          <option value="">-- PLEASE SELECT --</option>
                          {renderNewWidgetColumnSelect()}
                        </SelectField></p>}
                        {
                          new_widget_column == "" ? undefined : 
                          <div>
                            <br/>
                            <p style={{textAlign:'center'}}><Button onClick={() => createWidget()} style={{paddingTop:10, paddingBottom:10, paddingLeft:30, paddingRight:30, color:"#092F50", borderRadius:5, width:'100%'}}>SUBMIT</Button></p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                <br/><br/>
                <p style={{textAlign:'center'}}><Button onClick={() => hideCreateWidgetForm()} style={{paddingTop:10, paddingBottom:10, paddingLeft:30, paddingRight:30, color:"#44BECB", borderRadius:5, width:'100%'}}>CANCEL</Button></p>
              </div>
            </div>
          : undefined}


          {show_edit_widget_form ? 
            <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:50}}>
              <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px', paddingLeft:'20px', paddingRight:'20px' }}>
                <p style={{textAlign:'center', fontWeight:'bold', borderBottom:'1px solid #092F50'}}>EDIT YOUR WIDGET</p>
                <p style={{textAlign:'center'}}><TextField
                  value={new_widget_name}
                  label="Widget Name"
                  descriptiveText="Name your widget, minding the length."
                  hasError={new_widget_name==="" ? true : false}
                  labelHidden={new_widget_name==="" ? false : true}
                  errorMessage="This is a required field"
                  placeholder="Widget Name"
                  onChange={(e) => updateNewWidgetName(e.currentTarget.value)}
                /></p>
                <p style={{textAlign:'center'}}><SelectField
                  value={new_widget_type}
                  label="Widget Type"
                  descriptiveText="Select a widget type, how the data is displayed."
                  hasError={new_widget_type==="" ? true : false}
                  labelHidden={new_widget_type==="" ? false : true}
                  errorMessage="This is a required field"
                  onChange={(e) => handleUpdateNewWidgetType(e.currentTarget.value)}
                >
                  <option value="">-- SELECT TYPE --</option>
                  <option value="single_value">Single Value</option>
                  <option value="top_five">Top 5</option>
                  <option value="bottom_five">Bottom 5</option>
                  <option value="section_header">Section Header</option>
                </SelectField></p>
                { new_widget_type==="" ? undefined : 
                  <div>
                    <p style={{textAlign:'center'}}>
                      {
                        new_widget_type==='single_value' ? 
                                    <SelectField
                                      value={new_widget_source}
                                      label="Source"
                                      descriptiveText="Select the type of data you're looking for."
                                      hasError={new_widget_source==="" ? true : false}
                                      labelHidden={new_widget_source==="" ? false : true}
                                      errorMessage="This is a required field"
                                      onChange={(e) => updateNewWidgetSource(e.currentTarget.value)}
                                    >
                                      <option value="">-- PLEASE SELECT --</option>
                                      <option value="fleetwide">Fleetwide Stats</option>
                                    </SelectField>
                        : new_widget_type==='top_five' || new_widget_type==='bottom_five' ? 
                                    <SelectField
                                      value={new_widget_source}
                                      label="Source"
                                      descriptiveText="Select the type of data you're looking for."
                                      hasError={new_widget_source==="" ? true : false}
                                      labelHidden={new_widget_source==="" ? false : true}
                                      errorMessage="This is a required field"
                                      onChange={(e) => updateNewWidgetSource(e.currentTarget.value)}
                                    >
                                      <option value="">-- PLEASE SELECT --</option>
                                      <option value="vehicle_costs">Cars - Insurance Costs & Risk</option>
                                      <option value="vehicle_telematics">Cars - Telematics</option>
                                      <option value="drivers">Drivers</option>
                                      <option value="billing_history">Billing History</option>
                                    </SelectField>
                        : new_widget_type==='section_header' ? undefined
                        :
                                    <SelectField
                                      value={new_widget_source}
                                      label="Source"
                                      descriptiveText="Select the type of data you're looking for."
                                      hasError={new_widget_source==="" ? true : false}
                                      labelHidden={new_widget_source==="" ? false : true}
                                      errorMessage="This is a required field"
                                      onChange={(e) => updateNewWidgetSource(e.currentTarget.value)}
                                    >
                                      <option value="">-- SELECT TYPE FIRST --</option>
                                    </SelectField>
                      }
                    </p>
                    {
                      new_widget_source === "" ? undefined : 
                      <div>
                        {new_widget_source=== "section_header" ? undefined :
                        <p style={{textAlign:'center'}}><SelectField
                          value={new_widget_column}
                          label="Data"
                          descriptiveText="Select the data point to return or sort by."
                          hasError={new_widget_column==="" ? true : false}
                          labelHidden={new_widget_column==="" ? false : true}
                          errorMessage="This is a required field"
                          onChange={(e) => updateNewWidgetColumn(e.currentTarget.value)}
                        >
                          <option value="">-- PLEASE SELECT --</option>
                          {renderNewWidgetColumnSelect()}
                        </SelectField></p>}
                        {
                          new_widget_column == "" && new_widget_source !== 'section_header' ? undefined : 
                          <div>
                            <br/>
                            <p style={{textAlign:'center'}}><Button onClick={() => editWidget()} style={{paddingTop:10, paddingBottom:10, paddingLeft:30, paddingRight:30, color:"#092F50", borderRadius:5, width:'100%'}}>SUBMIT</Button></p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                <br/><br/>
                <p style={{textAlign:'center'}}><Button onClick={() => hideEditWidgetForm()} style={{paddingTop:10, paddingBottom:10, paddingLeft:30, paddingRight:30, color:"#44BECB", borderRadius:5, width:'100%'}}>CANCEL</Button></p>
              </div>
            </div>
          : undefined}
          {show_confirm ? 
            <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
              <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                <p style={{textAlign:'center', fontWeight:'bold'}}>Are you sure?</p>
                <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>{confirm_text}</p>
                <p style={{textAlign:'center'}}><Button onClick={() => confirm()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>CONFIRM</Button></p>
                <p style={{textAlign:'center'}}><Button onClick={() => cancelConfirmation()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></p>
              </div>
            </div>
          : undefined}
          {show_bottom_banner ? 
            <div style={{width:'100%', height:'400px', position:'fixed', bottom:'0px', left:'0px', backgroundColor:'#111111', opacity:'0.97', paddingLeft:'10%', paddingRight:'10%', paddingTop:'50px', paddingBottom:'25px'}}>
                <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center'}}>
                  <div style={{width:'100%', maxWidth:'900px'}}>
                    <FaRegWindowClose onClick={() => hideBottomBanner()} style={{cursor: 'pointer', color:'white', height:40, width:40, float:'right', marginTop:'5px'}}/>
                    <h2 style={{color:'#FFF'}}>{bottom_banner_header}</h2>
                    <p style={{color:'#FFF', width:'80%', textAlign:'justify'}}>{bottom_banner_paragraph_1}</p>
                    <p style={{color:'#FFF', width:'80%', textAlign:'justify'}}>{bottom_banner_paragraph_2}</p>
                    <p style={{color:'#FFF', width:'80%', textAlign:'justify'}}>{bottom_banner_paragraph_3}</p>
                    {/*show_change_to_recent_schedule_view_button ? <p><Button onClick={() => fetchTrailingMostRecentScheduleView()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>View Trailing from Most Recent Schedule</Button></p> : undefined*/}
                    {/*show_change_to_default_view_button ? <p><Button onClick={() => fetchPageData()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>View Most Recent Data</Button></p> : undefined*/}
                  </div>
                </div>
            </div>
          : undefined}

        </div>
      }
    </main>
  );
}