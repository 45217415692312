import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Flex, Divider, View, Text, Heading, Button } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import TableContainer from '../components/tablecontainer';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { FaAngleDoubleRight, FaAngleDoubleDown } from 'react-icons/fa';
import Loading from '../components/loading';
import { NavLink} from "react-router-dom";

export default function Billinghistory() {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"Loading...",rows:[]})
  const [count, setCount] = useState(0);



  const tableCols = [
    {
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FaAngleDoubleDown /> : <FaAngleDoubleRight />}
        </span>
      ),
    },
    {
        Header: 'Period Start',
        accessor: 'BilLStTstamp',
    },
    {
        Header: 'Period End',
        accessor: 'BillEndTstamp',
    },
    {
        Header: 'Insured Cars',
        accessor: 'InsuredCarCount',
    },
    {
        Header: 'Total Miles',
        accessor: 'MlsTot',
    },
    {
        Header: 'Total $ Per Car',
        accessor: 'Tot$PerCar',
    },
    {
        Header: 'Total $ Without Safety Adjust',
        accessor: 'Tot$NoSafeAdj',
    },
    {
        Header: 'Total $ With Safety Adjust',
        accessor: 'Tot$SafeAdj',
    },
    {
        Header: 'Cents per Mile With Safety Adjust',
        accessor: 'CentsPerMileWithSafetyAdjust',
    },
    {
        Header: '% Miles not LIABILITY Gig Insured',
        accessor: 'PercentMilesNotLiabilityGigInsured',
    },
    {
        Header: '% Miles not PHYSICAL DAMAGE Gig Insured',
        accessor: 'PercentMilesNotPhysicalDamageGigInsured',
    },
    {
        Header: 'History',
        accessor: 'billing_period_start_end',
        Cell: (tableInfo) => {
                let val = tableInfo.data[tableInfo.row.index].billing_period_start_end
                let link = '/billingentry/' + String(val) 
                return( <NavLink to={link} index = {val}>History</NavLink>)
            },
        filter: 'text'
    }
]

  async function fetchData() {
    updateIsLoading(true)
    /*
    var i = 0;
    var totalCost = 0;
    var aveCost = "loading...";
    const session =  await Auth.currentSession();
    var userEmail = session.idToken.payload.email;
    */
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ "api_endpoint":"billinghistory", back_end: process.env.REACT_APP_BACKEND, jwt:jwt}};
    try {
        const res = await API.get('adminWebTools','/ago',myInit);
        updateRes(res);
        updateIsLoading(false)
        return
    }
    catch (e) {
        var statusString = "Error: " + e;
        const res = {status:statusString,rows:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }
  useEffect(() => {
    fetchData();
  },[])



  var NoData = <Text>No data to display.</Text>

  return (
    <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction='column'      //direction={{ base: 'column', large: 'row' }}
        //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
        //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
        //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
        wrap="wrap" //nowrap
        gap="1rem"
    >
        <View style={{padding:"1rem"}}>
            {
                is_loading ? <Loading /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={1}>Billing History</Heading>
                    {
                      res['history_exists'] < 1 ? 
                        <Text>{res['message']}</Text>
                      : 
                        <Flex direction='column'>
                          <Text style={{width:'100%'}}>Your most recent billing history is displayed below.</Text>
                          <View>
                              <Button onClick={() => setCount(count + 1)}>
                                  PRESS TO GET LATEST UPDATES
                              </Button>
                          </View>
                          <Text>
                              {res.status !== "success" ? `${res.status}`:``}
                          </Text>
                          <Divider />
                          {res['fleet_owner'] === "true" ? <TableContainer columns={tableCols} data = {res['rows']} defaultSort = 'BillEndTstamp' renderRowSubComponent={row => {
                            return (
                              <View style={{padding:"20px"}}>
                                  <Heading level={2}>Billing History</Heading>
                                  <table>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Created:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['created']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Fleet ID:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['FleetID']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Analysed:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['AnalyisTstamp']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Billing Period Start:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['BilLStTstamp']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Billing Period End:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['BillEndTstamp']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Insured Cars Count:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['InsuredCarCount']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Total Miles:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{isNaN(row['original']['MlsTot']) ? row['original']['MlsTot'] : row['original']['MlsTot'].toLocaleString("en-US")}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Liability $ No Safety Adjust:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Liab$NoSafeAdj']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Liability $ Safety Adjust:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['Liab$SafeAdj']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Liability $ Per Car:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Liab$PerCar']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Cents Per Mile with Safety Adjust:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['CentsPerMileWithSafetyAdjust']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Liability Gig Insured Miles:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['LiabGigInsMls']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Liability Gig Uninsured Miles:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['LiabGigUninsMls']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Liability Personal Miles:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['LiabPersMls']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage $ No Safety Adjust:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDam$NoSafeAdj']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Physical Damage $ Safety Adjust:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDam$SafeAdj']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage $ Per Car:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDam$PerCar']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Cents Per Physical Damage Mile:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['CentsPerPhysDamMile']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage Gig Insured Miles:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDamGigInsMls']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Physical Damage Gig Uninsured Miles:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDamGigUninsMls']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Physical Damage Personal Miles:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['PhysDamPersMls']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Total $ Per Car:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Tot$PerCar']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>Total $ No Safety Adjust:  </th>
                                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['Tot$NoSafeAdj']}</td>
                                    </tr>
                                    <tr>
                                      <th style={{padding:'5px'}}>Total $ Safety Adjust:  </th>
                                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['Tot$SafeAdj']}</td>
                                    </tr>
                                  </table>
                                  <br/>
                              </View>
                            );
                          }}/> : NoData}
                          <ReactTooltip />
                        </Flex>
                    }
                    <Divider />
                </Flex>
            }
        </View>
    </Flex>
  );
}