import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import Loading from '../components/loading';
import Loadingmini from '../components/loadingmini';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Chatmessages from './chatmessages';
import Widgets from './widgets';
import Alerts from './alerts';

export default function Dashboard() {
    const [is_loading, updateIsLoading] = React.useState(false);
    const [count, setCount] = useState(0);
    const [jwt, setJwt] = React.useState(false);
    
    async function fetchPageData() {
      updateIsLoading(true);
      try {
          let session = await Auth.currentSession()      
          let accessToken = await session.getAccessToken()
          let jwt = await accessToken.getJwtToken()
          setJwt(jwt)
      }
      catch (e) {
          //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
          //updateRes(res) 
      }
      updateIsLoading(false);
    }

    useEffect(() => {
      fetchPageData();
    },[count])





  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            {
                is_loading ? <Loading /> : 
                jwt === false ? <Loading /> :
                <Flex 
                    direction='column'
                >
                    <Alerts userJwt={jwt} refreshJwt={() => setCount(count + 1)} />
                    <Chatmessages userJwt={jwt} refreshJwt={() => setCount(count + 1)} />
                    <Widgets userJwt={jwt} />
                </Flex>
            }
        </View>
      </Flex>
  );
}
