import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button, TextField, SelectField} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Userdeposits({userJwt, refreshJwt, userId, fleetCode}) {
    const [is_loading, updateIsLoading] = React.useState(false);
    const [display_form, updateDisplayForm] = React.useState(false);
    const [count, setCount] = useState(0);
    const [depositCommand, setDepositCommand] = useState('INFO');
    const [amountOnDeposit, setAmountOnDeposit] = useState("-");
    const [lastDepositDate, setLastDepositDate] = useState("-");
    const [actionRequired, updateActionRequired] = useState("");
    const [incrementDeposit, updateIncrementDeposit] = useState("");
    const [reasonForPost, updateReasonForPost] = useState("");
    const [postResults, updatePostResults] = useState(false);
    const [driverConfig, updateDriverConfig] = useState("required");
    const [configAmount, updateConfigAmount] = useState(0.00);
    const [requiredOrWaived, updateRequiredOrWaived] = useState("required");
    
    //const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  
    const isFloat = (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value);
      };  
  

  async function fetchPageData() {
    if(userId > 0)
    {
        updateIsLoading(true)
        setAmountOnDeposit("-")
        setLastDepositDate("-")
        updateActionRequired("")
        updateIncrementDeposit("")
        updateRequiredOrWaived("")
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"deposit",
            "driver_id":userId,
            "request_type":"GET",
            "command":"INFO",
            "fleet":fleetCode
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('deposits user ' + userId + ' : ' + JSON.stringify(res))
            //updateRes(res)
            setAmountOnDeposit(res.deposits[0]['amount_on_deposit'])
            setLastDepositDate(res.deposits[0]['last_deposit_date'])
            updateActionRequired(res.deposits[0]['action_required'])
            updateIncrementDeposit(res.deposits[0]['incremental_deposit'])
            updateRequiredOrWaived(res.deposits[0]['deposit_config_status'])
        }
        catch (e) {
            console.log('deposits error: '+toString(e));
            setAmountOnDeposit('error loading deposits')
            setLastDepositDate('-')
            updateActionRequired("-")
            updateIncrementDeposit("-")
            updateRequiredOrWaived("-")
            /*
            if(e.response.status === 401)
            {
                refreshJwt()
            }
            */
        }
        updateIsLoading(false)
    }
  }

  async function submitForm() {
    if(userId > 0)
    {
        updateIsLoading(true)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": userJwt,
            "api_endpoint":"deposit",
            "driver_id":userId,
            "request_type":"POST",
            "fleet":fleetCode,
            "command":depositCommand,
            "reason":reasonForPost,
            "driver_config":driverConfig,
            "amount":configAmount
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updatePostResults(res)
            fetchPageData();
        }
        catch (e) {
            console.log('deposits error: '+toString(e));
            updatePostResults(e)
        }
        updateIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count, userId, userJwt, fleetCode])



      
    
  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Driver Deposits</Heading>
                    <Text style={{width:'100%'}}>This is where you can view and issue commands relating to driver deposits.</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    {/*<Text><i>Hint: If Action is Required, driver must deposit the amount of Increment Deposit.</i></Text>*/}
                    <Heading level={4}>Amount on Deposit:  {amountOnDeposit}</Heading>
                    <Text>Last Deposit Date:  {lastDepositDate}</Text>
                    <Text>Required/Waived:  {requiredOrWaived}</Text>
                    {/*<Text>Action Required:  {actionRequired}</Text>
                    <Text>Increment Deposit:  {incrementDeposit}</Text>*/}
                    {display_form === false ? 
                        <Flex direction="column" gap="1rem" >
                            <Button onClick={() => updateDisplayForm(true)}>MANAGE DEPOSITS</Button>
                        </Flex>
                    : 
                        <Flex direction="column" gap="1rem" >
                            <Heading level={4}>Manage Deposits</Heading>
                            <Text>Complete the form below to manage this driver's deposits.</Text>
                            <SelectField
                                descriptiveText="What is your command?"
                                size="small"
                                value={depositCommand}
                                onChange={(e) => setDepositCommand(e.target.value)}
                            >
                                <option value="">-- SELECT --</option>
                                <option value="REFUND">REFUND</option>
                                <option value="ADJUST">ADJUST AMOUNT</option>
                                <option value="CONFIG_DRIVER_DEPOSIT">REQUIRE/BYPASS</option>
                            </SelectField>
                            {
                                depositCommand==="REFUND"||depositCommand==="ADJUST"||depositCommand==="CONFIG_DRIVER_DEPOSIT" ?
                                    <View>
                                        <Text>Why are you taking this action?</Text>
                                        <TextField
                                            placeholder='reason for taking this action'
                                            onInput={(e) => updateReasonForPost(e.target.value)}
                                            defaultValue={reasonForPost}
                                        ></TextField>
                                    </View>
                                :
                                    <Text><i>Please select an option above</i></Text>
                            }
                            {
                                depositCommand === "REFUND" ?
                                <View>
                                    <Text>Refund the driver's deposit (back to their payment card) by the amount you specify.</Text>
                                    <TextField
                                        placeholder='0.00'
                                        onInput={(e) => updateConfigAmount(e.target.value)}
                                        defaultValue={configAmount}
                                    ></TextField>
                                    <br/>
                                    {
                                        isFloat(configAmount) ? 
                                            <View style={{marginTop:15}}>
                                                <Button onClick={() => submitForm()}>SUBMIT</Button>
                                            </View>
                                        : <Text><i>Please enter a valid amount, like 50.00</i></Text>
                                    }

                                </View>
                                : depositCommand === "ADJUST" ?
                                <View>
                                    <Text>Grant an adjustment to the driver's deposit balance.</Text>
                                    <Text><i>If a driver has a balance of $100.00, typing -50.00, <br/>their deposit balance will become $50.00</i></Text>
                                    <Text><i>If a driver has a balance of $100.00 and you type 50.00, <br/>their deposit balance will become $150.00 (without charging their card)</i></Text>
                                    <TextField
                                        placeholder='0.00'
                                        onInput={(e) => updateConfigAmount(e.target.value)}
                                        defaultValue={configAmount}
                                    ></TextField>
                                    <br/>
                                    {
                                        isFloat(configAmount) ? 
                                            <View style={{marginTop:15}}>
                                                <Button onClick={() => submitForm()}>SUBMIT</Button>
                                            </View>
                                        : <Text><i>Please enter a valid amount, like 50.00</i></Text>
                                    }
                                </View>
                                : depositCommand === "CONFIG_DRIVER_DEPOSIT" ?
                                <View>
                                    <SelectField
                                        descriptiveText="Require or waive deposit?"
                                        size="small"
                                        value={driverConfig}
                                        onChange={(e) => updateDriverConfig(e.target.value)}
                                    >
                                        <option value="required">required</option>
                                        <option value="waived">waived</option>
                                    </SelectField>
                                    {
                                        driverConfig === "required" ? <Text>Driver will be required to pay a deposit.</Text>
                                        : driverConfig === "waived" ? <Text>Driver will NOT be required to pay a deposit.</Text>
                                        : <Text>Error - invalid driver configuration selected.</Text>
                                    }
                                    <br/>
                                    <View style={{marginTop:15}}>
                                        <Button onClick={() => submitForm()}>SUBMIT</Button>
                                    </View>
                                </View>
                                : undefined
                            }
                            <br/>
                            {
                                !postResults ? undefined :
                                <Text>{JSON.stringify(postResults)}</Text>
                            }
                            
                            <br/>
                            <View><Button onClick={() => updateDisplayForm(false)}>CLOSE</Button></View>
                            
                        </Flex>
                    }
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
