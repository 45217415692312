import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text,View,Flex} from '@aws-amplify/ui-react';
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';
import TableContainer from '../components/tablecontainer';
import { FaQuestionCircle, FaAngleDoubleRight, FaAngleDoubleDown } from 'react-icons/fa';

export default function Fleetscheduleentry() {
  let params = useParams();
  const [res, updateRes] = useState({status:"If this message stays - email us at info@joinago.com",rows:[]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);


    const tableCols = [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <FaAngleDoubleDown /> : <FaAngleDoubleRight />}
          </span>
        ),
      },
      {
          Header: 'Car VIN',
          accessor: 'vehicle_vin',
      },
      {
          Header: 'Car License',
          accessor: 'vehicle_license',
      },
      {
          Header: 'Driver',
          accessor: 'driver_name',
      },
      {
          Header: 'start_string',
          accessor: 'start_string',
      },
      {
          Header: 'end_string',
          accessor: 'end_string',
      },
      {
          Header: 'status',
          accessor: 'status',
      },
      {
          Header: 'version',
          accessor: 'version',
      },
      {
          Header: 'time_zone',
          accessor: 'time_zone',
      },
      {
          Header: 'external_res_id',
          accessor: 'external_res_id',
      }
  ]
  useEffect(() => {
    const fetchDataentry = async () => {
      updateIsLoading(true)
      let entryIdentifier = params.entryIdentifier
      console.log('entryIdentifier:  ' + entryIdentifier);
      let session = await Auth.currentSession()      
      let accessToken = session.getAccessToken()
      let jwt = accessToken.getJwtToken()
      const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        "api_endpoint":"fleetschedule",
        "jwt":jwt,
        "res_id": entryIdentifier
      }};
      try {
          const res = await API.get('adminWebTools','/ago',myInit);
          updateRes(res);
          console.log(JSON.stringify(res.rows,null, 4))
          updateIsLoading(false)
          return
      }
      catch (e) {
          const res = {status:"Error - email us at info@joinago.com",rows:[]};
          updateRes(res);
          updateIsLoading(false)
          return
      }
    }

    fetchDataentry()

  },[count,params])

  var NoData = <p>No data to display.</p>

  return (
      <main style={{ padding: "1rem" }}>
        <Heading level={2}>Fleet Schedule Entry Versions</Heading>
        {res['status']==="success" && res['rows'].length > 0 ? <p>Below is a history of the changes for {res['rows'][0]['res_id']}.</p> : <p>Loading...</p>}
        <div>
              <button onClick={() => setCount(count + 1)}>
                  PRESS TO GET LATEST UPDATES
              </button>
        </div>
        <Text>
              {res.status !== "success" ? `${res.status}`:``}
        </Text>
        <br/>
        {res['status'] === "success" ? <TableContainer columns={tableCols} data = {res['rows']} defaultSort = 'version' renderRowSubComponent={row => {
            return (
              <div style={{padding:"20px"}}>
                  <h3>Details</h3>
                  <table>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>res_id:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['res_id']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>external_res_id:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['external_res_id']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>vehicle_id:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['vehicle_id']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>driver_id:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['driver_id']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>fleet_owner_id:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['fleet_owner_id']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>insurance_policies_id:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['insurance_policies_id']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>version:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['version']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>start:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['start']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>end:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['end']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>start_string:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['start_string']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>end_string:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['end_string']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>max_version_ext:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['max_version_ext']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>status:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['status']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>time_zone:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['time_zone']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>driver_name:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['driver_name']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>driver_license:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['driver_license']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>vehicle_name:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['vehicle_name']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>vehicle_license:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['vehicle_license']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>vehicle_vin:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['vehicle_vin']}</td>
                    </tr>
                    <tr>
                      <th style={{padding:'5px'}}>insurance_policy_company:  </th>
                      <td style={{padding:'5px', paddingLeft:'20px'}}>{row['original']['insurance_policy_company']}</td>
                    </tr>
                    <tr>
                      <th style={{backgroundColor:'#e8e9ed', padding:'5px'}}>insurance_policy_details:  </th>
                      <td style={{backgroundColor:'#e8e9ed', padding:'5px', paddingLeft:'20px'}}>{row['original']['insurance_policy_details']}</td>
                    </tr>
                  </table>
                  <br/>
              </div>
            );
          }}/>: NoData}

        <br/><pre id="json"><p>{/*JSON.stringify(res.rows,null, 4)*/}</p></pre>

        {is_loading ? <Loading /> : undefined}
      </main>
    );
}

