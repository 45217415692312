import React from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, View, Text, Heading} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid


export default function Usercommercialplatformaccounts({user}) {
  const columns = [
    {
        headerName: 'Platform',
        field: 'platform',
        filter: true
    },
    {
        headerName: 'Connection',
        field: 'connect_status',
        filter: true
    },
    {
        headerName: 'Verification',
        field: 'int_verif',
        filter: true
    },
    {
        headerName: 'Last Status Change',
        field: 'job_updated',
        filter: true
    },
    {
        headerName: 'Platform Account ID',
        field: 'job_account',
        filter: true
    },
    {
        headerName: 'Overall Account ID',
        field: 'job_user_id',
        filter: true
    },
    {
        headerName: 'Error Code',
        field: 'error_code',
        filter: true
    }
  ];

  function formatDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var day = days[a.getDay()];
    if(hour > 12)
    {
      var thour = hour - 12
      var pmam = ' PM';
    } else {
      if(hour < 12)
      {
        if(hour < 1)
        {
          var thour = '12';
          var pmam = ' AM';
        } else {
          var thour = hour;
          var pmam = ' AM';
        }
      } else {
        var thour = '12';
        var pmam = ' PM';
      }
    }
    var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
    return time;
  }


  return (
    <Flex
      direction="column"
    >
      <View style={{padding:"1rem"}}>
          <Divider />
          {
              typeof(user) === 'undefined' ?  
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>User not found</Text>
                </Flex>
              :
              typeof(user.resp) === 'undefined' ?  
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>User not found</Text>
                </Flex>
              :
              typeof(user.resp[0]) === 'undefined' ?  
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>User not found</Text>
                </Flex>
              :
              typeof(user.resp[0]["user_commercial_accounts"]) === 'undefined' ?  
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>User not found</Text>
                </Flex>
              :
              user === false ?
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>User not found</Text>
                </Flex>
              :
              user.resp === false ?
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>No commercial account data</Text>
                </Flex>
              :
              user.resp[0] === false ?
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>No commercial account data</Text>
                </Flex>
              :
              user.resp[0]["user_commercial_accounts"] === false ?
                <Flex direction="column">
                  <Heading level={2}>No Commercial Platform Accounts</Heading>
                  <Text>Error loading user data</Text>
                  <Text>No commercial account data</Text>
                </Flex>
              : 
              <Flex 
                  direction='column'
              >
                  <Heading level={2}>Commercial Platform Accounts</Heading>
                  <Text style={{width:'100%'}}>Driver's connected commercial platform (gig) accounts and their status.</Text>
                  <Divider />
                  <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                  <View style={{width:"100%"}}>
                      <View 
                          className="ag-theme-quartz" // applying the grid theme
                          style={{ height: 500 }} // the grid will fill the size of the parent container
                      >
                              <AgGridReact
                                  rowData={user.resp[0]["user_commercial_accounts"]}
                                  columnDefs={columns}
                                  rowSelection={'multiple'}
                                  paginationPageSize={500}
                                  paginationPageSizeSelector={[10, 25, 50]}
                                  enableCellTextSelection={true}
                              />
                      </View>
                  </View>
                  <Divider />
              </Flex>
          }
      </View>
    </Flex>
  );
}
