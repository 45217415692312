import React, { useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { TextField } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';

export default function FleetSuppApp() {
  //let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]});
  //const [count, setCount] = useState(0);
  const [company_name_address, updateCompanyNameAddress] = React.useState("");
  const [company_established, updateCompanyEstablished] = React.useState("");
  const [company_markets, updateCompanyMarkets] = React.useState("");
  const [partner_platforms, updatePartnerPlatforms] = React.useState("");
  const [rental_intervals, updateRentalIntervals] = React.useState("");
  const [types_of_vehicles, updateTypesOfVehicles] = React.useState("");
  
  const [rental_transactions_year_1, updateRentalTransactionsCount1] = React.useState("");
  const [rental_transactions_days_1, updateRentalTransactionsDays1] = React.useState("");
  const [rental_transactions_mileage_1, updateRentalTransactionsMileage1] = React.useState("");
  const [rental_transactions_year_2, updateRentalTransactionsCount2] = React.useState("");
  const [rental_transactions_days_2, updateRentalTransactionsDays2] = React.useState("");
  const [rental_transactions_mileage_2, updateRentalTransactionsMileage2] = React.useState("");
  const [rental_transactions_year_3, updateRentalTransactionsCount3] = React.useState("");
  const [rental_transactions_days_3, updateRentalTransactionsDays3] = React.useState("");
  const [rental_transactions_mileage_3, updateRentalTransactionsMileage3] = React.useState("");
  const [rental_transactions_year_4, updateRentalTransactionsCount4] = React.useState("");
  const [rental_transactions_days_4, updateRentalTransactionsDays4] = React.useState("");
  const [rental_transactions_mileage_4, updateRentalTransactionsMileage4] = React.useState("");
  const [rental_transactions_year_5, updateRentalTransactionsCount5] = React.useState("");
  const [rental_transactions_days_5, updateRentalTransactionsDays5] = React.useState("");
  const [rental_transactions_mileage_5, updateRentalTransactionsMileage5] = React.useState("");

  const [projected_rental_days_year_1, updateProjectedRentalDaysYear1] = React.useState("");
  const [projected_rental_days_year_2, updateProjectedRentalDaysYear2] = React.useState("");
  const [projected_rental_days_year_3, updateProjectedRentalDaysYear3] = React.useState("");
  const [projected_rental_days_year_4, updateProjectedRentalDaysYear4] = React.useState("");
  const [projected_rental_days_year_5, updateProjectedRentalDaysYear5] = React.useState("");

  const [period_tracking, updatePeriodTracking] = React.useState("");
  const [expected_mix, updateExpectedMix] = React.useState("");
  const [mvr_rejection_criteria, updateMvrRejectionCriteria] = React.useState("");
  const [third_party_record_checks, updateThirdPartyRecordChecks] = React.useState("");
  const [rating_system, updateRatingSystem] = React.useState("");
  const [driver_minimum_requirements, updateDriverMinimumRequirements] = React.useState("");

  const [telematics_camera_hardware_software, updateTelematicsCameraHardwareSoftware] = React.useState("");
  const [telematics_camera_data_useage, updateTelematicsCameraDataUseage] = React.useState("");
  const [telematics_camera_driver_behavior_monitoring, updateTelematicsCameraDriverBehaviorMonitoring] = React.useState("");
  const [telematics_camera_driver_behavior_monitor, updateTelematicsCameraDriverBehaviorMonitor] = React.useState("");

  const [minimal_vehicle_requirements, updateMinimalVehicleRequirements] = React.useState("");
  const [hazard_controls, updateHazardControls] = React.useState("");
  const [vehicle_procurement_process, updateVehicleProcurementProcess] = React.useState("");
  
  const [insurance_coverage_seeking_limit_liability_renter, updateInsuranceCoverageSeekingLimitLiabilityRenter] = React.useState("");
  const [insurance_coverage_seeking_deductible_liability_renter, updateInsuranceCoverageSeekingDeductibleLiabilityRenter] = React.useState("");
  const [insurance_coverage_seeking_limit_liability_platform, updateInsuranceCoverageSeekingLimitLiabilityPlatform] = React.useState("");
  const [insurance_coverage_seeking_deductible_liability_platform, updateInsuranceCoverageSeekingDeductibleLiabilityPlatform] = React.useState("");
  const [insurance_coverage_seeking_limit_physical_damage, updateInsuranceCoverageSeekingLimitPhysicalDamage] = React.useState("");
  const [insurance_coverage_seeking_deductible_physical_damage, updateInsuranceCoverageSeekingDeductiblePhysicalDamage] = React.useState("");
  const [insurance_coverage_seeking_limit_umuim, updateInsuranceCoverageSeekingLimitUmuim] = React.useState("");
  const [insurance_coverage_seeking_deductible_umuim, updateInsuranceCoverageSeekingDeductibleUmuim] = React.useState("");
  const [insurance_coverage_seeking_limit_medicalpip, updateInsuranceCoverageSeekingLimitMedicalpip] = React.useState("");
  const [insurance_coverage_seeking_deductible_medicalpip, updateInsuranceCoverageSeekingDeductibleMedicalpip] = React.useState("");
  
  const [historical_loss, updateHistoricalLoss] = React.useState("");
  const [financial_info, updateFinancialInfo] = React.useState("");
  const [terms_link, updateTermsLink] = React.useState("");
  const [senior_team, updateSeniorTeam] = React.useState("");


  async function submitForm()
  {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "api_endpoint":"fleetsuppapp",
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "company_name_address":company_name_address,
            "company_established":company_established,
            "company_markets":company_markets,
            "partner_platforms":partner_platforms,
            "rental_intervals":rental_intervals,
            "types_of_vehicles":types_of_vehicles,
            "rental_transactions_year_1":rental_transactions_year_1,
            "rental_transactions_days_1":rental_transactions_days_1,
            "rental_transactions_mileage_1":rental_transactions_mileage_1,
            "rental_transactions_year_2":rental_transactions_year_2,
            "rental_transactions_days_2":rental_transactions_days_2,
            "rental_transactions_mileage_2":rental_transactions_mileage_2,
            "rental_transactions_year_3":rental_transactions_year_3,
            "rental_transactions_days_3":rental_transactions_days_3,
            "rental_transactions_mileage_3":rental_transactions_mileage_3,
            "rental_transactions_year_4":rental_transactions_year_4,
            "rental_transactions_days_4":rental_transactions_days_4,
            "rental_transactions_mileage_4":rental_transactions_mileage_4,
            "rental_transactions_year_5":rental_transactions_year_5,
            "rental_transactions_days_5":rental_transactions_days_5,
            "rental_transactions_mileage_5":rental_transactions_mileage_5,
            "projected_rental_days_year_1":projected_rental_days_year_1,
            "projected_rental_days_year_2":projected_rental_days_year_2,
            "projected_rental_days_year_3":projected_rental_days_year_3,
            "projected_rental_days_year_4":projected_rental_days_year_4,
            "projected_rental_days_year_5":projected_rental_days_year_5,
            "period_tracking":period_tracking,
            "expected_mix":expected_mix,
            "mvr_rejection_criteria":mvr_rejection_criteria,
            "third_party_record_checks":third_party_record_checks,
            "rating_system":rating_system,
            "driver_minimum_requirements":driver_minimum_requirements,
            "telematics_camera_hardware_software":telematics_camera_hardware_software,
            "telematics_camera_data_useage":telematics_camera_data_useage,
            "telematics_camera_driver_behavior_monitoring":telematics_camera_driver_behavior_monitoring,
            "telematics_camera_driver_behavior_monitor":telematics_camera_driver_behavior_monitor,
            "minimal_vehicle_requirements":minimal_vehicle_requirements,
            "hazard_controls":hazard_controls,
            "vehicle_procurement_process":vehicle_procurement_process,
            "insurance_coverage_seeking_limit_liability_renter":insurance_coverage_seeking_limit_liability_renter,
            "insurance_coverage_seeking_deductible_liability_renter":insurance_coverage_seeking_deductible_liability_renter,
            "insurance_coverage_seeking_limit_liability_platform":insurance_coverage_seeking_limit_liability_platform,
            "insurance_coverage_seeking_deductible_liability_platform":insurance_coverage_seeking_deductible_liability_platform,
            "insurance_coverage_seeking_limit_physical_damage":insurance_coverage_seeking_limit_physical_damage,
            "insurance_coverage_seeking_deductible_physical_damage":insurance_coverage_seeking_deductible_physical_damage,
            "insurance_coverage_seeking_limit_umuim":insurance_coverage_seeking_limit_umuim,
            "insurance_coverage_seeking_deductible_umuim":insurance_coverage_seeking_deductible_umuim,
            "insurance_coverage_seeking_limit_medicalpip":insurance_coverage_seeking_limit_medicalpip,
            "insurance_coverage_seeking_deductible_medicalpip":insurance_coverage_seeking_deductible_medicalpip,
            "terms_link":terms_link,
            "senior_team":senior_team
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateRes(res)
    }
    catch (e) {
        const res = {status:"Error saving form - email us at info@joinago.com: "+toString(e)}
        updateRes(res)
        updateIsLoading(false)
    }
    updateIsLoading(false)
  }

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>SUPPLEMENTAL AUTO INSURANCE APPLICATION</Heading>
        <p>Fleet Rentals for Gig Use Platforms: Please complete this form required by the insurance company for your fleet.</p>
        <Heading level={4}>1.  Name and address of your company:</Heading>
        <p><TextField
            placeholder='name and address of company'
            onInput={(e) => updateCompanyNameAddress(e.target.value)}
        ></TextField></p>
        <Heading level={4}>2.  When was your company established and how long has it been in operation?</Heading>
        <p><TextField
            placeholder='company established, years in operation'
            onInput={(e) => updateCompanyEstablished(e.target.value)}
        ></TextField></p>
        <Heading level={4}>3.  What countries and states, and major markets are you currently operating in? Does your company plan on expanding its operations into new territories in the next year, and if so, which ones?</Heading>
        <p><TextField
            placeholder='countries, states, markets, plans for expanding'
            onInput={(e) => updateCompanyMarkets(e.target.value)}
        ></TextField></p>
        <Heading level={4}>4.  Please also describe any platforms (e.g. ridesharing/delivery) that you are or plan on partnering with to offer vehicles for. Will offered vehicles be specific to one platform or allowed to be used on multiple platforms? Please provide any applicable contracts and terms with partners.</Heading>
        <p><TextField
            placeholder='partner platforms'
            onInput={(e) => updatePartnerPlatforms(e.target.value)}
        ></TextField></p>
        <Heading level={4}>5.  What intervals (e.g. weekly, daily, hourly, etc) can renters secure a vehicle for? What is the expected average rental period? Is there mileage allowances or overage rates?</Heading>
        <p><TextField
            placeholder='rental intervals, mileage allowances'
            onInput={(e) => updateRentalIntervals(e.target.value)}
        ></TextField></p>
        <Heading level={4}>6.  Please describe the type of vehicles that are or will be rented on your platform. Please provide historical/expected mix.</Heading>
        <p><TextField
            placeholder='types of vehicles'
            onInput={(e) => updateTypesOfVehicles(e.target.value)}
        ></TextField></p>
        <Heading level={4}>7.  Please provide historical rental transactions, number of rental days, and the total mileage from the past five years.</Heading>
        <table style={{}}>
          <tr>
            <th style={{padding:10, border:'1px solid black'}}>Year</th>
            <th style={{padding:10, border:'1px solid black'}}>Rental Transactions</th>
            <th style={{padding:10, border:'1px solid black'}}>Rental Days</th>
            <th style={{padding:10, border:'1px solid black'}}>Mileage</th>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Prior 1</td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsCount1(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsDays1(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsMileage1(e.target.value)}
              ></TextField>
            </td>
          </tr>
          <tr>
          <td style={{padding:10, border:'1px solid black'}}>Prior 2</td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsCount2(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsDays2(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsMileage2(e.target.value)}
              ></TextField>
            </td>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Prior 3</td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsCount3(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsDays3(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsMileage3(e.target.value)}
              ></TextField>
            </td>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Prior 4</td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsCount4(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsDays4(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsMileage4(e.target.value)}
              ></TextField>
            </td>
          </tr>
          <tr>
          <td style={{padding:10, border:'1px solid black'}}>Prior 5</td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsCount5(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsDays5(e.target.value)}
              ></TextField>
            </td>
            <td style={{padding:10, border:'1px solid black'}}>
              <TextField
                  placeholder=''
                  onInput={(e) => updateRentalTransactionsMileage5(e.target.value)}
              ></TextField>
            </td>
          </tr>
        </table><br/>
        <Heading level={4}>8.  What is your projected number of rental days over the next five (5) years?</Heading>
        <ul>
          <li>Year 1:  <TextField
            placeholder='Projected rental days, year 1'
            onInput={(e) => updateProjectedRentalDaysYear1(e.target.value)}
        ></TextField></li>
          <li>Year 2:  <TextField
            placeholder='Projected rental days, year 2'
            onInput={(e) => updateProjectedRentalDaysYear2(e.target.value)}
        ></TextField></li>
          <li>Year 3:  <TextField
            placeholder='Projected rental days, year 3'
            onInput={(e) => updateProjectedRentalDaysYear3(e.target.value)}
        ></TextField></li>
          <li>Year 4:  <TextField
            placeholder='Projected rental days, year 4'
            onInput={(e) => updateProjectedRentalDaysYear4(e.target.value)}
        ></TextField></li>
          <li>Year 5:  <TextField
            placeholder='Projected rental days, year 5'
            onInput={(e) => updateProjectedRentalDaysYear5(e.target.value)}
        ></TextField></li>
        </ul>
        <Heading level={4}>9.  How will mileage by platform by period (e.g. 1, 2 or 3) as well as off platform be tracked and reported?</Heading>
        <p><TextField
            placeholder='how mileage by insurance period will be tracked'
            onInput={(e) => updatePeriodTracking(e.target.value)}
        ></TextField></p>
        <Heading level={4}>10.  What is the expected mix by period and platform type (e.g. ridesharing vs delivery?)</Heading>
        <p><TextField
            placeholder='expected mix by period and platform type'
            onInput={(e) => updateExpectedMix(e.target.value)}
        ></TextField></p>
        <Heading level={4}>11.  Please provide the details of the background and driving record checks performed on your renters. To what extent is renter identity validated? Please include your qualifying criteria for acceptance and rejection.</Heading>
        <p><TextField
            placeholder='mvr checks, identity verification, rejection criteria'
            onInput={(e) => updateMvrRejectionCriteria(e.target.value)}
        ></TextField></p>
        <Heading level={4}>12.  Does your company utilize a third party to perform these background/driving record checks/member identification? If so, please list the name(s).</Heading>
        <p><TextField
            placeholder='third party record checks'
            onInput={(e) => updateThirdPartyRecordChecks(e.target.value)}
        ></TextField></p>
        <Heading level={4}>13.  Is there a rating system in place to score renters, permitting the removal of those with poor scores? Please describe.</Heading>
        <p><TextField
            placeholder='rating system'
            onInput={(e) => updateRatingSystem(e.target.value)}
        ></TextField></p>
        <Heading level={4}>14.  What are the minimum requirements for drivers, including age and years of operator experience?</Heading>
        <p><TextField
            placeholder='driver minimum requirements'
            onInput={(e) => updateDriverMinimumRequirements(e.target.value)}
        ></TextField></p>
        <Heading level={4}>15.  Are there telematics and/or camera systems in place?</Heading>
        <ul>
          <li>What hardware, software and vendors are used?  <TextField
              placeholder='telematics or camera hardware and software'
              onInput={(e) => updateTelematicsCameraHardwareSoftware(e.target.value)}
          ></TextField></li>
          <li>What is recorded and how is it utilized in order to remove individuals from the platform?  <TextField
              placeholder='telematics or camera data and useage'
              onInput={(e) => updateTelematicsCameraDataUseage(e.target.value)}
          ></TextField></li>
          <li>How does your company monitor driver behavior, distracted driving, and other vehicle data during rides?   <TextField
              placeholder='telematics or camera driver behavior monitoring'
              onInput={(e) => updateTelematicsCameraDriverBehaviorMonitoring(e.target.value)}
          ></TextField></li>
          <li>Who is responsible for monitoring this within the Company?  <TextField
              placeholder='telematics or camera monitor'
              onInput={(e) => updateTelematicsCameraDriverBehaviorMonitor(e.target.value)}
          ></TextField></li>
        </ul>

        <Heading level={4}>16.  What are the minimum vehicle requirements and how are these checked and confirmed? Please include the required maintenance records that must be kept, how these are checked, and how vehicle recalls are monitored.</Heading>
        <p><TextField
            placeholder='minimal vehicle requirements'
            onInput={(e) => updateMinimalVehicleRequirements(e.target.value)}
        ></TextField></p>
        <Heading level={4}>17.  What are your controls/processes for inclement weather and heightened hazard periods?</Heading>
        <p><TextField
            placeholder='hazard controls'
            onInput={(e) => updateHazardControls(e.target.value)}
        ></TextField></p>
        <Heading level={4}>18.  How are vehicles collected or delivered and how are they returned? Is the renter responsible for picking up and returning the vehicle?</Heading>
        <p><TextField
            placeholder='vehicle procurement process'
            onInput={(e) => updateVehicleProcurementProcess(e.target.value)}
        ></TextField></p>
        <Heading level={4}>19.  What insurance coverage are you currently seeking, including potential limits and retentions? Please list any required or desired related/ancillary coverages below this table.</Heading>        
        <table>
          <tr>
            <th style={{padding:10, border:'1px solid black'}}>Party</th>
            <th style={{padding:10, border:'1px solid black'}}>Limit/Value</th>
            <th style={{padding:10, border:'1px solid black'}}>Retention/Deductible</th>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Renters - Auto Liability</td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingLimitLiabilityRenter(e.target.value)}
            ></TextField></td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingDeductibleLiabilityRenter(e.target.value)}
            ></TextField></td>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Platform - Auto Liability</td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingLimitLiabilityPlatform(e.target.value)}
            ></TextField></td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingDeductibleLiabilityPlatform(e.target.value)}
            ></TextField></td>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Physical Damage</td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingLimitPhysicalDamage(e.target.value)}
            ></TextField></td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingDeductiblePhysicalDamage(e.target.value)}
            ></TextField></td>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>UM/UIM</td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingLimitUmuim(e.target.value)}
            ></TextField></td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingDeductibleUmuim(e.target.value)}
            ></TextField></td>
          </tr>
          <tr>
            <td style={{padding:10, border:'1px solid black'}}>Medical payments / PIP</td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingLimitMedicalpip(e.target.value)}
            ></TextField></td>
            <td style={{padding:10, border:'1px solid black'}}><TextField
                placeholder=''
                onInput={(e) => updateInsuranceCoverageSeekingDeductibleMedicalpip(e.target.value)}
            ></TextField></td>
          </tr>
        </table><br/>

        <Heading level={4}>20.  If applicable, please provide at least (5) five years of historical loss runs, valued within the last sixty days, and in Excel format if available. Provide the corresponding limits and retentions.  If necessary, please elaborate on large or unusual losses here.</Heading>
        <p><TextField
            placeholder='(file upload in development)'
            onInput={(e) => updateHistoricalLoss(e.target.value)}
        ></TextField></p>
        <Heading level={4}>21.  Please provide historical and projected financial information. If a complete set of historical financials were prepared please provide your most recent complete set including accounting sign of, if applicable. If not provided separately what are the Company's cash, working capital, retained earnings (accumulated deficit), and total equity balances as of the most recent year or quarter-end. (Note: Publicly traded companies can ignore this question)</Heading>
        <p><TextField
            placeholder='(file upload in development)'
            onInput={(e) => updateFinancialInfo(e.target.value)}
        ></TextField></p>
        <Heading level={4}>23.  Please provide a copy of any terms of service, rental/member agreements, and distracted driving or your safety policies. If online, please provide reference as to where they are located</Heading>
        <p><TextField
            placeholder='link to terms of service'
            onInput={(e) => updateTermsLink(e.target.value)}
        ></TextField></p>
        <Heading level={4}>23.   Please describe the senior management team or provide bios. What experience do you or they have in this industry?</Heading>
        <p><TextField
            placeholder='Senior team'
            onInput={(e) => updateSeniorTeam(e.target.value)}
        ></TextField></p>
        
        <br/><br/>
        <p><button onClick={() => submitForm()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5, width:'100%'}}>Submit</button></p>
        <br/><br/><br/><p>{JSON.stringify(res)}</p>
        {is_loading ? <Loading /> : undefined}
    </main>
  );
}