import React, { useState } from 'react'
import "@aws-amplify/ui-react/styles.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Usercommercialplatformactivity({user, selectCommercialPlatformActivityMaxDate, commercialPlatformActivityMaxDate}) {
  const [is_loading, updateIsLoading] = React.useState(false);

  const columns = [
      {
          field: 'trip_empl',
          filter: true
      },
      {
          field: 'trip_status',
          filter: true
      },
      {
          field: 'start_datetime',
          filter: true
      },
      {
          field: 'end_datetime',
          filter: true
      },
      {
          field: 'trip_dist',
          filter: true
      },
      {
          field: 'trip_dist_units',
          filter: true
      },
      {
          field: 'trip_durat',
          filter: true
      },
      {
          field: 'request_at_datetime',
          filter: true
      },
      {
          field: 'pickup_at_datetime',
          filter: true
      },
      {
          field: 'dropoff_at_datetime',
          filter: true
      },
      {
          field: 'break_start_datetime',
          filter: true
      },
      {
          field: 'break_end_datetime',
          filter: true
      },
      {
          field: 'shift_start_datetime',
          filter: true
      },
      {
          field: 'shift_end_datetime',
          filter: true
      }
  ];
  


  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    {
                        //typeof(user === 'undefined') ?  <Text>Commercial activities not found, user undefined</Text> :
                        user === false ? 
                        <Flex direction="column">
                            <Heading level={2}>No Commercial acitivities found</Heading>
                            <Text>User not found</Text>
                        </Flex>
                         :
                        typeof(user) === 'undefined' ?  
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        typeof(user.resp) === 'undefined' ?  
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        typeof(user.resp[0]) === 'undefined' ?  
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        typeof(user.resp[0]["user_commercial_activities"]) === 'undefined' ?  
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        user === false ?
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        user.resp === false ?
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        user.resp[0] === false ?
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        user.resp[0]["user_commercial_activities"] === false ?
                            <Flex direction="column">
                                <Heading level={2}>No Commercial acitivities found</Heading>
                                <Text>No Commercial acitivities found. Is this driver new? Do they have commercial platform accounts?</Text>
                            </Flex>
                        :
                        <Flex direction='column'>
                            <Heading level={2}>Commercial Platform Activity</Heading>
                            <Text style={{width:'100%'}}>This is the history of the driver's commercial platform activity</Text>
                            {
                                /*
                            <Text>Trailing from date:</Text>
                            <Flex>
                                <DatePicker
                                    selected={commercialPlatformActivityMaxDate}
                                    onChange={(date) => selectCommercialPlatformActivityMaxDate(date)}
                                    dateFormat="MMMM d, yyyy"
                                    timeIntervals={60}
                                />
                            </Flex>
                                */
                            }
                            <Divider />
                            <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                            <View style={{width:"100%"}}>
                                <View 
                                    className="ag-theme-quartz" // applying the grid theme
                                    style={{ height: 500 }} // the grid will fill the size of the parent container
                                >
                                        <AgGridReact
                                            rowData={user.resp[0]["user_commercial_activities"]}
                                            columnDefs={columns}
                                            rowSelection={'multiple'}
                                            paginationPageSize={500}
                                            paginationPageSizeSelector={[10, 25, 50]}
                                            enableCellTextSelection={true}
                                        />
                                </View>
                            </View>
                            <Divider />
                        </Flex>
                    }
                </Flex>
            }
        </View>
      </Flex>
  );
}
