import React, { useState } from 'react'
import { View, Text, Heading, Button, TextField, Flex} from '@aws-amplify/ui-react';
import Loadingmini from './loadingmini';
import { API, Auth } from 'aws-amplify';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export const Searchselect = ({searchType, objects, selectObject, selectedObject, updateSelectedObjectName, selectedObjectName, updateSearchSelectActive}) => {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [isFocused, updateIsFocused] = React.useState(false);
  async function loadPage() {
  }


  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    //console.log(string, results)
    updateIsFocused(true)
    updateSearchSelectActive(true);
  }

  const handleOnHover = (result) => {
    // the item hovered
    //console.log('hover:'+JSON.stringify(result))
  }

  const handleOnSelect = (item) => {
    // the item selected
    //console.log(item)
    selectObject(item.id)
    updateSelectedObjectName(item.name)
    updateIsFocused(false)
    updateSearchSelectActive(false);
  }

  const handleOnFocus = () => {
    //console.log('Focused')
  }

  const formatResult = (item) => {
    if(item===false)
    {
      return;
    }
    if(searchType=='driver')
    {
      return (
        <View>
          <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
          <span style={{ display: 'block', textAlign: 'left' }}>name_first: {item.name_first}</span>
          <span style={{ display: 'block', textAlign: 'left' }}>name_last: {item.name_last}</span>
          <span style={{ display: 'block', textAlign: 'left' }}>license: {item.license}</span>
          <span style={{ display: 'block', textAlign: 'left' }}>phone: {item.phone}</span>
          <span style={{ display: 'block', textAlign: 'left' }}>email: {item.email}</span>
        </View>
      )
    
    } else {
        // vehicle
        return (
          <View>
            <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>vin: {item.name}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>license: {item.name}</span>
          </View>
        )
    }
  }

  const clearSelection = () => {
    selectObject(false)
    updateSearchSelectActive(false)
  }

  return (<View style={{flex:1}}>
    {
      is_loading ? <Loadingmini /> : 
      selectedObject === false || selectedObject < 1 ? 
        <View style={isFocused ? {width:400, zIndex:100} : {width:400}}>
          <ReactSearchAutocomplete
            items={objects}
            fuseOptions={searchType=="driver" ? { keys: ["id","name_first", "name_last", "license", "phone", "email"] } : { keys: ["id","name", "vin", "license"] }}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={isFocused ? {zIndex:1000, position:'fixed'} : undefined}
          />
        </View>
      :
        <Button onClick={() => clearSelection()}>
            ({selectedObject}) {selectedObjectName}
        </Button>
    }
  </View>);
};

export default Searchselect;