import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Vehiclestatus({userJwt, refreshJwt, vehicle}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const columns = [
      {
          field: 'sql_timestamp',
          filter: true
      },
      {
          field: 'status',
          filter: true
      }
  ];
  

  async function fetchPageData() {
    updateIsLoading(true)
    if(vehicle)
    {
        if(vehicle['resp'])
        {
            if(vehicle['resp'][0])
            {
                if(vehicle['resp'][0]['car_id'])
                {
                    //console.log('vehicelstatushistory vehicle '+ vehicle['resp'][0]['car_id'])
                    const myInit = {queryStringParameters:{ 
                        "back_end": process.env.REACT_APP_BACKEND,
                        "jwt": userJwt,
                        "api_endpoint":"vehiclestatushistory",
                        "vehicle_id":vehicle['resp'][0]['car_id']
                    }};
                    try {
                        const res = await API.get('adminWebTools','/ago',myInit)
                        //console.log('vehiclestatushistory response: '+JSON.stringify(res))
                        updateRes(res)
                        if('detail' in res)
                        {
                            if(res['detail'].includes("token"))
                            {
                                refreshJwt()
                            }
                        }
                        updateIsLoading(false)
                    }
                    catch (e) {
                        console.log('vehiclestatushistory error: '+toString(e));
                        if(e.response.status === 401)
                        {
                            refreshJwt()
                        }
                        updateIsLoading(false)
                    }
                }
            }
        }
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count, userJwt])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Vehicle Status History</Heading>
                    <Text style={{width:'100%'}}>This is the history of your vehicle's rental listing status in the mobile app</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 500 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
