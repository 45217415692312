import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text, View, Flex, Divider, TextField} from '@aws-amplify/ui-react';
import Loading from '../components/loading';
import { Heading, Button } from '@aws-amplify/ui-react';
import User from './user';
import Vehicle from './vehicle';
import Reservationcommands from './reservationcommands';
import Reservationactionhistory from './reservationactionhistory';


export default function Reservation({paramResId,closeReservationDetails}) {
  let params = useParams();
  let tresId = false
  if(paramResId)
  {
    tresId = paramResId;
  } else {
    tresId = params.resId;
  }
  const [resId, updateResId] = React.useState(tresId);
  const [reservation, updateReservation] = useState({status:"If this message stays - email us at info@joinago.com",rows:[]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [showDataPacket, updateShowDataPacket] = React.useState(false);

  const [closeReservationResults, updateCloseReservationResults] = React.useState({});
  const [switchCarResults, updateSwitchCarResults] = React.useState({});
  const [showConfirmCloseReservation, updateShowConfirmCloseReservation] = React.useState(false);

  const [showConfirmCancelReservation, updateShowConfirmCancelReservation] = React.useState(false);
  const [cancellationResults, updateCancellationResults] = React.useState(false);
  const [fullRefund, updateFullRefund] = React.useState(true);
  const [cashNotCredits, updateCashNotCredits] = React.useState(true);
  const [reservationCancelled, updateReservationCancelled] = React.useState(false);
  const [reservationCancelledLostAmount, updateReservationCancelledLostAmount] = React.useState(0);
  const [reservationCancelledCreditedAmount, updateReservationCancelledCreditedAmount] = React.useState(0);
  const [reservationCancelError, updateReservationCancelError] = React.useState(false);
  const [showSwitchCarAssignedToReservation, updateShowSwitchCarAssignedToReservation] = React.useState(false);
  const [switchToCarId, updateSwitchToCarId] = React.useState(0);


  
  useEffect(() => {
    const fetchData = async () => {
      updateIsLoading(true)
      let session = await Auth.currentSession()      
      let accessToken = session.getAccessToken()
      let jwt = accessToken.getJwtToken()
      const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND,
        jwt:jwt,
        api_endpoint: "reservation",
        res_id:resId,
      }};
      try {
          const res = await API.get('adminWebTools','/ago',myInit);
          updateReservation(res);
          updateIsLoading(false)
          return
      }
      catch (e) {
          const res = {status:"Error retrieving Reservation or User not permissioned",rows:[]};
          updateReservation(res);
          updateIsLoading(false)
          return
      }
    }
    fetchData()
  },[count,params])


  const computeCancelReservation = async (resId) => {
    console.log('resId compute cancel: ' + resId);
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughrescomputecancel",
      res_id:resId
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      console.log(JSON.stringify(res)); //TEMP
      updateCancellationResults(res);
      updateShowConfirmCancelReservation(true);
    } catch (e) {
      const res = {status:"Error computing cancel for reservation: " + JSON.stringify(e)};
      updateCancellationResults(res);
      updateShowConfirmCancelReservation(true);
    }
    updateIsLoading(false);
  }


  const switchCarAssignedToReservation = async (resId) => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresswitchcar",
      res_id:resId,
      "vehicle_id":switchToCarId
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      updateSwitchCarResults(res);
      //updateShowSwitchCarAssignedToReservation(false);
    } catch (e) {
      const res = {status:"Error: " + JSON.stringify(e)};
      updateSwitchCarResults(res);
      //updateShowSwitchCarAssignedToReservation(false);
    }
    updateIsLoading(false);
  }


  const closeReservation = async (resId) => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "reservationclose",
      res_id:resId
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      updateCloseReservationResults(res);
      updateShowConfirmCloseReservation(false);
    } catch (e) {
      const res = {status:"Error closing reservation: " + JSON.stringify(e)};
      updateCloseReservationResults(res);
      updateShowConfirmCloseReservation(false);
    }
    updateIsLoading(false);
  }

  

  const confirmCancelReservation = async (resId) => {
    console.log('resId confirm cancel: ' + resId);
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresmakecancel",
      res_id:resId,
      started: true,
      full_refund: fullRefund,
      cash_refund: cashNotCredits,
      reason: "Support cancel"
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      console.log(JSON.stringify(res));
      updateCancellationResults(res);
      if(res['status'])
      {
        if(res['status']=='success')
        {
          updateReservationCancelled(true);
          updateReservationCancelledLostAmount(res['lost_amount']);
          updateReservationCancelledCreditedAmount(res['credited_amount']);
        } else {
          updateReservationCancelError(res['status']);
        }
      } else {
        updateReservationCancelError(JSON.stringify(res));
      }
    } catch (e) {
      const res = {status:"Error computing cancel for reservation: " + JSON.stringify(e)};
      console.log(JSON.stringify(res));
      updateCancellationResults(res);
    }
    updateIsLoading(false);

  }



  return (
    is_loading ? <Loading /> : 
    <Flex //  https://ui.docs.amplify.aws/react/components/flex#demo
      direction="column"
    >
      <View style={{padding:"1rem"}}>
          <Flex>
            <Heading level={1}>Reservation {resId}</Heading>
          </Flex>
          {
            reservation.rows[0] ? 
                "status" in reservation.rows[0] ? 
                    <View><Heading level={4}>{reservation.rows[0]["status"]}</Heading><br/></View>
                  : undefined 
                : undefined
          }
          <Button onClick={() => setCount(count + 1)}>
              PRESS TO GET LATEST UPDATES
          </Button>
          <Divider />
          {reservation ?
            reservation.rows ?
              reservation.rows[0] ?  
                "status" in reservation.rows[0] === false ? undefined :
                <Flex direction="column">
                  <Flex wrap="wrap" justifyContent="center" gap="2rem">
                    <Flex direction="column">
                      <Heading level={2}>Reservation Info</Heading>
                      <table style={{marginLeft:'15px'}}>
                        <tr>
                          <th>Status:</th>
                          <td>{reservation['rows'][0]['status']}</td>
                        </tr>
                        <tr>
                          <th>Start:</th>
                          <td>{reservation['rows'][0]['start_string']}</td>
                        </tr>
                        <tr>
                          <th>End:</th>
                          <td>{reservation['rows'][0]['end_string']}</td>
                        </tr>
                        <tr>
                          <th>Driver:</th>
                          <td><a href={'../../user/'+reservation['rows'][0]['driver_id']}>{reservation['rows'][0]['driver_name']}</a></td>
                        </tr>
                        <tr>
                          <th>Car:</th>
                          <td><a href={'../../vehicle/'+reservation['rows'][0]['car_id']}>{reservation['rows'][0]['car_name']}</a></td>
                        </tr>
                        {
                          reservation['rows'][0]['original_car_id']!==reservation['rows'][0]['car_id'] ?
                            <tr>
                              <th>Original Car:</th>
                              <td><a href={'../../vehicle/'+reservation['rows'][0]['original_car_id']}>#{reservation['rows'][0]['original_car_id']}</a></td>
                            </tr>
                          : undefined
                        }
                        <tr>
                          <th>Created on:</th>
                          <td>{reservation['rows'][0]['created']}</td>
                        </tr>
                        <tr>
                          <th>Last Modified:</th>
                          <td>{reservation['rows'][0]['modified']}</td>
                        </tr>
                        <tr>
                          <th>Reservation Version:</th>
                          <td>{reservation['rows'][0]['version']}</td>
                        </tr>
                        <tr>
                          <th>Gross Cost:</th>
                          <td>${reservation['rows'][0]['gross_cost']}</td>
                        </tr>
                        {
                          reservation['rows'][0]['deposit'] > 0 ?
                            <tr>
                              <th>Deposit:</th>
                              <td>${reservation['rows'][0]['deposit']}</td>
                            </tr>
                          : undefined
                        }
                        <tr>
                          <th>Intends Renew:</th>
                          <td>{reservation['rows'][0]['intent']}</td>
                        </tr>
                        <tr>
                          <th>Fleet ID:</th>
                          <td>{reservation['rows'][0]['fleet_id']}</td>
                        </tr>
                        <tr>
                          <th>Close/Cancel:</th>
                          <td>{reservation['rows'][0]['close_cancel']}</td>
                        </tr>
                        <tr>
                          <th>Close/Cancel Reason:</th>
                          <td>{reservation['rows'][0]['reason']}</td>
                        </tr>
                      </table>
                      <Divider />
                    </Flex>
                    <Reservationcommands resId={resId} resData={reservation['rows'][0]} />
                    <Reservationactionhistory resId={resId} />
                    {
                      'car_id' in reservation.rows[0] === false ? undefined :
                      reservation.rows[0]['car_id'] < 1 ? undefined :
                      <Flex direction="column">
                        <Divider />
                        <Vehicle paramVehicleId={reservation.rows[0]["car_id"]} />
                        <Divider />
                      </Flex>
                    }
                    {
                      'driver_id' in reservation.rows[0] === false ? undefined :
                      reservation.rows[0]['driver_id'] < 1 ? undefined :
                      <Flex direction="column">
                        <Divider />
                        <User paramUserId={reservation.rows[0]["driver_id"]} />
                        <Divider />
                      </Flex>
                    }
                  </Flex>
                  <Divider />
                  {
                    showDataPacket ?
                      <Flex direction="column">
                        <Heading level={2}>Data Packet</Heading>
                        <pre id="json"><p>{JSON.stringify(reservation,null, 4)}</p></pre>
                        <Button onClick={() => updateShowDataPacket(false)}>
                          Hide Data Packet
                        </Button>
                      </Flex>
                    : 
                      <Button onClick={() => updateShowDataPacket(true)}>
                        Reservation Data Packet
                      </Button>
                  }
                  <br/><br/><br/><br/>
                </Flex>
              : undefined
            : undefined
          : undefined}



          
          {showConfirmCancelReservation ? 
              <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
                {reservationCancelled ? 
                    <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                      <h2 style={{textAlign:'center', fontWeight:'bold'}}>Reservation Cancelled</h2>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Reservation {resId} successfully cancelled.</p>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Lost amount:  ${reservationCancelledLostAmount}</p>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Credited amount:  ${reservationCancelledCreditedAmount}</p>
                      <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic'}} onClick={() => closeReservationDetails()}>EXIT AND REFRESH</Button></p>
                    </div>
                  : 
                  reservationCancelError ? 
                    <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                      <h2 style={{textAlign:'center', fontWeight:'bold'}}>Whoops!</h2>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>There was an error cancelling reservation {resId}.</p>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>{reservationCancelError}</p>
                      <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic'}} onClick={() => closeReservationDetails()}>EXIT AND REFRESH</Button></p>
                    </div>
                  :
                    <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                      <h2 style={{textAlign:'center', fontWeight:'bold'}}>Are you sure?</h2>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Are you sure you'd like to cancel reservation {resId}?</p>
                      <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>There's no undo-ing this. Do not cancel reservations that actually occured.</p>
                      {fullRefund == true ? 
                        <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateFullRefund(false)}>Issue a Full Refund (click to change)</Button></p>
                        : 
                        <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateFullRefund(true)}>Do not refund deposit (click to change)</Button></p>
                      }
                      {cashNotCredits == true ? 
                        <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateCashNotCredits(false)}>Issue Refund to Card (click to change)</Button></p>
                        : 
                        <p style={{textAlign:'center'}}><Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateCashNotCredits(true)}>Issue Refund in Credits (click to change)</Button></p>
                      }
                      <p style={{textAlign:'center'}}><Button onClick={() => confirmCancelReservation(resId)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>CONFIRM</Button></p>
                      <p style={{textAlign:'center'}}><Button onClick={() => updateShowConfirmCancelReservation(false)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>EXIT</Button></p>
                    </div>
                }
              </div>
          : undefined}
          {showSwitchCarAssignedToReservation ? 
              <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
                <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                  <h2 style={{textAlign:'center', fontWeight:'bold'}}>Switch Car</h2>
                  <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Enter the ID of the vehicle you'd like to become the new primary vehicle for reservation #{resId}:</p>
                  <p style={{textAlign:'center'}}><TextField
                    value={switchToCarId}
                    label="New Car ID"
                    descriptiveText="Car ID of the new car"
                    hasError={parseInt(switchToCarId) < 1 || switchToCarId==="" ? true : false}
                    labelHidden={parseInt(switchToCarId) < 1 || switchToCarId==="" ? false : true}
                    errorMessage="This is a required field"
                    placeholder="1234"
                    onChange={(e) => updateSwitchToCarId(e.currentTarget.value)}
                  /></p>
                  { parseInt(switchToCarId) < 1 ? undefined :
                    <p style={{textAlign:'center'}}><Button onClick={() => switchCarAssignedToReservation(resId)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></p>
                  }
                  {
                    <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>{JSON.stringify(switchCarResults)}:</p>
                  }
                  <p style={{textAlign:'center'}}><Button onClick={() => updateShowSwitchCarAssignedToReservation(false)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>EXIT</Button></p>
                </div>
              </div>
            : undefined}
          {showConfirmCloseReservation ? 
              <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
                <div style={{position:'fixed', top:'20%', left:'50%',width:'400px',marginLeft:'-200px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                  <h2 style={{textAlign:'center', fontWeight:'bold'}}>Are you sure?</h2>
                  <p style={{textAlign:'center', paddingLeft:'15px', paddingRight:'15px'}}>Are you sure you'd like to close reservation {resId}?</p>
                  <p style={{textAlign:'center'}}><Button onClick={() => closeReservation(resId)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>CONFIRM</Button></p>
                  <p style={{textAlign:'center'}}><Button onClick={() => updateShowConfirmCloseReservation(false)} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>EXIT</Button></p>
                </div>
              </div>
            : undefined}




          
      </View>
    </Flex>
  );
}