
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text,View,TextField, SelectField, Heading } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TableContainer from '../components/tablecontainer';
import Loading from '../components/loading';

export default function CarMaintHist() {
    const [is_loading, updateIsLoading] = React.useState(false);
    const [count, setCount] = useState(0);
    const [res, updateRes] = useState({status:"No results",rows:[]})
    const [csv, updateCsv] = useState("")
    const [totalCost, updateTotalCost] = useState("")
    const [aveCost, updateAveCost] = useState("")
    const [doNotDisplayTable, updatedoNotDisplayTable] = useState(false)
    const [countResults, updateCountResults] = useState("")
    const [selectedObjId, updateSelectedObjId] = useState("")
    const [selectedObjType, updateSelectedObjType] = useState("")
    const [selectedObjRangeStart, updateSelectedObjRangeStart] = useState("")
    const [selectedObjRangeEnd, updateSelectedObjRangeEnd] = useState("")
    const [selectedObjRangeStartFull, updateSelectedObjRangeStartFull] = useState("")
    const [selectedObjRangeEndFull, updateSelectedObjRangeEndFull] = useState("")
    const columnsMaintItems = [
      {
          Header: 'invoice_id',
          accessor: 'invoice_id',
      },
      {
          Header: 'type',
          accessor: 'type',
      },
      {
          Header: 'item',
          accessor: 'item',
      },
      {
          Header: 'sku',
          accessor: 'sku',
      },
      {
          Header: 'hours',
          accessor: 'hours',
      },
      {
          Header: 'quantity',
          accessor: 'quantity',
      },
      {
          Header: 'unit_price',
          accessor: 'unit_price',
      },
      {
          Header: 'price',
          accessor: 'price',
      },
      {
          Header: 'notes',
          accessor: 'notes',
      }
    ];
    const columnsDefault = [
      {
          Header: 'invoice_id',
          accessor: 'invoice_id',
      },
      {
          Header: 'invoice_date',
          accessor: 'invoice_date',
      },
      {
          Header: 'name',
          accessor: 'name',
      },
      {
          Header: 'car_id',
          accessor: 'car_id',
      },
      {
          Header: 'vin',
          accessor: 'vin',
      },
      {
          Header: 'invoice amount',
          accessor: 'amount',
      },
      {
          Header: 'year_make',
          accessor: 'year_make',
      },
      {
          Header: 'model',
          accessor: 'model',
      },
      {
          Header: 'license',
          accessor: 'license',
      },
      {
          Header: 'mileage from pepboys',
          accessor: 'mileage',
      },
      {
          Header: 'verified odometer',
          accessor: 'odometer_verified',
      },
      {
          Header: 'invoice subtotal',
          accessor: 'subtotal',
      },
      {
          Header: 'invoice tax',
          accessor: 'tax',
      },
      {
          Header: 'invoice total_with_tax',
          accessor: 'total_with_tax',
      },
      {
          Header: 'driver_pay',
          accessor: 'driver_pay',
      },
      {
          Header: 'charge_to_account',
          accessor: 'charge_to_account',
      },
      {
          Header: 'type',
          accessor: 'type',
      },
      {
          Header: 'item',
          accessor: 'item',
      },
      {
          Header: 'sku',
          accessor: 'sku',
      },
      {
          Header: 'hours',
          accessor: 'hours',
      },
      {
          Header: 'quantity',
          accessor: 'quantity',
      },
      {
          Header: 'unit_price',
          accessor: 'unit_price',
      },
      {
          Header: 'price',
          accessor: 'price',
      },
      {
          Header: 'notes',
          accessor: 'notes',
      },
      {
          Header: 'seller',
          accessor: 'seller',
      },
      {
          Header: 'purchased_condition',
          accessor: 'purchased_condition',
      }
    ];

    async function fetchData() {
        updateIsLoading(true)
        const res = {status:"loading...",rows:[]};
        var i = 0;
        var totalCost = 0;
        var aveCost = "loading...";
        updateRes(res);
        updateTotalCost(totalCost);
        updateAveCost(aveCost);
        updateCountResults(i);
        if(selectedObjType==='seller_cost_per_mile')
        {
            updatedoNotDisplayTable(true);
        } else {
            updatedoNotDisplayTable(false);
        }
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        let user = await Auth.currentAuthenticatedUser(); 
        const myInit = {queryStringParameters:{ 
            back_end: process.env.REACT_APP_BACKEND, 
            jwt:jwt, typeval: selectedObjId, 
            api_endpoint:"vehiclemaintenancehistory",
            user_pool_id: user.pool.userPoolId,
            type: selectedObjType, 
            range_start: selectedObjRangeStart, 
            range_end: selectedObjRangeEnd
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit);
            updateRes(res);
            if(selectedObjType!=='seller_cost_per_mile'&&selectedObjType!=='seller_cost_per_mile_daterange')
            {
                if(res.rows)
                {
                    res.rows.forEach(async (row) =>
                    {
                        if(row['price'])
                        {
                            i = i + 1;
                            totalCost = totalCost + row['price'];
                        }
                    });
                    aveCost = totalCost/i;
                    updateTotalCost(totalCost);
                    updateAveCost(aveCost);
                    updateCountResults(i);
                }
            } else {
                //updateTotalCost("NA");
                //updateAveCost("NA");
                //updateCountResults("NA");
            }

            /*
            var json = res.rows
            var fields = Object.keys(json[0])
            var replacer = function(key, value) { return value === null ? '' : value } 
            var csv = json.map(function(row){
                return fields.map(function(fieldName){
                    return JSON.stringify(row[fieldName], replacer)
                }).join(',')
            })
            csv.unshift(fields.join(',')) // add header column
            csv = csv.join('\r\n');
            updateCsv(csv);
            */
            updateIsLoading(false)
            return
        }
        catch (e) {
            var statusString = "Error: " + e;
            const res = {status:statusString,rows:[]};
            updateRes(res);
            updateIsLoading(false)
            return
        }
    }

    useEffect(() => {
        fetchData()
      },[count])

    var PageNoData = <View><p>{JSON.stringify(res)}</p></View>
    var MustRefresh = <View><p>Please get updates to display table</p></View>
    var DevNote = <p></p>
    var ProdNote = <p></p>
  

    var MaintCostsDefault = <View>
        <Heading level={4}>Maintenance Costs History</Heading>
        <TableContainer columns={columnsDefault} data = {res['rows']} defaultSort = 'invoice_date'/>
        <br/><br/>
        {csv}
    </View>



    function renderSellerCostPerMileTables()
    {
        //res['rows'].map(mapItems);
        return <p>results exclude invoices marked as physical damage:<br/><br/>{JSON.stringify(res['rows'])}</p>
        //['seller']} - {item['cost_per_miles']
    }
    var SellerCostPerMileTable = <View>
        <Heading level={2}>Seller Cost per Mile</Heading>
        {renderSellerCostPerMileTables()}
        <br/><br/>
    </View>

    var SellerCostPerMileDaterangeTable = <View>
        <Heading level={2}>Seller Cost per Mile Between Dates</Heading>
        {renderSellerCostPerMileTables()}
        <br/><br/>
    </View>


    var MaintInvoiceItems = <View>
        <Heading level={2}>Invoice Items Details</Heading>
        <TableContainer columns={columnsMaintItems} data = {res['rows']} defaultSort = 'id'/>
        <br/><br/>
    </View>

    var RefreshButton = 
        <button onClick={() => setCount(count + 1)}>
                    PRESS TO GET LATEST UPDATES
        </button>
    
    var MiItemsParams = <View>
        <p>Enter an invoice_id</p>
        <TextField
            placeholder='invoice_id'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var YearMakeParams = <View>
        <p>Enter a year_make combination</p>
        <TextField
            placeholder='year_make'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var ModelParams = <View>
        <p>Enter a car model</p>
        <TextField
            placeholder='model'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var LicenseParams = <View>
        <p>Enter a car license</p>
        <TextField
            placeholder='license'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var VinParams = <View>
        <p>Enter a car vin</p>
        <TextField
            placeholder='vin'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var PriceOverParams = <View>
        <p>Enter the minimum price value</p>
        <TextField
            placeholder='min price'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var PriceUnderParams = <View>
        <p>Enter the maximum price value</p>
        <TextField
            placeholder='max price'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var MileageOverParams = <View>
        <p>Enter the minimum mileage value</p>
        <TextField
            placeholder='min mileage'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>

    var MileageUnderParams = <View>
        <p>Enter the maximum mileage value</p>
        <TextField
            placeholder='max mileage'
            onInput={(e) => updateSelectedObjId(e.target.value)}
        ></TextField><br/>
    </View>


    function handleRangeStartSelected(date)
    {
        updateSelectedObjRangeStartFull(date);
        const date2 = date.toISOString().substring(0, 10);
        updateSelectedObjRangeStart(date2);
    }
    function handleRangeEndSelected(date)
    {
        updateSelectedObjRangeEndFull(date);
        const date2 = date.toISOString().substring(0, 10);
        updateSelectedObjRangeEnd(date2);
    }

    var DateRangeParams = <View>
        <p>Enter earliest date</p>
        <DatePicker
            name="Range Start"
            selected={selectedObjRangeStartFull}
            onChange={(date) => handleRangeStartSelected(date)}
            showTimeSelect
            dateFormat="yyyy-MM-d"
            timeIntervals={60}
            />
        <br/><br/>
        <p>Enter latest date</p>
        <DatePicker
            name="Range End"
            selected={selectedObjRangeEndFull}
            onChange={(date) => handleRangeEndSelected(date)}
            showTimeSelect
            dateFormat="yyyy-MM-d"
            timeIntervals={60}
            />
        <br/>
    </View>

    var YearMakeModelParams = <View>
        <p>Enter a car year_make</p>
        <TextField
            placeholder='year_make'
            onInput={(e) => updateSelectedObjRangeStart(e.target.value)}
        ></TextField><br/>
        <br/>
        <p>Enter a car model</p>
        <TextField
            placeholder='model'
            onInput={(e) => updateSelectedObjRangeEnd(e.target.value)}
        ></TextField><br/>
        <br/>
    </View>



    var PriceRangeParams = <View>
        <p>Enter the lowest price value you want</p>
        <TextField
            placeholder='range_start'
            onInput={(e) => updateSelectedObjRangeStart(e.target.value)}
        ></TextField><br/>
        <br/>
        <p>Enter the highest price value you want</p>
        <TextField
            placeholder='range_end'
            onInput={(e) => updateSelectedObjRangeEnd(e.target.value)}
        ></TextField><br/>
        <br/>
    </View>


    var SellerCostPerMileDateRangeParams = <View>
        <p>Enter the lowest date value you want like 2022-12-15 00:00:00</p>
        <TextField
            placeholder='range_start'
            onInput={(e) => updateSelectedObjRangeStart(e.target.value)}
        ></TextField><br/>
        <br/>
        <p>Enter the highest date value you want like 2022-12-15 00:00:00</p>
        <TextField
            placeholder='range_end'
            onInput={(e) => updateSelectedObjRangeEnd(e.target.value)}
        ></TextField><br/>
        <br/>
    </View>


    var MileageRangeParams = <View>
        <p>Enter the lowest mileage value you want</p>
        <TextField
            placeholder='range_start'
            onInput={(e) => updateSelectedObjRangeStart(e.target.value)}
        ></TextField><br/>
        <br/>
        <p>Enter the highest mileage value you want</p>
        <TextField
            placeholder='range_end'
            onInput={(e) => updateSelectedObjRangeEnd(e.target.value)}
        ></TextField><br/>
        <br/>
    </View>


    return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Car Maintenance History</Heading>
        {process.env.REACT_APP_BACKEND==="dev" ? DevNote : ProdNote}
        <Heading level={4}>Want to Drill Down?</Heading>
        <p>Limit results based on criteria or look at the details of a particular invoice by selecting an option below.</p>
        
        <SelectField
            label="Drill Type"
            descriptiveText="Select the type of filter..."
            size="small"
            value={selectedObjType}
            onChange={(e) => updateSelectedObjType(e.target.value)}
        >
            <option value="">All Invoices</option>
            <option value="date_range">Date Range</option>
            <option value="year_make">Car Year and Make</option>
            <option value="model">Car Model</option>
            <option value="year_make_model">Car Year Make and Model</option>
            <option value="license">Car License</option>
            <option value="vin">Car Vin</option>
            <option value="price_over">Price Over</option>
            <option value="price_under">Price Under</option>
            <option value="price_range">Price Range</option>
            <option value="mileage_over">Mileage Over</option>
            <option value="mileage_under">Mileage Under</option>
            <option value="mileage_range">Mileage Range</option>
            <option value="seller_cost_per_mile">Seller Cost per Mile</option>
            <option value="seller_cost_per_mile_daterange">Seller Cost per Mile Daterange</option>
        </SelectField>
        <br/>
        {selectedObjType==='mi_items' ? MiItemsParams : undefined}
        {selectedObjType==='date_range' ? DateRangeParams : undefined}
        {selectedObjType==='year_make' ? YearMakeParams : undefined}
        {selectedObjType==='model' ? ModelParams : undefined}
        {selectedObjType==='license' ? LicenseParams : undefined}
        {selectedObjType==='vin' ? VinParams : undefined}
        {selectedObjType==='year_make_model' ? YearMakeModelParams : undefined}
        {selectedObjType==='price_over' ? PriceOverParams : undefined}
        {selectedObjType==='price_under' ? PriceUnderParams : undefined}
        {selectedObjType==='price_range' ? PriceRangeParams : undefined}
        {selectedObjType==='mileage_over' ? MileageOverParams : undefined}
        {selectedObjType==='mileage_under' ? MileageUnderParams : undefined}
        {selectedObjType==='mileage_range' ? MileageRangeParams : undefined}
        {selectedObjType==='seller_cost_per_mile' ? undefined : undefined}
        {selectedObjType==='seller_cost_per_mile_daterange' ? SellerCostPerMileDateRangeParams : undefined}
        <br/>
        {selectedObjType==='' ? RefreshButton : undefined}
        {selectedObjType==='mi_items' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='date_range' && selectedObjRangeStart!=="" && selectedObjRangeEnd!=="" ? RefreshButton : undefined}
        {selectedObjType==='year_make' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='model' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='license' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='vin' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='price_over' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='price_under' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='mileage_over' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='mileage_under' && selectedObjId!=="" && selectedObjId ? RefreshButton : undefined}
        {selectedObjType==='year_make_model' && selectedObjRangeStart!=="" && selectedObjRangeEnd!=="" ? RefreshButton : undefined}
        {selectedObjType==='price_range' && selectedObjRangeStart!=="" && selectedObjRangeEnd!=="" ? RefreshButton : undefined}
        {selectedObjType==='seller_cost_per_mile' ? RefreshButton : undefined}
        {selectedObjType==='seller_cost_per_mile_daterange' && selectedObjRangeStart!=="" && selectedObjRangeEnd!=="" ? RefreshButton : undefined}
        <br/><br/>
        <Text>
            {res.status === "success" && selectedObjType !== 'seller_cost_per_mile' && doNotDisplayTable===true ? MustRefresh:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === '' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'date_range' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'mi_items' ? MaintInvoiceItems:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'year_make' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'model' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'license' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'vin' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'year_make_model' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'price_over' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'price_under' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'price_range' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'mileage_over' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'mileage_under' ? MaintCostsDefault:undefined}
            {res.status === "success" && doNotDisplayTable === false && selectedObjType === 'mileage_range' ? MaintCostsDefault:undefined}
            {res.status === "success" && selectedObjType === 'seller_cost_per_mile' ? SellerCostPerMileTable:undefined}
            {res.status === "success" && selectedObjType === 'seller_cost_per_mile_daterange' ? SellerCostPerMileDaterangeTable:undefined}
            {res.status !== "success" ? PageNoData:undefined}
        </Text>
        <br/><br/><br/><br/>
        {is_loading ? <Loading /> : undefined}
    </main>
    );
}