import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';
import Vehicleserviceraccess from './vehicleserviceraccess';

export default function Vehiclecommands({userJwt, vehicleId}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [can_unlock, updateCanUnlock] = React.useState(false);
  const [can_enable, updateCanEnable] = React.useState(false);
  const [can_lock, updateCanLock] = React.useState(false);
  const [can_disable, updateCanDisable] = React.useState(false);
  const [can_check_fix_telemetry, updateCanCheckFixTelemetry] = React.useState(false);
  const [can_location, updateCanLocation] = React.useState(false);
  const [showCommandResponse, updateShowCommandResponse] = React.useState(true);
  const [command_response, updateCommandResponse] = React.useState([]);
  const [commandId, updateCommandId] = React.useState(false);

  const getVehicleCommands = async () => {
    updateIsLoading(true);
    if(vehicleId)
    {
      var carId = vehicleId;
      if(carId > 0)
      {
        //console.log('vehiclecommands vehicle '+carId);
        const myInit = {queryStringParameters:{ 
            back_end: process.env.REACT_APP_BACKEND, 
            jwt:userJwt,
            api_endpoint:"commandvehicle",
            request_type:"GET",
            vehicle_id: carId
        }};
        try {
          const res = await API.get('adminWebTools','/ago',myInit)
          //console.log(JSON.stringify(myInit)); // temporary
          //console.log(JSON.stringify(res)); // temporary
          //console.log(res['vehicle_commands']);
          var vehicleCommands = []
          if('vehicle_commands' in res)
          {
            vehicleCommands = res['vehicle_commands'];
            //console.log('vehicleCommands: '+vehicleCommands)
          }
          if(vehicleCommands.includes('lock'))
          {
            updateCanLock(true);
          }
          if(vehicleCommands.includes('unlock'))
          {
            updateCanUnlock(true);
          }
          if(vehicleCommands.includes('enable'))
          {
            updateCanEnable(true);
          }
          if(vehicleCommands.includes('disable'))
          {
            updateCanDisable(true);
          }
          if(vehicleCommands.includes('location'))
          {
            updateCanLocation(true);
          }
          if(vehicleCommands.includes('check_fix_telemetry'))
          {
            updateCanCheckFixTelemetry(true);
          }
        } catch (e) {
          console.log('error fetching vehicle commands: ' + JSON.stringify(e))
        }
      }
    }
    updateIsLoading(false);
  }
  
  useEffect(() => {
    getVehicleCommands();
  },[userJwt])


  async function sendLocation() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        vehicle_command:"location",
        command:"SEND"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        if('command_id' in res)
        {
          updateCommandId(res['command_id']);
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }
  }

  
  async function sendLock() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        vehicle_command:"lock",
        command:"SEND"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        if('command_id' in res)
        {
          updateCommandId(res['command_id']);
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }
  }

  async function sendUnlock() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        vehicle_command:"unlock",
        command:"SEND"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        if('command_id' in res)
        {
          updateCommandId(res['command_id']);
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }
  }

  async function sendEnable() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        vehicle_command:"enable",
        command:"SEND"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        if('command_id' in res)
        {
          updateCommandId(res['command_id']);
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }
  }

  async function sendDisable() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        vehicle_command:"disable",
        command:"SEND"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        if('command_id' in res)
        {
          updateCommandId(res['command_id']);
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }
  }

  async function sendCheckFixTelemetry() {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        vehicle_command:"check_fix_telemetry",
        command:"SEND"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        if('command_id' in res)
        {
          updateCommandId(res['command_id']);
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }
  }


  async function getRequestUpdate(requestId) {
    updateIsLoading(true)
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:userJwt,
        api_endpoint:"commandvehicle",
        request_type:"POST",
        vehicle_id: vehicleId,
        command:"CHECK",
        vehicle_command_id:requestId
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log(JSON.stringify(res))
        updateCommandResponse(res);
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log(JSON.stringify(e))
        var errorRes = {'error':e}
        updateCommandResponse(errorRes);
        updateIsLoading(false)
        return
    }

  }


  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem", width:"100%"}}>
          <Divider />
          <Heading level={2}>Vehicle Commands</Heading>
          <Flex direction="column" gap="1rem" >
            <Heading level={4}>Unlock & Enable</Heading>
            <Flex direction="column">
                { can_unlock ? 
                    <Button onClick={() => sendUnlock()}>UNLOCK DOORS</Button>
                  : <Button backgroundColor={'#9E9E9E'}>UNLOCK DOORS UNAVAILABLE</Button>
                }
            </Flex>
            <Flex direction="column">
                { can_enable? 
                    <Button onClick={() => sendEnable()}>ENABLE ENGINE</Button>
                  : <Button backgroundColor={'#9E9E9E'}>ENABLE ENGINE UNAVAILABLE</Button>
                }
            </Flex>
            <Divider />
          </Flex>
          <Flex direction="column" gap="1rem" >
            <Heading level={4}>Lock & Disable</Heading>
            <Flex direction="column">
                { can_lock ? 
                    <Button onClick={() => sendLock()}>LOCK DOORS</Button>
                  : <Button backgroundColor={'#9E9E9E'}>LOCK DOORS UNAVAILABLE</Button>
                }
            </Flex>
            <Flex direction="column">
                { can_disable ? 
                    <Button onClick={() => sendDisable()}>DISABLE ENGINE</Button>
                  : <Button backgroundColor={'#9E9E9E'}>DISABLE ENGINE UNAVAILABLE</Button>
                }
            </Flex>
            <Divider />
          </Flex>
          <Flex direction="column" gap="1rem" >
            <Heading level={4}>Other Commands</Heading>
            <Vehicleserviceraccess vehicleId={vehicleId} />
            <Flex direction="column">
                { can_location ? 
                    <Button onClick={() => sendLocation()}>GET LOCATION</Button>
                  : undefined
                }
            </Flex>
            <Flex direction="column">
                { can_check_fix_telemetry ? 
                    <Button onClick={() => sendCheckFixTelemetry()}>TROUBLESHOOT API</Button>
                  : undefined
                }
            </Flex>
            {
              command_response.length < 1 ? undefined : 
              showCommandResponse === false ? 
                  <Flex direction="column">
                    <Divider />
                    <Button onClick={() => updateShowCommandResponse(true)}>
                      SHOW SERVER RESPONSE
                    </Button>
                    <Divider />
                  </Flex>
              :
                  <Flex direction="column">
                    <Divider />
                    <Heading level={4}>Server Response</Heading>
                    <Flex direction="column">
                        <Text style={{maxWidth:"350px"}}>{JSON.stringify(command_response)}</Text>
                    </Flex>
                    {
                      commandId === false ? undefined :
                      <Button onClick={() => getRequestUpdate(commandId)}>
                        GET STATUS UPDATE
                      </Button>
                    }
                    <Button onClick={() => updateShowCommandResponse(false)}>
                      HIDE SERVER RESPONSE
                    </Button>
                    <Divider />
                  </Flex>
            }
          </Flex>
        </View>
      </Flex>
  );
}