import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button, TextField} from '@aws-amplify/ui-react';

export default function Reservationcommands({resId, resData}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [showCommandResponse, updateShowCommandResponse] = React.useState(true);
  const [command_response, updateCommandResponse] = React.useState([]);
  const [showConfirm, updateShowConfirm] = React.useState(false);
  const [confirmMessage, updateConfirmMessage] = React.useState(false);
  const [resAction, updateResAction] = React.useState(false);
  const [showForm, updateShowForm] = React.useState(false);
  const [fullRefund, updateFullRefund] = React.useState(true);
  const [cashNotCredits, updateCashNotCredits] = React.useState(true);
  const [switchToCarId, updateSwitchToCarId] = React.useState(0);
  const [showCommandSent, updateShowCommandSent] = React.useState(false);

  
  
  async function showFormWindow(action) {
    updateIsLoading(true);
    await updateResAction(action);
    updateShowForm(true);
    updateIsLoading(false);
  }

  async function showConfirmWindow() {
    updateIsLoading(true);
    var msg = "Are you sure?";
    switch(resAction)
    {
      case 'getcurrent':
          let returnCost = await reservationCurrentCalculate();
          msg = "Are you sure you'd like to get reservation " + resId + " current? It will cost $" + returnCost;
          await updateConfirmMessage(msg);
        break;
      case 'close':
          msg = "Are you sure you'd like to close reservation " + resId + "?";
          await updateConfirmMessage(msg);
        break;
      case 'cancel':
          msg = "Are you sure you'd like to cancel  reservation " + resId + " and issue a ";
          if(fullRefund)
            {
              msg = msg + "full refund";
            } else {
              msg = msg + "full refund";
            }
          if(cashNotCredits)
            {
              msg = msg + " to the driver's credit/debit card?";
            } else {
              msg = msg + " in credits to the driver's account?";
            }
          await updateConfirmMessage(msg);
        break;
      case 'switch':
          msg = "Are you sure you'd like to switch reservation " + resId + " to vehicle #" + switchToCarId + "?";
          await updateConfirmMessage(msg);
        break;
      case 'activate':
          msg = "Are you sure you'd like to activate reservation " + resId + "?";
          await updateConfirmMessage(msg);
        break;
      default:
        msg = {'error':'Invalid action selection'}
        await updateConfirmMessage(msg);
        break;
    }
    updateShowConfirm(true);
    updateIsLoading(false);
  }

  async function confirm()
  {
    var res = {}
    updateShowForm(false);
    updateShowConfirm(false);
    updateShowCommandSent(true);
    switch(resAction)
    {
      case 'getCurrent':
          getResCurrent();
        break;
      case 'cancel':
          cancelReservation();
        break;
      case 'close':
          closeReservation();
        break;
      case 'switch':
          switchCarAssignedToReservation();
        break;
      case 'activate':
          activateReservation();
        break;
      default:
        res = {'error':'Invalid action selection'}
        break;
    }
  }


  
  const getResCurrent = async () => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresmakepayment",
      res_id: resId,
      promo_code: "",
      ago_points: "0",
      payment_type: "extension"
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    } catch (e) {
      const res = {status:"Error closing reservation: " + JSON.stringify(e)};
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    }
    updateIsLoading(false);
  }

  const reservationCurrentCalculate = async () => {
    var returnCost = "Error calculating get current"
    updateIsLoading(true)
    var session = await Auth.currentSession()      
    var accessToken = session.getAccessToken()
    var jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresextend",
      res_id: resId,
      mode: "penalty"
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      if(res['status']=='success')
      {
        const myInit = {queryStringParameters:{ 
          back_end: process.env.REACT_APP_BACKEND,
          jwt: jwt,
          api_endpoint: "passthroughrescomputepayment",
          res_id: resId,
          promo_code: "",
          ago_points: "0",
          payment_type: "extension"
        }};
        try {
          const res = await API.get('adminWebTools','/ago',myInit)
          console.log(JSON.stringify(res))
          returnCost = res['invoice']['total'].toFixed(2)
        } catch (e) {
          const res = {status:"Error getting reservation current: " + JSON.stringify(e)};
          updateCommandResponse(res);
          updateShowCommandResponse(true);
        }
      } else {
        updateCommandResponse(res);
        updateShowCommandResponse(true);
        console.log(JSON.stringify(res));
      }
    } catch (e) {
      const res = {status:"Error calculating get reservation current: " + JSON.stringify(e)};
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    }
    updateIsLoading(false);
    return returnCost;
  }

  const closeReservation = async () => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "reservationclose",
      res_id: resId
    }};
    try {
      var res = await API.get('adminWebTools','/ago',myInit)
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    } catch (e) {
      if(!res)
      {
        var res = {status:"Error closing reservation: " + JSON.stringify(e)};
      } else {
        if(res['status']===false)
        {
          var res = {status:"Error closing reservation: " + JSON.stringify(e)};
        }
      }
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    }
    updateIsLoading(false);
  }
  

  const cancelReservation = async () => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresmakecancel",
      res_id: resId,
      started: true,
      full_refund: fullRefund,
      cash_refund: cashNotCredits,
      reason: "Support Portal"
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    } catch (e) {
      const res = {status:"Error computing cancel for reservation: " + JSON.stringify(e)};
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    }
    updateIsLoading(false);
  }


  const switchCarAssignedToReservation = async () => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresswitchcar",
      res_id: resId,
      vehicle_id: switchToCarId
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    } catch (e) {
      const res = {status:"Error: " + JSON.stringify(e)};
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    }
    updateIsLoading(false);
  }

  
  const activateReservation = async () => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresactivate",
      res_id: resId
    }};
    try {
      const res = await API.get('adminWebTools','/ago',myInit)
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    } catch (e) {
      const res = {status:"Error activating reservation: " + JSON.stringify(e)};
      updateCommandResponse(res);
      updateShowCommandResponse(true);
    }
    updateIsLoading(false);
  }
  

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem", width:"100%"}}>
          <Divider />
          <Flex direction="column" gap="1rem" >
            <Heading level={2}>Reservation Commands</Heading>
            {
              showCommandSent ?
                <View>
                  <Heading level={4}>Command Sent</Heading>
                  <Text>View the Server Response below</Text>
                  <br/>
                  <br/>
                  <Button onClick={() => updateShowCommandSent(false)}>
                        EXIT - SHOW COMMANDS
                  </Button>
                </View>
              :
              showConfirm ? 
                <View>
                  <Heading level={4}>Are you sure?</Heading>
                  <Text>{confirmMessage}</Text>
                  <br/>
                  <Button onClick={() => confirm()}>
                      CONFIRM
                  </Button>
                  <br/>
                  <br/>
                  <Button onClick={() => updateShowConfirm(false)}>
                        EXIT
                  </Button>
                </View>
              : 
              showForm ? 
                <Flex direction="column" gap="1rem">
                  {
                    resAction==='getcurrent' ? 
                    <Flex direction="column">
                      <Heading level={4}>Get Reservation Current</Heading>
                      <Text>Charge late fees and extend so reservation is about to end.</Text>
                      <Text><i>Hint: This is helpful when a driver is late returning the vehicle but wants to continue driving.</i></Text>
                      <Button onClick={() => showConfirmWindow()}>
                          SUBMIT
                      </Button>
                    </Flex>
                  : resAction==='close' ? 
                    <Flex direction="column">
                      <Heading level={4}>Close Reservation</Heading>
                      <Text>If a reservation did not automatically close, you can manually close it by clicking submit below.</Text>
                      <Text><i>Hint: This is helpful when vehicle GPS is unavailable at the time of return.</i></Text>
                      <Button onClick={() => showConfirmWindow()}>
                          SUBMIT
                      </Button>
                    </Flex>
                  : resAction==='cancel' ? 
                    <Flex direction="column">
                      <Heading level={4}>Cancel Reservation</Heading>
                      <Text>Do not cancel reservations that actually occured.</Text>
                      {fullRefund == true ? 
                          <Text style={{textAlign:'center'}}>
                            <Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateFullRefund(false)}>Issue a Full Refund (click to change)</Button>
                          </Text>
                        : 
                          <Text style={{textAlign:'center'}}>
                            <Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateFullRefund(true)}>Do not refund deposit (click to change)</Button>
                          </Text>
                      }
                      {cashNotCredits == true ? 
                          <Text style={{textAlign:'center'}}>
                            <Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateCashNotCredits(false)}>Issue Refund to Card (click to change)</Button>
                          </Text>
                        : 
                          <Text style={{textAlign:'center'}}>
                            <Button style={{border:'none', fontStyle: 'italic'}} onClick={() => updateCashNotCredits(true)}>Issue Refund in Credits (click to change)</Button>
                          </Text>
                      }
                      <Button onClick={() => confirm()}>
                          SUBMIT
                      </Button>
                    </Flex>
                  : resAction==='switch' ? 
                    <Flex direction="column">
                      <Heading level={4}>Switch Car Assigned to Reservation</Heading>
                      <Text><TextField
                          value={switchToCarId}
                          label="New Car ID"
                          descriptiveText="Car ID of the new car"
                          hasError={parseInt(switchToCarId) < 1 || switchToCarId==="" ? true : false}
                          labelHidden={parseInt(switchToCarId) < 1 || switchToCarId==="" ? false : true}
                          errorMessage="This is a required field"
                          placeholder="1234"
                          onChange={(e) => updateSwitchToCarId(e.currentTarget.value)}
                      /></Text>
                      <Button onClick={() => showConfirmWindow("close")}>
                          SUBMIT
                      </Button>
                    </Flex>
                  : 
                  resAction==='activate' ? 
                    <Flex direction="column">
                      <Heading level={4}>Activate Reservation</Heading>
                      <Text>If a reservation did not automatically activate, you can manually activate it by clicking submit below.</Text>
                      <Text><i>Hint: This is a good way to troubleshoot why a driver doesn't have keys they should</i></Text>
                      <Button onClick={() => showConfirmWindow()}>
                          SUBMIT
                      </Button>
                    </Flex>
                  :
                    <Flex direction="column">
                      <Heading level={4}>Invalid Selection</Heading>
                      <Text>{resAction} is not a recognized selection</Text>
                    </Flex>
                  }
                  <br/>
                  <Button onClick={() => updateShowForm(false)}>
                        EXIT
                  </Button>
                </Flex>
              : 
              <Flex direction="column" gap="1rem">
                {
                  resData['status'] !== 'cancelled' ? undefined :
                    <Flex direction="column">
                      <Button style={{backgroundColor:'#9E9E9E'}}>
                          CANNOT ACT ON CANCELLED RESERVATION
                      </Button>
                    </Flex>
                }
                {
                  resData['status'] !== 'closed' ? undefined :
                    <Flex direction="column">
                      <Button style={{backgroundColor:'#9E9E9E'}}>
                          CANNOT ACT ON CLOSED RESERVATION
                      </Button>
                    </Flex>
                }
                {
                  resData['status'] === 'cancelled' ? undefined :
                  resData['status'] === 'closed' ? undefined :
                  <Flex direction="column">
                    <Button onClick={() => showFormWindow("close")}>
                        CLOSE RESERVATION
                    </Button>
                  </Flex>
                }
                {
                  resData['status'] === 'cancelled' ? undefined :
                  resData['status'] === 'closed' ? undefined :
                  <Flex direction="column">
                    <Button onClick={() => showFormWindow("cancel")}>
                        CANCEL RESERVATION
                    </Button>
                  </Flex>
                }
                {
                  resData['status'] === 'cancelled' ? undefined :
                  resData['status'] === 'closed' ? undefined :
                  <Flex direction="column">
                    <Button onClick={() => showFormWindow("switch")}>
                        SWITCH CAR
                    </Button>
                  </Flex>
                }
                {
                  resData['status'] === 'cancelled' ? undefined :
                  resData['status'] === 'closed' ? undefined :
                  <Flex direction="column">
                    <Button onClick={() => showFormWindow("activate")}>
                        ACTIVATE RESERVATION
                    </Button>
                  </Flex>
                }
                {
                  resData['status'] === 'cancelled' ? undefined :
                  resData['status'] === 'closed' ? undefined :
                  resData['end'] > (Date.now()/1000) ? undefined :
                    <Flex direction="column">
                      <Button onClick={() => showFormWindow("getcurrent")}>
                          GET RESERVATION CURRENT
                      </Button>
                    </Flex>
                }
              </Flex>
            }
            <Divider />
            {
              command_response.length < 1 ? undefined : 
              showCommandResponse === false ? 
                  <Flex direction="column">
                    <Divider />
                    <Button onClick={() => updateShowCommandResponse(true)}>
                      SHOW SERVER RESPONSE
                    </Button>
                    <Divider />
                  </Flex>
              :
                  <Flex direction="column">
                    <Divider />
                    <Heading level={4}>Server Response</Heading>
                    <Flex direction="column">
                        <Text style={{maxWidth:"350px"}}>{JSON.stringify(command_response)}</Text>
                    </Flex>
                    <Button onClick={() => updateShowCommandResponse(false)}>
                      HIDE SERVER RESPONSE
                    </Button>
                    <Divider />
                  </Flex>
            }
          </Flex>
        </View>
      </Flex>
  );
}