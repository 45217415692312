import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Button, Heading } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';

export default function Checkr1({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [pageDoc, setPageDoc] = useState(0);
  const [count, setCount] = useState(0);

  async function checkUrlForDoc()
  {
    if(isHomepage)
    {
      console.log('isHomepage=' + isHomepage);
      setPageDoc(isHomepage);
      console.log('pageDoc set by isHomepage');
    } else {
      var pageDoc = window.location.pathname.replace("/","");
      console.log('pageDoc start=' + pageDoc);
      pageDoc = pageDoc.replace("agreements","");
      pageDoc = pageDoc.replace("/","");
      console.log('pageDoc=' + pageDoc);
      setPageDoc(pageDoc);
      console.log('pageDoc set by URL');
    }
    fetchTermsAgreed();
  }

  async function fetchTermsAgreed() {
    if(pageDoc=="authorization")
    {
      console.log("sending to checkr3, authorization...");
      var apiEndpoint = "checkr3";
    } else {
      if(pageDoc=="disclosure")
      {
        console.log("sending to checkr2, disclosure...");
        var apiEndpoint = "checkr2";
      } else {
        if(pageDoc=='fcra')
        {
          console.log("sending to checkr1, fcra...");
          var apiEndpoint = "checkr1";
        } else {
          var apiEndpoint = "";
        }
      }
    }
    updateIsLoading(true)
    if(apiEndpoint !== "")
    {
      try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":apiEndpoint
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateRes(res)
        console.log('response from get ' + apiEndpoint + ':');
        console.log(JSON.stringify(res));
      } catch (e) {
          //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
          //updateRes(res)
      }
      updateIsLoading(false);
    } else {
      console.log('no pageDoc yet, waiting for pageDoc...');
    }
  }

  useEffect(() => {
    checkUrlForDoc();
  },[count])
  
  useEffect(() => {
    setCount(count+1);
  },[window.location.pathname])

  async function setTermsAgree()
  {
    updateIsLoading(true);
    try {
        if(pageDoc=="authorization")
        {
          console.log('sending to checkr3... authorization...');
          var apiEndpoint = "checkr3";
        } else {
          if(pageDoc=="disclosure")
          {
            console.log('sending to checkr2... disclosure...');
            var apiEndpoint = "checkr2";
          } else {
            console.log('sending to checkr1... fcra...');
            var apiEndpoint = "checkr1";
          }
        }
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "confirm_terms_agree":true,
            "api_endpoint":apiEndpoint
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res));
        fetchTermsAgreed()
        if(typeof isHomepage==='boolean')
        { } else {
          window.location.reload();
        }
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
      updateIsLoading(false)
    }
  }
  const renderTermsAgreed = () => {
    if(is_loading){
      return (
        <p>Loading...</p>
      )
    }
    if(!res['terms_agreed_string']){
      return (
        <p>Please review and confirm agreement.</p>
      )
    }
    return (
     <p>{res['terms_agreed_string']}</p>
    )
  }


  var CheckrDivOne = 
  <div style={{paddingTop:50, paddingBottom:15, paddingLeft:'7%', paddingRight:'7%', backgroundColor:'#e6e7ed', maxHeight:700, overflow:'scroll', width:'95%'}}>
      <p style={{textAlign:'justify'}}>Ago Technologies LLC (the 'Company') has engaged Checkr, Inc. to obtain a consumer report and/or investigative consumer report for employment purposes. Checkr Inc. will provide a background investigation as a pre-condition of your engagement with the Company and in compliance with federal and state employment laws.</p>
      <p style={{textAlign:'justify'}}>If you have any questions related to the screening process, please contact us at applicant.checkr.com.</p>
      <p style={{textAlign:'justify'}}>Rights under the FCRA:</p>
      <p style={{textAlign:'justify'}}>The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and privacy of information in the files of consumer reporting agencies. There are many types of consumer reporting agencies, including credit bureaus and specialty agencies (such as agencies that sell information about check writing histories, medical records, and rental history records). Here is a summary of your major rights under the FCRA. For more information, including information about additional rights, go to http://www.consumerfinance.gov/learnmore or write to: Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.</p>
      <p style={{textAlign:'justify'}}>You must be told if information in your file has been used against you. Anyone who uses a credit report or anohter type of consumer report to deny your application for credit, insurance, or employment - or to take another adverse action against you - must tell you, and must give you the name, address, and phone number of the agency that provided the information.</p>
      <p style={{textAlign:'justify'}}>You have the right to know what is in your file. You may request and obtain all the information about you in the files of a consumer reporting agency (your 'file disclosure'). You will be required to provide proper identification, which may include your Social Security number. In many cases, the disclosure will be free. You are entitled to a free file disclosure if:</p>
      <ul>
        <li>a person has taken adverse action against you because of the information in your credit report;</li>
        <li>you are the victim of identify theft and place a fraud alert in your file;</li>
        <li>your file contains inaccurate information as a result of fraud; you are on public assistance;</li>
        <li>you are unemployed but expect to apply for employment within 60 days. In addition, all consumers are entitled to one free disclosure every 12 months upon request from each nationwide credit bureau and from nationwide specialty consumer reporting agencies. See www.consumerfinance.gov/learnmore for additional information.</li>
      </ul>
      <p style={{textAlign:'justify'}}>You have the right to ask for a credit score. Credit scores are numerical summaries of your credit-worthiness based on information from credit bureaus. You may request a credit score from consumer reporting agencies that create scores or distribute scores used in redidential real property loans, but you will have to pay for it. In some mortgage transactions, you will receive a credit score information for free from the mortgage lender.</p>
      <p style={{textAlign:'justify'}}>You have the right to dispute incomplete or inaccurate information. If you identify information in your file that is incomplete or inaccurate, and report it to the consumer reporting agency, the agency must investigate unless your dispute is frivolous. See http://www.consumerfinance.gov/learnmore for an explanation of dispute procedures.</p>
      <p style={{textAlign:'justify'}}>Consumer reporting agencies must correct or delete inaccurate, incomplete, or unverifiable information. Inaccurate, incomplete, or unverifiable information must be removed or corrected, usually within 30 days. However, a consumer reporting agency may continue to report information it has verified as accurate.</p>
      <p style={{textAlign:'justify'}}>Consumer reporting agencies may not report outdated negative information. In most cases, a consumer reporting agency may not report negative information that is more than seven years old, or bankruptcies that are more than 10 years old.</p>
      <p style={{textAlign:'justify'}}>Access to your file is limited. A consumer reporting agency may provide information about you only to people with a valid need -- usually to consider an application with a creditor, insurer, employer, landlord, or other business. The FCRA specifies those with a valid need for access.</p>
      <p style={{textAlign:'justify'}}>You must give your consent for reports to be provided to employers. A consumer reporting agency may not give out information about you to your employer, or a potential employer, without your written consent given to the employer. Written consent generally is not required in the trucking industry. For more information, go to www.consumerfinance.gov/learnmore.</p>
      <p style={{textAlign:'justify'}}>You may limit 'prescreened' offers of credit and insurance you get based on information in your credit report. Unsolicited 'prescreened' offers for credit and insurance must include a toll-free phone number you can call if you choose to remove your name and address from the lists these offers are based on. You may opt out with the nationwide credit bureaus at 1-888-5-OPTOUT (1-888-567-8688).</p>
      <p style={{textAlign:'justify'}}>You may see damages from violators. If a consumer reporting agency, or, in some cases, a user of consumer reports or a furnisher of information to a consumer reporting agency violates the FCRA, you may be able to sue in state or federal court.</p>
      <p style={{textAlign:'justify'}}>Identify theft victims and active duty military personnel have additional rights. For more information, visit www.consumerfinance.gov/learnmore. States may enforce the FCRA, and many states have their own consumer reporting laws. In some cases, you may have more rights under state law. For more information, contact your state or local consumer protection agency or your state Attorney General. For information about your federal rights, contact:</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  1.a.  Banks, savings associations, and credit unions with total assets of over $10 billion and their affiliates<br/>
      b. Such affiliates that are not banks, savings associations, or credit unions also should list,<br/>
      CONTACT:  a. Consumer Financial Protection Bureau 1700 G. Street N.W. Washington, DC 20552. b. Federal Trade Commission: Consumer Response Center - FCRA</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  in addition to the CFPB:<br/>
      CONTACT:  Washington, DC 20580 (877) 382-4357</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  2. To the extent not included in item 1 above:<br/>a. National banks, federal savings associations, and federal branches and federal agencies of foreign banks<br/>
      b. State member banks, branches and agencies of foreign banks (other than federal branches, federal agencies, and Insured State Branches of Foreign Banks), commercial lending companies owned or controlled by foreign banks, and organizations operating under section 25 or 25A of the Federal Reserve Act.<br/>
      c. Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state savings associations<br/>
      d. Federal Credit Unions</p>
      <p style={{textAlign:'justify'}}>CONTACT:  a. Office of the Comptroller of the Currency Customer Assistance Group 1301 McKinney Street, Suite 3450 Houston, TX 77010-9050<br/>
      b. Federal Reserve Consumer Help Center P.O. Box. 1200 Minneapolis, MN 55480<br/>
      c. FDIC Consumer Response Center 1100 Walnut Street, Box #11 Kansas City, MO 64106<br/>
      d. National Credit Union Administration Office of Consumer Protection (OCP) Division of Consumer Compliance and Outreach (DCCO)<br/>
      1775 Duke Street Alexandria, VA 22314</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  Air carriers<br/>
      CONTACT:  Asst. General Counsel for Aviation Enforcement & Proceedings Aviation Consumer Protection Division Department of Transporation<br/>
      1200 New Jersey Avenue, S.E. Washington, DC 20423</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  4. Creditors Subject to the Surface Transportation Board<br/>
      CONTACT:  Office of Proceedings, Surface Transportation Board Department of Transportation 395 E Street, S.W.<br/>
      Washington, DC 20423</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  5. Creditors Subject to the Packers and Stockyards Act, 1921<br/>
      CONTACT:  Nearest Packers and Stockyards Administration area supervisor</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  6. Small Business Investment Companies<br/>
      CONTACT:  Associate Deputy Administrator for Capital Access United States Small Business Administration 409 Third Street, S.W., 8th Floor Washington, DC 20549</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  7. Brokers and Dealers<br/>
      CONTACT:  Securities and Exchange Commission 100 F Street, N.E. Washington, DC 20549</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  8. Federal Land Banks, Federal Land Bank Associations, Federal Intermediate Credit Banks, and Production Credit Associations<br/>
      CONTACT:  Farm Credit Administration 1501 Farm Credit Drive McLean, VA 22102-5090</p>
      <p style={{textAlign:'justify'}}>TYPE OF BUSINESS:  9. Retailers, Finance Companies, and All Other Creditors Not Listed Above<br/>
      CONTACT:  FTC Regional Office for region in which the creditor operates or Federal Trade Commission: Consumer Response Center - FCRA<br/>
      Washington, DC 20580 (877) 382-4357</p>
      <p style={{textAlign:'justify'}}>Rights under the FCRA acknowledgment</p>
      <p style={{textAlign:'justify'}}>By clicking 'agree', I acknowledge receipt of the Summary of Your Rights Under the Fair Credit Reporting Act (FCRA) and certify that I have read and understand this document.</p>
  </div>

  var CheckrDivTwo =  <div style={{paddingTop:50, paddingBottom:15, paddingLeft:'7%', paddingRight:'7%', backgroundColor:'#e6e7ed', maxHeight:700, overflow:'scroll', width:'95%'}}>
  <p style={{textAlign:'justify'}}>Ago Technologies LLC (the 'Company') may obtain information about you from a third party consumer reporting agency for employment purposes. Thus, you may be the subject of a 'consumer report' and/or an 'investigative consumer report' which may include information about your character, general reputation, personal characteristics, and/or mode of living, and which can involve personal interviews with sources such as your neighbors, friends, or associates. These reports may contain information regarding your criminal history, social security verification, motor vehicle records ('driving records'), verification of your education or employment history, or other background checks.</p>
  <p style={{textAlign:'justify'}}>You have the right, upon written request made within a reasonable time, to request whether a consumer report has been run about you, and disclosure of the nature and scope of any investigative consumer report and to request a copy of your report. Please be advised that the nature and scope of the most common form of investigative consumer report is an employment history or verification. These searches will be conducted by Checkr, Inc., 2505 Mariposa St. San Fransisco, CA 94110 | 844-824-3247 | applicant.checkr.com. The scope of this disclosure is all-encompassing, however, allowing the Company to obtain from any outside organization all manner of consumer reports throughout the course of your employment to the extent permitted by law.</p>
  <p style={{textAlign:'justify'}}>Para informacion en espanol, visite www.consumerfinance.gov/learnmore o escribe al Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.</p>
  <p style={{textAlign:'justify'}}>Disclosure acknowledgment</p>
  <p style={{textAlign:'justify'}}>By clicking 'agree', I acknowledge receipt of the Disclosure Regarding Background Check Investigation and certify that I have read and understand this document.</p>
</div>

  var CheckrDivThree =  <div style={{paddingTop:50, paddingBottom:15, paddingLeft:'7%', paddingRight:'7%', backgroundColor:'#e6e7ed', maxHeight:700, overflow:'scroll', width:'95%'}}>
  <p style={{textAlign:'justify'}}>I acknowledge receipt of the separate document entitled DISCLOSURE REGARDING BACKGROUND INVESTIGATION and A SUMMARY OF YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and certify that I have read and understand both of those documents. I hereby authorize the obtaining of 'consumer reports' and/or 'investigative consumer reports' by the Company at any time after receipt of this authorization and throughout my employment, if applicable. To this end, I hereby authorize, without reservation, any law enforcement agency, administrator, state or federal agency, institution, school or university (public or private), information service bureau, employer, or insurance company to furnish any and all background information requested by Checkr, Inc., One Montgomery Street, Suite 2000, San Francisco, CA 94104 | 844-824-3247 | applicant.checkr.com. I agree that an electronic copy of this Authorization shall be as valid as the original.</p>
  <p style={{textAlign:'justify'}}>New York applicants only: Upon request, you will be informed whether or not a consumer report was requested by the Company, and if such report was requested, informed of the name and address of the consumer reporting agency that furnished the report. You have the right to inspect and receive a copy of any investigative consumer report requested by the Company by contacting the consumer reporting agency identified above directory. By signing below, you acknowledge receipt of of Article 23-A of the New York Correction Law.</p>
  <p style={{textAlign:'justify'}}>Washington State applicants only: You also have the right to request from the consumer reporting agency written summary of your rights and remedies under the Washington Fair Credit Reporting Act.</p>
  <p style={{textAlign:'justify'}}>Minnesota and Oklahoma applicants only : Please check the box on the next page if you would like to receive a copy of a consumer report if one is obtained by the Company.</p>
  <p style={{textAlign:'justify'}}>California applicants only : Under California Civil Code section 1786.22, you are entitled to find our what is in the CRA's file on you with proper identification, as follows:</p>
  <ul>
    <li>In person, by visual inspection of your file during normal business hours and on reasonable notice. You also may request a copy of the information in person. The CRA may not charge you more than the actual copying cost for providing you with a copy of your file.</li>
    <li>A summary of all information contained in the CRA file on you that is required to be provided by the California Civil Code will be provided to you via telephone, if you have made a written request, with proper identification, for telephone disclosure, and the toll charge, if any, for the telephone call is prepaid by or charged directory to you.</li>
    <li>By requesting a copy be sent to a specified addressee by certified mail. CRAs complying with requests for certified mailings shall not be liable for disclosures to third parties caused by mishandling of mail after such mailings leave CRAs. 'Proper Identification' includes documents such as a valid driver's license, social security account number, military identification card, and credit cards. Only if you cannot identify yourself with such information may the CRA require additional information concerning your employment and personal or family history in order to verify your identify. The CRA will provide trained personnel to explain any information furnished to you and will provide a written explanation of any coded information contained in files maintained on you. This written explanation will be provided whenever a file is provided to you for visual inspection. You may be accompanied by one other person of your choosing, who must furnish reasonable identification. An CRA may require you to furnish a written statement granting permission to the CRA to discuss your file in such person's presence. Please check the box on the next page if you would like to receive a copy of an investigative consumer report or consumer credit report at no charge if one is obtained by the Company whenever you have a right to receive such a copy under California law.</li>
  </ul>
</div>

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>{pageDoc=="authorization" ? "ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND CHECK" : pageDoc=="disclosure" ? "Background Check Disclosure" : "Summary of Rights under FCRA"}</Heading>
        {renderTermsAgreed()}
        <br/>
        {pageDoc=="authorization" ? CheckrDivThree : pageDoc=="disclosure" ? CheckrDivTwo : CheckrDivOne}
        <br/><br/>
        {res['terms_agreed'] ? typeof isHomepage==='boolean' ? <p><Button onClick={() => window.location.reload()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5, width:'100%'}}>Continue...</Button></p> : undefined : <p><Button onClick={() => setTermsAgree()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5, width:'100%'}}>I Agree and Understand</Button></p>}
        {is_loading ? <Loading /> : undefined}
    </main>
  );
}