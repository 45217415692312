import React, { useState, useEffect } from 'react'
import { View, Text, Heading, Button, TextField, Flex, Divider} from '@aws-amplify/ui-react';
import Loadingmini from './loadingmini';
import { API, Auth } from 'aws-amplify';
import Searchselect from './searchselect';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export const Reservationcreate = ({fleetCode, vehicles, selectedVehicle, drivers, selectedDriver, refreshParent}) => {
  const roundBack = (date,hours) => {
    date.setHours(date.getHours() - hours);
    date.setMinutes(0, 0, 0);
    return date;
  }
  const [is_loading, updateIsLoading] = React.useState(false);
  const [displayWindow, updateDisplayWindow] = useState(false);
  const [makePaymentError, updateMakePaymentError] = useState(false);
  const [createResError, updateCreateResError] = useState(false);
  const [showConfirmInfo, updateShowConfirmInfo] = useState(false);
  const [showReservationSelect, updateShowReservationSelect] = useState(false);
  const [resSearchError, updateResSearchError] = useState(false);
  const [vehicleList, updateVehicleList] = useState([]);
  const [driverList, updateDriverList] = useState([]);
  const [vehicleId, updateVehicleId] = useState(0);
  const [driverId, updateDriverId] = useState(0);
  const [selectedDriverName, updateSelectedDriverName] = useState("");
  const [selectedVehicleName, updateSelectedVehicleName] = useState("");
  //var now = ;
  //var tomorrow = new Date();
  //tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, updateStartDate] = useState(roundBack(new Date(),12));
  const [endDate, updateEndDate] = useState(roundBack(new Date(),16));
  const [resId, updateResId] = useState("");
  const [reservationCreateResponse, updateReservationCreateResponse] = useState({});
  const [driverSearchSelectActive, updateDriverSearchSelectActive] = useState(false);
  const [vehicleSearchSelectActive, updateVehicleSearchSelectActive] = useState(false);
  const [totalCost, updateTotalCost] = useState(false);
  const [credits, updateCredits] = useState(false);
  const [depositOnFile, updateDepositOnFile] = useState(false);
  const [depositRequiredAmount, updateDepositRequiredAmount] = useState(false);
  const [startString, updateStartString] = useState(false);
  const [endString, updateEndString] = useState(false);
  const [timezone, updateTimezone] = useState(false);
  const [driverNameFirst, updateDriverNameFirst] = useState(false);
  const [driverNameLast, updateDriverNameLast] = useState(false);
  const [vehicleName, updateVehicleName] = useState(false);



  async function fetchData() {
    updateIsLoading(true)
    if(selectedDriver)
    {
      if(selectedDriver > 0)
      {
        updateDriverId(selectedDriver)
      }
    }
    if(selectedVehicle)
    {
      if(selectedVehicle > 0)
      {
        updateVehicleId(selectedVehicle)
      }
    }
    if(typeof(vehicles) !== 'undefined')
    {
      if(typeof(vehicles[0]) !== 'undefined')
      {
        updateVehicleList(vehicles);
      } else {
        await fetchVehicles();
      }
    } else {
      await fetchVehicles();
    }
    if(typeof(drivers) !== 'undefined')
    {
      if(typeof(drivers[0]) !== 'undefined')
      {
        updateDriverList(drivers);
      } else {
        await fetchDrivers();
      }
    } else {
      await fetchDrivers();
    }
    updateIsLoading(false);
  }
  useEffect(() => {
    fetchData();
  },[])


  async function fetchDrivers() {
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    var myInit = {queryStringParameters:{ 
      "back_end": process.env.REACT_APP_BACKEND,
      "jwt":jwt,
      "api_endpoint":"drivers",
      "fleet_code":fleetCode
    }};
    try {
        var vres = await API.get('adminWebTools','/ago',myInit);
        updateDriverList(vres.resp);
        return
    }
    catch (e) {
        var statusString = "Error: " + e;
        console.log(statusString);
        return
    }
  }

  async function fetchVehicles() {
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    var myInit = {queryStringParameters:{ 
      "back_end": process.env.REACT_APP_BACKEND,
      "jwt":jwt,
      "api_endpoint":"vehiclesget",
      "fleet_code":fleetCode
    }};
    try {
        var vres = await API.get('adminWebTools','/ago',myInit);
        updateVehicleList(vres.resp);
        return
    }
    catch (e) {
        var statusString = "Error: " + e;
        console.log(statusString);
        return
    }
  }


  async function reservationSearch() {
    if(driverId > 0)
    {
        var session = await Auth.currentSession()
        var accessToken = session.getAccessToken()
        var jwt = accessToken.getJwtToken()
        var myInit = {queryStringParameters:{
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"driveraddress",
            "fleet_code":fleetCode,
            "driver_id":driverId
        }};
        try{
          const res = await API.get('adminWebTools','/ago',myInit);
          var driverLat = res['latitude'];
          var driverLng = res['longitude'];
        }
        catch (e) {
            console.log(toString(e));
        }
        // Get date components
        var year = startDate.getFullYear();
        var month = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        var day = String(startDate.getDate()).padStart(2, '0');

        // Get time components
        var hours = String(startDate.getHours()).padStart(2, '0');
        var minutes = String(startDate.getMinutes()).padStart(2, '0');

        // Format the string
        var startString = `${year}-${month}-${day} ${hours}:${minutes}`;
        
        // Get date components
        year = endDate.getFullYear();
        month = String(endDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        day = String(endDate.getDate()).padStart(2, '0');

        // Get time components
        hours = String(endDate.getHours()).padStart(2, '0');
        minutes = String(endDate.getMinutes()).padStart(2, '0');

        // Format the string
        var endString = `${year}-${month}-${day} ${hours}:${minutes}`;

        updateIsLoading(true)
        updateResSearchError(false)
        updateCreateResError(false)
        updateMakePaymentError(false)


        //  BACKEND BUILDOUT REQUIRED TO ALLOW VEHICLE ID PARAM
        if(vehicleId > 0)
        {
          myInit = {queryStringParameters:{ 
              "back_end": process.env.REACT_APP_BACKEND,
              "jwt": jwt,
              "api_endpoint":"passthroughressearch",
              "fleet_code":fleetCode,
              "intent":"no",
              "driver_id":driverId,
              "car_id":vehicleId,
              "res_start":startString,
              "res_end":endString,
              "window_days":30,
              "max_dist":100,
              "driver_lat":driverLat,
              "driver_lon":driverLng
          }};
        } else {
          myInit = {queryStringParameters:{ 
              "back_end": process.env.REACT_APP_BACKEND,
              "jwt": jwt,
              "api_endpoint":"passthroughressearch",
              "fleet_code":fleetCode,
              "intent":"no",
              "driver_id":driverId,
              "car_id":vehicleId,
              "res_start":startString,
              "res_end":endString,
              "window_days":30,
              "max_dist":100,
              "driver_lat":driverLat,
              "driver_lon":driverLng
          }};
        }
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('init: ' + JSON.stringify(myInit))            //temporary
            console.log('res: ' + JSON.stringify(res))                //temporary
            if('detail' in res)
            {
              updateResSearchError(res['detail'])
            } else {
              // TO DO update the list of reservations to select from




            }
        }
        catch (e) {
            console.log('reservation search error: '+toString(e));
            var thisError = toString(e)
            updateResSearchError(thisError)
        }
        updateIsLoading(false)
    } else {
      updateResSearchError("driver, start, and end must be selected.")
    }
  }


  const cancelReservation = async () => {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
      back_end: process.env.REACT_APP_BACKEND,
      jwt: jwt,
      api_endpoint: "passthroughresmakecancel",
      res_id: resId,
      started: true,
      full_refund: true,
      cash_refund: true,
      reason: "Support create cancel"
    }};
    try {
      await API.get('adminWebTools','/ago',myInit)
    } catch (e) {
      var error = "Error computing cancel for reservation: " + JSON.stringify(e)
      console.log(error)
    }
    updateIsLoading(false);
  }

  async function createReservation() {
    if(vehicleId > 0 && driverId > 0)
    {

        // Get date components
        var year = startDate.getFullYear();
        var month = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        var day = String(startDate.getDate()).padStart(2, '0');

        // Get time components
        var hours = String(startDate.getHours()).padStart(2, '0');
        var minutes = String(startDate.getMinutes()).padStart(2, '0');

        // Format the string
        var startString = `${year}-${month}-${day} ${hours}:${minutes}`;
        
        // Get date components
        year = endDate.getFullYear();
        month = String(endDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        day = String(endDate.getDate()).padStart(2, '0');

        // Get time components
        hours = String(endDate.getHours()).padStart(2, '0');
        minutes = String(endDate.getMinutes()).padStart(2, '0');

        // Format the string
        var endString = `${year}-${month}-${day} ${hours}:${minutes}`;

        updateIsLoading(true)
        updateCreateResError(false)
        updateMakePaymentError(false)
        updateResSearchError(false)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        var jwt = await accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"passthroughrescreate",
            "fleet_code":fleetCode,
            "intent":"no",
            "driver_id":driverId,
            "car_id":vehicleId,
            "res_start":startString,
            "res_end":endString
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('init: ' + JSON.stringify(myInit))            //temporary
            //console.log('res: ' + JSON.stringify(res))                //temporary
            updateReservationCreateResponse(res);
            if('detail' in res)
            {
              updateCreateResError(res['detail'])
            } else {
              if('res_id' in res)
              {
                updateResId(res['res_id']);
                updateTotalCost(res['prices']['price_total']);
                updateCredits(res['prices']['credits']);
                updateDepositOnFile(res['prices']['deposit']);
                updateDepositRequiredAmount(res['prices']['deposit_total']);
                updateStartString(res['reservation']['start_string']);
                updateEndString(res['reservation']['end_string']);
                updateTimezone(res['reservation']['timezone']);
                updateDriverNameFirst(res['driver']['name_first']);
                updateDriverNameLast(res['driver']['name_last']);
                updateVehicleName(res['car']['name']);

                updateShowConfirmInfo(true);
              } else {
                updateCreateResError(JSON.stringify(res))
              }
            }
        }
        catch (e) {
            console.log('create reservation error: '+toString(e));
            var thisError = toString(e)
            updateCreateResError(thisError)
        }
        updateIsLoading(false)
    } else {
      updateCreateResError("Vehicle, driver, start, and end must be selected.")
    }
  }

  async function makePayment() {
    if(resId!=="")
    {
        updateIsLoading(true)
        updateCreateResError(false)
        updateMakePaymentError(false)
        updateResSearchError(false)
        let session = await Auth.currentSession()      
        let accessToken = await session.getAccessToken()
        var jwt = await accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"passthroughresmakepayment",
            "res_id":resId,
            "payment_type":"price",
            "promo_code":"",
            "ago_points":"0"
        }};
        //console.log('init: ' + JSON.stringify(myInit)) //temporary
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('makepayment response: ' + JSON.stringify(res)) //temporary
            if('detail' in res)
            {
              updateMakePaymentError(res['detail'])
            } else {
              if('status' in res)
              {
                if(res['status']==='success')
                {
                  updateShowConfirmInfo(false);
                  refreshParent();
                } else {
                  updateMakePaymentError(res['status'])
                }
              } else {
                updateShowConfirmInfo(false);
                refreshParent();
              }
            }
        }
        catch (e) {
            console.log('make payment error: '+toString(e));
            var thisError = toString(e)
            updateMakePaymentError(thisError)
        }
        updateIsLoading(false)
    }
  }

  async function handleupdateVehicleSearchSelectActive(val) {
    updateVehicleSearchSelectActive(val);
    if(val)
    {
      updateDriverSearchSelectActive(false);
    }
  }

  async function handleupdateDriverSearchSelectActive(val) {
    updateDriverSearchSelectActive(val);
    if(val)
    {
      updateVehicleSearchSelectActive(false);
    }
  }


  async function closeWindow() {
    updateCreateResError(false)
    updateMakePaymentError(false)
    updateResSearchError(false)
    updateDisplayWindow(false)
  }

  async function cancelMadeReservation() {
    cancelReservation()
    updateShowConfirmInfo(false)
  }

  return (<View style={{flex:1}}>
    {
      is_loading ? <Loadingmini /> : 
      displayWindow ? 
        <View>
          <Heading level={4}>New Reservation</Heading>
          <Text>Complete the form below to create a reservation on behalf of a driver.</Text>
          <br/>
          {
            showConfirmInfo === false ?
            <View>
              <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                <Text>Driver:</Text>
                {vehicleSearchSelectActive ? <Text>Select a vehicle first</Text> : <Searchselect searchType="driver" objects={driverList} selectObject={updateDriverId} selectedObject={driverId} updateSelectedObjectName={updateSelectedDriverName} selectedObjectName={selectedDriverName} updateSearchSelectActive={handleupdateDriverSearchSelectActive}/> }
              </Flex>
              <br/>
              <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                <Text>Vehicle:</Text>
                {driverSearchSelectActive ? <Text>Select a driver first</Text> : <Searchselect searchType="vehicle" objects={vehicleList} selectObject={updateVehicleId} selectedObject={vehicleId} updateSelectedObjectName={updateSelectedVehicleName} selectedObjectName={selectedVehicleName} updateSearchSelectActive={handleupdateVehicleSearchSelectActive}/> }
              </Flex>
              <br/>
              <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                <Text>Start:</Text>
                <DatePicker
                      name="Start Date"
                      selected={startDate}
                      onChange={(date) => updateStartDate(date)}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeIntervals={60}
                    />
              </Flex>
              <br/>
              <br/>
              <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                <Text>End:</Text>
                <DatePicker
                      name="End Date"
                      selected={endDate}
                      onChange={(date) => updateEndDate(date)}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeIntervals={60}
                    />
              </Flex>
              <br />
              <br/>
              {
                createResError === false ? 
                  undefined 
                :
                  <View>
                    <Heading level={4}>Error</Heading>
                    <Text>There was an issue creating your reservation.</Text> 
                    <Text>Please double-check the vehicle, driver, start, and end, and try again.</Text>
                    <Text><b><i>{createResError}</i></b></Text> 
                  </View>
              }
              {
                createResError ? 
                <Button onClick={() => createReservation()}>
                    TRY AGAIN
                </Button>
                :
                <Button onClick={() => createReservation()}>
                    CREATE RESERVATION
                </Button>
              }
              <br />
              <br />
              <Button onClick={() => closeWindow()}>
                  CANCEL
              </Button>
            </View>
            :
            makePaymentError ? 
              <View>
                <Heading level={4}>Error Paying for Reservation</Heading>
                <Text>{JSON.stringify(makePaymentError)}</Text>
                <br />
                <br />
                <Button onClick={() => updateMakePaymentError(false)}>
                    TRY AGAIN
                </Button>
              </View>
            :
              <View>
                <Heading level={4}>Complete Payment</Heading>
                <Text><i>Deposit Required is in addition to Total Cost, taxes already applied.</i></Text>
                <br/>
                <Text>New Reservation ID:  {resId}</Text>
                <Text>Driver:  {driverNameFirst} {driverNameLast}</Text>
                <Text>Vehicle:  {vehicleName}</Text>
                <Text>Timezone:  {timezone}</Text>
                <Text>Start:  {startString}</Text>
                <Text>End:  {endString}</Text>
                <Text>Credits:  ${credits}</Text>
                <Text>Deposit on file:  ${depositOnFile}</Text>
                <Text><b>Total Cost:  ${totalCost}</b></Text>
                <Text><b>Deposit Required:  ${depositRequiredAmount}</b></Text>


                <br />
                {
                  createResError ? undefined :
                    <Button onClick={() => makePayment()}>
                        PAY FOR RESERVATION
                    </Button>
                }
                <br />
                <br />
                <Button onClick={() => cancelMadeReservation()}>
                    CANCEL
                </Button>
              </View>
          }
          <br/>
          <Divider />
        </View>
      :
        <Flex flexDirection='row' justifyContent='flex-end' alignItems='flex-end' alignContent='flex-end' gap='1em'>
          <Button onClick={() => updateDisplayWindow(true)}>
            CREATE RESERVATION
          </Button>
        </Flex>
    }
  </View>);
};

export default Reservationcreate;