import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import { Heading, Flex, Text, Button, View } from '@aws-amplify/ui-react';

export default function Terms({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [count, setCount] = useState(0);

  async function fetchTermsAgreed() {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt, 
            "api_endpoint":"terms"
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateRes(res)
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
    }
    updateIsLoading(false)
  }

  useEffect(() => {
    fetchTermsAgreed();
  },[count])

  async function setTermsAgree()
  {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "confirm_terms_agree":true, 
            "api_endpoint":"terms"
        }};
        const res = await API.get('adminWebTools','/ago',myInit);
        console.log('confirm terms response:');
        console.log(JSON.stringify(res));
        fetchTermsAgreed()
        if(typeof isHomepage==='boolean')
        {
          if(isHomepage)
          {
            window.location.reload();
          }
        }
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
      updateIsLoading(false)
    }
  }
  const renderTermsAgreed = () => {
    if(is_loading){
      return (
        <p>Loading...</p>
      )
    }
    if(!res['terms_agreed_string']){
      return (
        <p>Please review and confirm agreement to the terms and conditions and collection of website cookies.</p>
      )
    }
    return (
     <p>{res['terms_agreed_string']}</p>
    )
  }

  return (
    <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction='column'      //direction={{ base: 'column', large: 'row' }}
        //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
        //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
        //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
        wrap="wrap" //nowrap
        gap="1rem"
    >
      <View style={{padding:"1rem"}}>
        {
          is_loading ? <Loading /> : 
          res['status']!=='success' ? <Text>{res['status']}</Text> : 
          <Flex 
              direction='column'
          >
              <Heading level={1}>Terms and Conditions</Heading>
              <Text style={{width:'100%'}}><b>This site uses cookies to gather data.</b> Continued use of this software requires that you agree to the use of cookies and to the terms and conditions below.</Text>
              <br/>
              {renderTermsAgreed()}
              <br/>
              <View style={{padding:15, display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'#9E9E9E'}}>
                <iframe src="https://agorisk.com/terms/" width="95%" height="400"></iframe>
              </View>
              <br/><br/>
              {res['terms_agreed'] ? undefined : <Flex direction="column"><Button onClick={() => setTermsAgree()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5, width:'100%'}}>I Agree to Terms and <br/>use of Cookies</Button></Flex>}
          </Flex>
        }
      </View>
    </Flex>
  );
}