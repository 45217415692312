import React, { Component } from 'react'
import {ActivityIndicator, StyleSheet, View} from 'react-native';
//import MyComponent from 'react-fullpage-custom-loader'

class Loading extends Component {
  render () {
    return (
      <View style={styles.supercontainer}>
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" color="#092F50" />
        </View>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  supercontainer: {
    width:'100%', 
    height:'100%', 
    position:'fixed', 
    top:0, 
    left:0, 
    right:0, 
    bottom:0, 
    backgroundColor:'white', 
    opacity:'0.92', 
    justifyContent:'center', 
    alignItems:'center', 
    zIndex:100
  },
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});
export default Loading;