import React, { useState } from 'react'
import { View, Text, Heading, Button, SelectField} from '@aws-amplify/ui-react';

export const Fleetselector = ({ fleet_codes, selected_fleet, updateParentSelectedFleet }) => {
  const [displayFleetSelectWindow, updateDisplayFleetSelectWindow] = useState(false);
  const [selectedFleet, updateSelectedFleet] = useState(selected_fleet);
  const [optionsArray, updateOptionsArray] = useState(fleet_codes);
  

  const selectFleet = (newFleet) => {
    updateSelectedFleet(newFleet);
    updateParentSelectedFleet(newFleet);
  }

  const renderOptions = () => {
    if(!optionsArray){
      return;
    }
    if(!Array.isArray(optionsArray)){
      return;
    }
    if(!optionsArray[0]){
      return;
    }
    return optionsArray.map(function(object, index){
      return(<option value={object}>{object}</option>)
    })
  }
  
  return (<View>
    {!fleet_codes || !selected_fleet ? 
        <Text>No Available Fleets, please refresh results.</Text>
      :
      displayFleetSelectWindow ? 
        <View>
          <Heading level={4}>Select Fleet</Heading>
          <Text>Contact support if your fleet is not listed below.</Text>
          <SelectField
              descriptiveText="Select your fleet..."
              size="small"
              value={selectedFleet}
              onChange={(e) => selectFleet(e.target.value)}
          >
              {renderOptions()}
          </SelectField>
          <br/>
          <Button onClick={() => updateDisplayFleetSelectWindow(false)}>
              CLOSE
          </Button>
        </View>
      :
        <Button onClick={() => updateDisplayFleetSelectWindow(true)}>
            {selected_fleet}
        </Button>
    }
  </View>);
};

export default Fleetselector;