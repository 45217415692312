
import { useParams } from "react-router-dom";
import React, { useState } from 'react'
import { API, Auth } from 'aws-amplify';
import { Button,Text,View,TextField, Heading } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import Loading from '../components/loading';

import TableContainer from '../components/tablecontainer';

export default function Dispute() {
  let params = useParams();

  const vehicleCommandColumns = [
    {
        Header: 'Created',
        accessor: 'created',
    },
    {
        Header: 'Car',
        accessor: 'car_name',
    },
    {
        Header: 'User',
        accessor: 'user_name',
    },
    {
        Header: 'Command',
        accessor: 'command',
    },
    {
        Header: 'Status',
        accessor: 'command_status',
    },
    {
        Header: 'Duration',
        accessor:'duration'
    },
    {
        Header: 'Error Info',
        accessor:'error_info'
    },
    {
        Header: 'Response',
        accessor:'response'
    },
  ];
  const chatColumns = [
    {
        Header: 'Date Sent',
        accessor: 'sql_timestamp',
    },
    {
        Header: 'Sender',
        accessor: 'sender_name',
    },
    {
        Header: 'Recipient',
        accessor:'recipient_name'
    },
    {
        Header: 'Message',
        accessor: 'message',
    },
    {
        Header: 'Read Timestamp',
        accessor: 'read_timestamp',
    },
    {
        Header: 'sender_id',
        accessor: 'sender_id',
    },
    {
        Header: 'recipient_id',
        accessor:'recipient_id'
    }
  ];
  const resLedgerColumns = [
    {
        Header: 'res_id',
        accessor: 'res_id',
    },
    {
        Header: 'version',
        accessor: 'version',
    },
    {
        Header: 'start_string',
        accessor: 'start_string',
    },
    {
        Header: 'end_string',
        accessor: 'end_string',
    },
    {
        Header: 'status',
        accessor: 'status',
    },
    {
        Header: 'driver_name',
        accessor:'driver_name'
    },
    {
        Header: 'car_name',
        accessor:'car_name'
    },
    {
        Header: 'action',
        accessor:'action'
    },
    {
        Header: 'reason',
        accessor:'reason'
    },
    {
        Header: 'gross_cost',
        accessor:'gross_cost'
    },
    {
        Header: 'deposit',
        accessor:'deposit'
    },
    {
        Header: 'created',
        accessor:'created'
    },
    {
        Header: 'modified',
        accessor:'modified'
    },
    {
        Header: 'close_cancel_string',
        accessor:'close_cancel_string'
    },
    {
        Header: 'close_cancel',
        accessor:'close_cancel'
    },
    {
        Header: 'driver_id',
        accessor:'driver_id'
    },
    {
        Header: 'car_id',
        accessor:'car_id'
    },
    {
        Header: 'start',
        accessor:'start'
    },
    {
        Header: 'end',
        accessor:'end'
    }
  ];
  const creditsColumns = [
    {
        Header: 'created',
        accessor: 'created',
    },
    {
        Header: 'amount',
        accessor: 'amount',
    },
    {
        Header: 'shift',
        accessor: 'shift',
    },
    {
        Header: 'entry_type',
        accessor: 'entry_type',
    },
    {
        Header: 'notes',
        accessor: 'notes',
    }
  ];
  const refundsColumns = [
    {
        Header: 'created',
        accessor: 'created',
    },
    {
        Header: 'res_id',
        accessor: 'res_id',
    },
    {
        Header: 'version',
        accessor: 'version',
    },
    {
        Header: 'fee_source',
        accessor: 'fee_source',
    },
    {
        Header: 'fee_category',
        accessor: 'fee_category',
    },
    {
        Header: 'amount',
        accessor: 'amount',
    },
    {
        Header: 'money_type',
        accessor: 'money_type',
    },
    {
        Header: 'stripe_invoice_id',
        accessor: 'stripe_invoice_id',
    }
  ];
  const [res, updateRes] = useState({status:"Please enter a reservation ID and submit",rows:[]})
  const [selectedResId, updateSelectedResId] = useState(params.resId)
  const [is_loading, updateIsLoading] = React.useState(false);
  const sleep = ms => new Promise(r => setTimeout(r, ms));

  async function fetchResData() {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = await session.getAccessToken()
    let jwt = await accessToken.getJwtToken()
    const res = {status:"loading...",rows:[]};
    updateRes(res);
    var disputePackageKey = selectedResId + "-" + Date.now()
    const myInit = {queryStringParameters:{ 
        "api_endpoint":"dispute",
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:jwt, 
        id: selectedResId,
        dispute_package_key: disputePackageKey
    }};
    try {
        //const res = await API.get('adminWebTools','/ago',myInit);
        API.get('adminWebTools','/ago',myInit);
        //updateIsLoading(false)
        loopGetUpdate(disputePackageKey);
        return
    }
    catch (e) {
        var statusString = "Reservation #" + selectedResId + " not found because " + e;
        const res = {status:statusString,rows:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }

  async function loopGetUpdate(disputePackageKey) {
    console.log('getting update...')
    let session = await Auth.currentSession()      
    let accessToken = await session.getAccessToken()
    let jwt = await accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
        api_endpoint:"dispute",
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:jwt, 
        id: selectedResId,
        dispute_package_key: disputePackageKey
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit);
        updateRes(res);
        if(res['status']=='generating')
        {
            console.log('sleeping for 2 seconds...')
            sleep(2000);
            console.log('starting loop update...')
            loopGetUpdate(disputePackageKey);
            return
        } else {
            console.log('status not generating, ending loop')
            updateIsLoading(false)
            return
        }
    }
    catch (e) {
        var statusString = "Reservation #" + selectedResId + " not found because " + e;
        const res = {status:statusString,rows:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }
  var DevNote = <p>Good test res in dev: <Button onClick={() => updateSelectedResId("6420-000000000028")}>6420-000000000028</Button></p>
  var ProdNote = <p></p>
  var ResLedgerGraph = <View><TableContainer columns={resLedgerColumns} data = {res['res_ledger']} defaultSort = 'version'/></View>
  var ChatGraph = <View><TableContainer columns={chatColumns} data = {res['chat']} defaultSort = 'sql_timestamp'/></View>
  var VehicleCommandsGraph = <View><TableContainer columns={vehicleCommandColumns} data = {res['vehicle_commands']} defaultSort = 'created'/></View>
  var CreditsForResGraph = <View><TableContainer columns={creditsColumns} data = {res['credits_for_res']} defaultSort = 'created'/></View>
  var CreditsGraph = <View><TableContainer columns={creditsColumns} data = {res['credits']} defaultSort = 'created'/></View>
  var RefundsGraph = <View><TableContainer columns={refundsColumns} data = {res['refunds']} defaultSort = 'created'/></View>
  var RefundsForResGraph = <View><TableContainer columns={refundsColumns} data = {res['refunds_for_res']} defaultSort = 'created'/></View>
  var NoData = "No Data"
  var Terms = <p style={{ textAlign: "justify" }}>{res['terms']}</p>
  var OdoDataStart = <View>
    <p>Odometer before Reservation Start:  {res['odo_start_value']}</p>
    <p>Date of Odometer Reading:  {res['odo_start_time_string']}</p>
    <p>Reservation Start:  {res['res_start_string']}</p>
  </View>
  var OdoDataEnd = <View>
    <p>Odometer after Reservation End:  {res['odo_end_value']}</p>
    <p>Date of Odometer Reading:  {res['odo_end_time_string']}</p>
    <p>Reservation End:  {res['res_end_string']}</p>
  </View>
  var OdoChangeData = <p>{res['odo_change_note']}</p>
  var DriverData = <View>
    <p>driver_join_date:  {res['driver_join_date']}</p>
    <p>driver_name_first:  {res['driver_name_first']}</p>
    <p>driver_name_middle:  {res['driver_name_middle']}</p>
    <p>driver_name_last:  {res['driver_name_last']}</p>
    <p>driver_email:  {res['driver_email']}</p>
    <p>driver_phone:  {res['driver_phone']}</p>
    <p>driver_license:  {res['driver_license']}</p>
    <p>driver_license_state:  {res['driver_license_state']}</p>
    <p>driver_device_type:  {res['driver_device_type']}</p>
  </View>
  var CarData = <View>
    <p>car_name:  {res['car_name']}</p>
    <p>car_make:  {res['car_make']}</p>
    <p>car_model:  {res['car_model']}</p>
    <p>car_year:  {res['car_year']}</p>
    <p>car_color:  {res['car_color']}</p>
    <p>car_vin:  {res['car_vin']}</p>
    <p>car_license:  {res['car_license']}</p>
    <p>car_state:  {res['car_state']}</p>
  </View>
  var ResData = <View>
    <p>res_id:  {res['res_id']}</p>
    <p>res_created:  {res['res_created']}</p>
    <p>res_start_string:  {res['res_start_string']}</p>
    <p>res_end_string:  {res['res_end_string']}</p>
  </View>
  var DriverContract = <View>
    <p style={{ textAlign: "justify" }}>On {res['driver_join_date']}, the rentor {res['driver_name_first']} {res['driver_name_last']} (using the email {res['driver_email']}, phone number {res['driver_phone']}, and driver's license {res['driver_license']} a(n) {res['driver_license_state']} driver's license) joined the Ago app to rent a vehicle (license plate {res['car_license']}) from Ago Mobility LLC. He or she entered a rental agreement pursuant to Ago's terms and conditions (described herein) on {res['res_created']}, pursuant to a rental agreement covering the times of {res['res_start_string']} until {res['res_end_string']}. This rental agreement is tracked along with his or her reservation of the vehicle under the internal file number of #{res['res_id']}. He or she agreed to the following terms of conditions, electronically entering a rental agreement with Ago Mobility LLC:</p>
    {Terms}
  </View>

  var PageData = <View>
    <Heading level={2}>Response Package for Download</Heading>
    <p><a href={res['document_1']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: CHAT</a></p>
    <p><a href={res['document_2']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: CONTRACT</a></p>
    <p><a href={res['document_3']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: TERMS</a></p>
    <p><a href={res['document_4']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: RESERVATION</a></p>
    <p><a href={res['document_5']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: REFUND POLICY</a></p>
    <p><a href={res['document_6']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: REFUNDS</a></p>
    <p><a href={res['document_7']} rel='noreferrer' target='_blank'>DOWNLOAD  DOCUMENT: TELEMATICS</a></p>

    <Heading level={2}>Payment Approval</Heading>
    <p>This charge was for an Ago car rental secured by {res['driver_name_first']} {res['driver_name_last']} on {res['res_created']} which provided said user access to the following vehicle from {res['res_start_string']} to {res['res_end_string']} pursuant to a rental agreement executed by said user on {res['res_created']}:  {res['car_vin']}, {res['car_license']}, {res['car_year']} {res['car_make']} {res['car_model']}, for reservation #{res['res_id']}.</p>

    <Heading level={2}>Details used to Generate Documents</Heading>
    <br/>
    <Heading level={4}>Reservation Details</Heading>
    {res['res_ledger'] ? ResLedgerGraph : NoData}

    <br/><br/>
    <Heading level={4}>Communication</Heading>
    {res['chat'] ? ChatGraph : NoData}
    
    <br/><br/>
    <Heading level={4}>Car Odometer Before Reservation</Heading>
    {res['odo_start_value'] > 0 ? OdoDataStart : NoData}
    
    <br/><br/>
    <Heading level={4}>Car Odometer After Reservation</Heading>
    {res['odo_end_value'] > 0 ? OdoDataEnd : NoData}

    <br/><br/>
    <Heading level={4}>Car Odometer Change Notes</Heading>
    <p>This is used to double-check in the event there is no car odometer reading after reservation.</p>
    {res['odo_change_note'] ? OdoChangeData : NoData}

    <br/><br/>
    <Heading level={4}>Commands Sent to Vehicle</Heading>
    {res['vehicle_commands'] ? VehicleCommandsGraph : NoData}

    <br/><br/>
    <Heading level={4}>Cash Refunds for Reservation #{selectedResId}</Heading>
    {res['refunds_for_res'] ? RefundsForResGraph : NoData}

    <br/><br/>
    <Heading level={4}>User Cash Refunds History</Heading>
    {res['refunds'] ? RefundsGraph : NoData}

    <br/><br/>
    <Heading level={4}>Credits for Reservation #{selectedResId}</Heading>
    {res['credits_for_res'] ? CreditsForResGraph : NoData}
    
    <br/><br/>
    <Heading level={4}>User Credits History</Heading>
    {res['credits'] ? CreditsGraph : NoData}

    <br/><br/>
    <Heading level={4}>Driver Data</Heading>
    {res["driver_name_first"] ? DriverData : NoData}

    <br/><br/>
    <Heading level={4}>Car Data</Heading>
    {res["car_name"] ? CarData : NoData}

    <br/><br/>
    <Heading level={4}>Res Data</Heading>
    {res["res_created"] ? ResData : NoData}
    
    <br/><br/>
    <Heading level={4}>Driver Contract Rental Agreement</Heading>
    {res['terms'] ? DriverContract : NoData}
    <br/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_terms_1.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_terms_2.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_checkr_1.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_checkr_2.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_checkr_3.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_esign_1.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_rules.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_reservation_1.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_reservation_2.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_reservation_3.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_reservation_4.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_reservation_5.png' alt='error' width='400'/><br/>
    <img src='https://ago-misc-public-photos.s3.us-east-2.amazonaws.com/screenshot_reservation_6.png' alt='error' width='400'/><br/>
    
  </View>

  var PageNoData = <View><p>{JSON.stringify(res)}</p></View>

  return (
    <main style={{ padding: "1rem" }}>
      <Heading level={2}>Dispute Reservation #{selectedResId}</Heading>
      <p>Specify a reservation ID to generate the Dispute Response package</p>
      {process.env.REACT_APP_BACKEND==="dev" ? DevNote : ProdNote}
      <TextField
        placeholder='reservation ID'
        onInput={(e) => updateSelectedResId(e.target.value)}
      ></TextField><br/>
      <Button onClick={() => fetchResData()}>
                  Submit
      </Button>
      <br/><br/>
      <Text>
            {res.status !== "success" ? PageNoData:PageData}
      </Text>
      <br/><br/><br/><br/>
        {is_loading ? <Loading /> : undefined}
    </main>
  );
}