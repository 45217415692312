import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Button,View} from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TableContainer from '../components/tablecontainer';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { FaQuestionCircle, FaAngleDoubleRight, FaAngleDoubleDown } from 'react-icons/fa';
import { PieChart } from 'react-minimal-pie-chart';
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';
import { NavLink} from "react-router-dom";

export default function Recenttripscars() {
  let params = useParams();
  const [displayJson, updateDisplayJson] = React.useState(false);
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"Loading...",rows:[]})
  const [count, setCount] = useState(0);
  const [selectedObjRangeStart, updateSelectedObjRangeStart] = useState("")
  const [selectedObjRangeEnd, updateSelectedObjRangeEnd] = useState("")
  const [selectedObjRangeStartFull, updateSelectedObjRangeStartFull] = useState("")
  const [selectedObjRangeEndFull, updateSelectedObjRangeEndFull] = useState("")



  const tableCols = [
  {
    Header: 'Car Id',
    accessor: 'car_id',
    Cell: (tableInfo) => {
            let val = tableInfo.data[tableInfo.row.index].car_id
            let link = '/vehicle/' + String(val) 
            return( <NavLink to={link} index = {val}>{val}</NavLink>)
        },
    filter: 'text'
  },
  {
      Header: 'Name',
      accessor: 'name',
  },
  {
      Header: 'VIN',
      accessor: 'vin',
  },
  {
      Header: 'License Plate',
      accessor: 'license',
  },
  {
      Header: 'Total Insurance Costs',
      accessor: 'total_insurance_costs',
  },
  {
      Header: 'Total Miles',
      accessor: 'total_miles',
  },
  {
      Header: 'Driving Hours',
      accessor: 'total_hours',
  },
  {
      Header: 'Gig Liab Uninsured Mls',
      accessor: 'gig_liab_uninsured_mls',
  },
  {
      Header: 'Gig Phys Uninsured Mls',
      accessor: 'gig_phys_dam_uninsured_mls',
  },
  {
      Header: 'Safety',
      accessor: 'safety_bucket',
  },
  {
      Header: 'Car Sent Telemetry',
      accessor: 'car_sent_telemetry',
  }
]

  function toggleDisplayJson()
  {
    if(displayJson)
    {
      updateDisplayJson(false);
    } else {
      updateDisplayJson(true);
    }
  }
  async function fetchData() {
    updateIsLoading(true)
    /*
    var i = 0;
    var totalCost = 0;
    var aveCost = "loading...";
    const session =  await Auth.currentSession();
    var userEmail = session.idToken.payload.email;
    */
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ "api_endpoint":"recenttripsvehicles", back_end: process.env.REACT_APP_BACKEND, jwt:jwt, range_start: selectedObjRangeStart, range_end: selectedObjRangeEnd}};
    try {
        const res = await API.get('adminWebTools','/ago',myInit);
        updateRes(res);
        console.log(res);
        updateIsLoading(false)
        return
    }
    catch (e) {
        var statusString = "Error: " + e;
        const res = {status:statusString,rows:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }
  useEffect(() => {
    fetchData();
  },[count])


  //var PageNoData = <View><p>{JSON.stringify(res)}</p></View>
  //var MustRefresh = <View><p>Please get updates to display table</p></View>

  
  function handleRangeStartSelected(date)
  {
      updateSelectedObjRangeStartFull(date);
      const date2 = date.toISOString().substring(0, 10);
      updateSelectedObjRangeStart(date2);
  }
  function handleRangeEndSelected(date)
  {
      updateSelectedObjRangeEndFull(date);
      const date2 = date.toISOString().substring(0, 10);
      updateSelectedObjRangeEnd(date2);
  }

  function formatDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var day = days[a.getDay()];
    if(hour > 12)
    {
      var thour = hour - 12
      var pmam = ' PM';
    } else {
      if(hour < 12)
      {
        if(hour < 1)
        {
          var thour = '12';
          var pmam = ' AM';
        } else {
          var thour = hour;
          var pmam = ' AM';
        }
      } else {
        var thour = '12';
        var pmam = ' PM';
      }
    }
    var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
    return time;
  }
  /*
  var RefreshButton = 
    <button onClick={() => setCount(count + 1)}>
                PRESS TO GET LATEST UPDATES
    </button>
  */

  var DateRangeParams = <View>
    <p>Enter earliest date</p>
    <DatePicker
        name="Range Start"
        selected={selectedObjRangeStartFull}
        onChange={(date) => handleRangeStartSelected(date)}
        showTimeSelect
        dateFormat="yyyy-MM-d"
        timeIntervals={60}
        />
    <br/><br/>
    <p>Enter latest date</p>
    <DatePicker
        name="Range End"
        selected={selectedObjRangeEndFull}
        onChange={(date) => handleRangeEndSelected(date)}
        showTimeSelect
        dateFormat="yyyy-MM-d"
        timeIntervals={60}
        />
    <br/>
  </View>
  var NoData = <p>No data available</p>


  
  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Vehicles</Heading>
        {res['status']!=='success' ? res['status'] : <div>
          <p>We've analyzed the past 7 days of data for every vehicle in your fleet. Here's a breakdown of their insurance costs and driving history:</p>

          {res['fleet_data_exists'] > 0 ? <TableContainer columns={tableCols} data = {res['rows']} defaultSort = 'total_insurance_costs' /> : NoData}
        </div>}
        <br/>
        {is_loading ? <Loading /> : undefined}
        {/*displayJson===true ? <div><p><Button onClick={() => toggleDisplayJson()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>Hide JSON</Button></p><p><a href='http://jsonprettyprint.net/' target='_blank'>PRETTY JSON</a></p><p style={{maxWidth:'500px', backgroundColor:'#eaebdf',overflow:'clip'}}>{JSON.stringify(res)}</p></div> : <p><Button onClick={() => toggleDisplayJson()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>View JSON</Button></p>*/}

    </main>
  );
}