import React from 'react'
import { Auth, API } from 'aws-amplify';
import { Text, View } from '@aws-amplify/ui-react';

class MyArgyle extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = { status: ""}
        this.openArgyle = this.openArgyle.bind(this)
        this.argyleObj = null
        this.someActionTaken = false
      }

    componentDidMount() {

        const script = document.createElement("script");
        script.src = "https://plugin.argyle.com/argyle.web.v3.js";
        script.async = true;
        
        document.body.appendChild(script);
       //const node = this.myRef.current;
       //node.appendChild(script)
        //const node = this.myRef.current;
        //node.appendChild(b)
        //document.body.appendChild(b);
    }

    async openArgyle() {
        var res = {}
        var argyle_token = ''
        let session = await Auth.currentSession()        
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()

        this.setState({ status: "retrieving credentials..." });
        const myInit = {
            queryStringParameters:{ 
                back_end: process.env.REACT_APP_BACKEND,
                jwt: jwt,
                api_endpoint: "user",
                method: "get_argyle"
            }};
        try {
            res = await API.get('adminWebTools','/ago',myInit)
        }
        catch (e) {
            this.setState({ status: "Error retrieving user or User not permissioned - email us at support@joinago.com" });
            return
        }

        if (res['status'] !== "success") {
            this.setState({ status: res['status'].concat(" - email us at support@joinago.com") });
            return
        }
        
        if ('argyle_token' in res && res['argyle_token'] !== null && res['argyle_token'].length > 0) {
            argyle_token = res['argyle_token']
        }

        this.argyleObj = window.Argyle.create({
            linkKey: '9934ceae-9147-4a66-b15c-0e4f337c77fe',
            apiHost: 'https://api.argyle.io',
            customizationId: '4MDLDXSF',
            userToken: argyle_token,
            linkItems: ['lyft', 'uber','doordash','grubhub','instacart','amazon_flex'],
            onAccountCreated: ({ accountId, userId, linkItemId }) => {
                console.log('Account created: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
                this.setState({ someActionTaken: true });
            },
            onAccountConnected:  ({ accountId, userId, linkItemId }) => {
                console.log('Account connected: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
                this.setState({ someActionTaken: true });
            },
            onAccountUpdated: ({ accountId, userId, linkItemId }) => {
                console.log('Account updated: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
                this.setState({ someActionTaken: true });
            },
            onAccountRemoved: ({ accountId, userId, linkItemId }) => {
                console.log('Account removed: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
                this.setState({ someActionTaken: true });
            },
            onUserCreated: async ({ userId, userToken }) => {
                console.log('User created: ', userId, 'User token:', userToken)
                this.setState({ someActionTaken: true });
                let session = await Auth.currentSession()        
                let accessToken = session.getAccessToken()
                let jwt = accessToken.getJwtToken()
                
                const myInit = {
                    queryStringParameters:{ back_end: process.env.REACT_APP_BACKEND, api_endpoint:"user", method: 'set_argyle', jwt: jwt, argyle_user_id : userId   }};
                try {
                    res = await API.get('adminWebTools','/ago',myInit);
                }
                catch (e) {
                    this.setState({ status: "Error saving argyle credential - email us at support@joinago.com " + e });
                    return
                }
        
                if (res['status'] !== "success") {
                    this.setState({ status: res['status'].concat(" - email us at support@joinago.com") });
                    return
                } else {
                    console.log('successfully saved user ' + userId);
                }

            },
            onClose: () => {
                if(this.state.someActionTaken){
                    window.location.reload();
                } else {
                    alert("Closing integration window at your request, no action yet taken...")
                }
                console.log('Link closed')
            },
            onTokenExpired: updateToken => {
                console.log('Token expired')

                //for now just close and user must reopen
                this.argyleObj.close()

                // generate your new token here (more info: /developer-tools/api-reference#user-tokens)
                // updateToken(newToken)  
            }
        })

        this.argyleObj.open()   

        this.setState({ status: "" });
    }
    
    render() {
    return(
        <View>
            <div ref={this.myRef} > 
            </div>
            <button onClick={this.openArgyle} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}}>
                  Open Account Integration
            </button>
            <Text>
              {this.state.status.length > 0 ? `${this.state.status}`:``}
            </Text>
        </View>
    )
    }
}

export default MyArgyle