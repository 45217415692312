
import { useParams } from "react-router-dom";
import { NavLink} from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';
import { API, Auth } from 'aws-amplify';
import Loadingmini from '../components/loadingmini';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import User from './user';
import Fleetselector from "../components/fleetselector";

export default function Users() {
    let params = useParams();
    const [users, updateUsers] = useState({status:"Loading... if this message stays - email us at info@joinago.com",resp:[]})
    const [is_loading, updateIsLoading] = React.useState(false);
    const [displaySideDock, updateDisplaySideDock] = React.useState(false);
    const [selectedObjectId, updateSelectedObjectId] = React.useState(false);
    const [tableColumnsView, updateTableColumnsView] = React.useState('default');
    const [fleet_codes, updateFleetCodes] = React.useState(null);
    const [selected_fleet, updateSelectedFleet] = React.useState(false);
    const [displayAdministrativeFunctions, updateDisplayAdministrativeFunctions] = React.useState(false);

    const columns = [
        {
            headerName: 'ID',
            width: 85,
            field: 'id',
            filter: true,
            pinned: 'left',
            cellRenderer: params => {
                let val = params.value
                if(displaySideDock===false)
                {
                    var link = '/user/' + String(val) 
                    return( <NavLink to={link} index = {val}>{val}</NavLink>)
                } else {
                    return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => selectObject(val)}>{val}</Button></Text>)
                }
            }
        },
        {
            headerName: 'First Name',
            field: 'name_first',
            filter: true
        },
        {
            headerName: 'Last Name',
            field: 'name_last',
            filter: true
        },
        {
            headerName: 'Email',
            field: 'email',
            filter: true
        },
        {
            headerName: 'Phone',
            field: 'phone',
            filter: true
        },
        {
            headerName: 'License#',
            field: 'license',
            filter: true
        },
        {
            headerName: 'License State',
            field: 'license_state',
            width: 85,
            filter: true
        },
        {
            headerName: 'Insurance $',
            field: 'total_insurance_costs',
            filter: true
        },
        {
            headerName: 'Miles Driven',
            field: 'total_miles',
            filter: true
        },
        {
            headerName: 'Hours Driven',
            field: 'total_hours',
            filter: true
        },
        {
            headerName: 'Gig Liability Insured Miles',
            field: 'gig_liab_insured_mls',
            filter: true
        },
        {
            headerName: 'Gig Physical Damage Insured Miles',
            field: 'gig_phys_dam_insured_mls',
            filter: true
        },
        {
            headerName: 'Safety Bucket',
            field: 'safety_bucket',
            filter: true
        },
        {
            headerName: 'Safety Score',
            field: 'safety_score',
            filter: true
        },
        {
            headerName: 'Has Gig Trips?',
            field: 'driver_has_gig_trips',
            filter: true
        },
        {
            headerName: 'Had Reservation?',
            field: 'had_reservations',
            filter: true
        },
        {
            headerName: 'Rental Price Recommend Index',
            field: 'rental_price_recommendation',
            filter: true
        },
    ];

    
    const columns_alt = [
        {
            headerName: 'ID',
            width: 85,
            field: 'id',
            filter: true,
            pinned: 'left',
            cellRenderer: params => {
                let val = params.value
                if(displaySideDock===false)
                {
                    var link = '/user/' + String(val) 
                    return( <NavLink to={link} index = {val}>{val}</NavLink>)
                } else {
                    return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => selectObject(val)}>{val}</Button></Text>)
                }
            }
        },
        {
            headerName: 'First Name',
            field: 'name_first',
            filter: true
        },
        {
            headerName: 'Last Name',
            field: 'name_last',
            filter: true
        },
        {
            headerName: 'Insurance $',
            field: 'total_insurance_costs',
            filter: true
        },
        {
            headerName: 'Miles Driven',
            field: 'total_miles',
            filter: true
        },
        {
            headerName: 'Hours Driven',
            field: 'total_hours',
            filter: true
        },
        {
            headerName: 'Gig Liability Insured Miles',
            field: 'gig_liab_insured_mls',
            filter: true
        },
        {
            headerName: 'Gig Physical Damage Insured Miles',
            field: 'gig_phys_dam_insured_mls',
            filter: true
        },
        {
            headerName: 'Safety Bucket',
            field: 'safety_bucket',
            filter: true
        },
        {
            headerName: 'Safety Score',
            field: 'safety_score',
            filter: true
        },
        {
            headerName: 'Has Gig Trips?',
            field: 'driver_has_gig_trips',
            filter: true
        },
        {
            headerName: 'Had Reservation?',
            field: 'had_reservations',
            filter: true
        },
        {
            headerName: 'Rental Price Recommend Index',
            field: 'rental_price_recommendation',
            filter: true
        },
    ];

    async function selectNewFleet(val)
    {
        updateSelectedFleet(val)
        fetchPageData(val)
    }
    async function selectObject(val) {
        updateDisplaySideDock(false);
        await updateSelectedObjectId(val);
        updateDisplaySideDock(true);
    }


    async function fetchPageData(tSelectedFleet) {
        var thisFleet = false
        if(!tSelectedFleet)
        {
            thisFleet = selected_fleet
        } else {
            if(tSelectedFleet==="")
            {
                thisFleet = selected_fleet
            } else {
                thisFleet = tSelectedFleet
            }
        }
        //console.log('getting drivers...')
        updateIsLoading(true)
        /*
        let currentAuthUser = await Auth.currentAuthenticatedUser()
        if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"])
        {
            if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='admin'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='support'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='fleet')
            {
                updateDisplayAdministrativeFunctions(true)
            } else {
                updateTableColumnsView("alt")
            }
        }
        */
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        if(accessToken.payload["cognito:groups"])
        {
            if(accessToken.payload["cognito:groups"][0]==='admin'||accessToken.payload["cognito:groups"][0]==='support'||accessToken.payload["cognito:groups"][0]==='fleet')
            {
                updateDisplayAdministrativeFunctions(true)
            } else {
                updateTableColumnsView("alt")
            }
        }
        var jwt = accessToken.getJwtToken()
        var myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"drivers"
        }};
        if(thisFleet)
        {
            myInit = {queryStringParameters:{ 
                "back_end": process.env.REACT_APP_BACKEND,
                "jwt":jwt,
                "api_endpoint":"drivers",
                "fleet_code":thisFleet
            }};
        }
        //console.log(JSON.stringify(myInit))
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('users: '+JSON.stringify(res));
            updateUsers(res)
            updateFleetCodes(res.fleet_codes)
            updateSelectedFleet(res.fleet_code)
            //console.log('success');
        }
        catch (e) {
            const res = {status:"Error retrieving Users or User not permissioned - email us at developer@agorisk.com: "+toString(e),resp:[]}
            console.log(JSON.stringify(res))
            updateUsers(res)
        }
        updateIsLoading(false)
    }

    useEffect(() => {
        fetchPageData()
      },[])

    return(
        <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
            direction='column'      //direction={{ base: 'column', large: 'row' }}
            //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
            //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
            //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
            wrap="wrap" //nowrap
            gap="1rem"
        >
            <View style={{padding:"1rem"}}>
                <Flex
                    direction='column'
                >
                    <Heading level={1}>Drivers</Heading>
                    <Text>These are the drivers in your fleet, even if they haven't driven yet. Insurance costs are calculated for the last {displayAdministrativeFunctions ? "7 days" : "7 days"}.</Text>
                    <View>
                        <Button onClick={() => fetchPageData()}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    {is_loading ? <Loadingmini /> : 
                        <Flex direction="column">
                            <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                                <Fleetselector fleet_codes={fleet_codes} selected_fleet={selected_fleet} updateParentSelectedFleet={selectNewFleet}/>
                                {
                                    displayAdministrativeFunctions === false ? undefined :
                                    tableColumnsView === 'default' ?
                                        <View>
                                            <Button onClick={() => updateTableColumnsView('alt')}>
                                                DEFAULT VIEW
                                            </Button>
                                        </View>
                                    :
                                        <View>
                                            <Button onClick={() => updateTableColumnsView('default')}>
                                                INSURANCE VIEW
                                            </Button>
                                        </View>
                                }
                            </Flex>
                            <Flex wrap="wrap" gap="2rem" >
                                <Flex direction="column" style={displaySideDock ? {width:"25vw"} : {width:"80vw",maxWidth:"2200px"}}>
                                    <Divider />
                                    <View 
                                        className="ag-theme-quartz" // applying the grid theme
                                        style={{ height: 750 }} // the grid will fill the size of the parent container
                                    >
                                            <AgGridReact
                                                rowData={users.resp}
                                                columnDefs={tableColumnsView==='default' ? columns : columns_alt}
                                                rowSelection={'multiple'}
                                                paginationPageSize={50}
                                                paginationPageSizeSelector={[10, 25, 50]}
                                                enableCellTextSelection={true}
                                            />
                                    </View>
                                    <Divider />
                                </Flex>
                                {
                                    displaySideDock === false ? 
                                    <Flex direction="column">
                                        <Divider />
                                        <Button onClick={() => updateDisplaySideDock(true)}>
                                            ACTIVATE SIDE DOCK
                                        </Button>
                                        <Divider />
                                    </Flex> 
                                    :
                                    selectedObjectId===false || selectedObjectId < 1 ? <Text><i>Please select a user by clicking their user ID in the table...</i></Text> :
                                    <Flex direction="column" style={{width:"55vw"}}>
                                        <Divider />
                                        <Heading level={2}>Driver Details</Heading>
                                        <Text><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplaySideDock(false)}>To open drivers in full-screen mode, click here to hide the side dock.</Button></Text>
                                        <User paramUserId={selectedObjectId} paramFleet={selected_fleet}/>
                                        <Divider />
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </View>
        </Flex> 
    );
}
