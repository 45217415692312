import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';

export default function Indevelopment({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [count, setCount] = useState(0);

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>IN DEVELOPMENT</Heading>
        <p><b>Pardon our dust,</b> this feature is in development. Please use the mobile app to complete this section of signup.</p>
    </main>
  );
}