import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import TableContainer from '../components/tablecontainer';
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';

export default function ScheduleUploads() {
    let params = useParams();
    const [is_loading, updateIsLoading] = React.useState(false);
    const [res, updateRes] = useState({status:"Loading...",rows:[]});
    const [count, setCount] = useState(0);

    const tableColumns = [
        {
            Header: 'Uploaded Date',
            accessor: 'uploaded_datetime',
        },
        {
            Header: 'YYYY-MM',
            accessor: 'schedule_yyyy_mm',
        },
        {
            Header: 'Processed',
            accessor: 'processed_timestamp',
        },
        {
            Header: 'Sync Results',
            accessor: 'processing_result',
        }
    ]

    async function getScheduleUploads() {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{api_endpoint:"scheduleuploads", back_end: process.env.REACT_APP_BACKEND, jwt:jwt, "api_endpoint":"scheduleuploads"}};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updateRes(res)
        }
        catch (e) {
            const res = {status:"Error: " + JSON.stringify(e)}
            updateRes(res)
        }
        updateIsLoading(false)
    }

    useEffect(() => {
        getScheduleUploads();
    },[count])

    return (
        <main style={{ padding: "1rem" }}>
            <Heading level={2}>Schedule Uploads</Heading>
            <p>View the status of your schedule uploads.</p>
            <TableContainer columns={tableColumns} data = {res['rows']} defaultSort = 'uploaded_datetime'/>
            {is_loading ? <Loading /> : undefined}
            {res['status']==='success' ? undefined : res['status']}
        </main>
    );
}