import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { Button } from '@aws-amplify/ui-react';
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { FaQuestionCircle } from 'react-icons/fa';
import { PieChart } from 'react-minimal-pie-chart';
import Loading from '../components/loading';
import { Heading } from '@aws-amplify/ui-react';

export default function Recenttrips() {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"Loading...",rows:[]})
  const [count, setCount] = useState(0);
  const [selectedObjRangeStart, updateSelectedObjRangeStart] = useState("")
  const [selectedObjRangeEnd, updateSelectedObjRangeEnd] = useState("")
  const [selectedObjRangeStartFull, updateSelectedObjRangeStartFull] = useState("")
  const [selectedObjRangeEndFull, updateSelectedObjRangeEndFull] = useState("")
  const [displayJson, updateDisplayJson] = React.useState(false);



  async function fetchData() {
    updateIsLoading(true)
    //var i = 0;
    //var totalCost = 0;
    //var aveCost = "loading...";
    /*
    const session =  await Auth.currentSession();
    var userEmail = session.idToken.payload.email;
    */
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{
      "api_endpoint":"recenttrips", back_end: process.env.REACT_APP_BACKEND, jwt:jwt/*, user_email: userEmail*/, range_start: selectedObjRangeStart, range_end: selectedObjRangeEnd}};
    try {
        const res = await API.get('adminWebTools','/ago',myInit);
        updateRes(res);
        updateIsLoading(false);
        console.log('recenttrips:' + JSON.stringify(res));
        return
    }
    catch (e) {
        var statusString = "Error: " + e;
        const res = {status:statusString,rows:[]};
        updateRes(res);
        updateIsLoading(false)
        return
    }
  }
  useEffect(() => {
    fetchData();
  },[count])


  //var PageNoData = <View><p>{JSON.stringify(res)}</p></View>
  //var MustRefresh = <View><p>Please get updates to display table</p></View>

  
  function handleRangeStartSelected(date)
  {
      updateSelectedObjRangeStartFull(date);
      const date2 = date.toISOString().substring(0, 10);
      updateSelectedObjRangeStart(date2);
  }
  function handleRangeEndSelected(date)
  {
      updateSelectedObjRangeEndFull(date);
      const date2 = date.toISOString().substring(0, 10);
      updateSelectedObjRangeEnd(date2);
  }

  /*
  var RefreshButton = 
    <button onClick={() => setCount(count + 1)}>
                PRESS TO GET LATEST UPDATES
    </button>
  
  var DateRangeParams = <View>
    <p>Enter earliest date</p>
    <DatePicker
        name="Range Start"
        selected={selectedObjRangeStartFull}
        onChange={(date) => handleRangeStartSelected(date)}
        showTimeSelect
        dateFormat="yyyy-MM-d"
        timeIntervals={60}
        />
    <br/><br/>
    <p>Enter latest date</p>
    <DatePicker
        name="Range End"
        selected={selectedObjRangeEndFull}
        onChange={(date) => handleRangeEndSelected(date)}
        showTimeSelect
        dateFormat="yyyy-MM-d"
        timeIntervals={60}
        />
    <br/>
  </View>

  const renderAccordian = () => {
    if(!res['rows'][0]){
        return;
    }
    return res['rows'].map(function(object, index){
        return (
            <div><h3>Object title...</h3><p>Object information...</p></div>
        )
    })
  }
  */
  function toggleDisplayJson()
  {
    if(displayJson)
    {
      updateDisplayJson(false);
    } else {
      updateDisplayJson(true);
    }
  }
  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Recent Trips</Heading>
        {res['status']!=='success' ? res['status'] : res['rows'].length < 1 ? <div><p>You have no recent trip history. Come back after you've done some driving, and be sure to update your gig integrations so your trips are correctly classified.</p></div> : res['fleet_owner'] === true || res['fleet_owner'] === 'true' ? <div><p>You are a fleet owner. Please log out and log back in to view your entire Fleet's driving history, including your own, as it cannot be viewed here.</p><p>If you continue to return to the Driver Portal, please contact support to escalate your account privileges.</p></div> : <div>
          <p>On {res['recent_entry_date'] ? res['recent_entry_date'].substring(0,10) : "???"}, we analyzed the previous 7 days of your driving history. Here's a breakdown:</p>
          <h3>Risk Bucket: {res['risk_bucket'] == 'super_safe' ? 'Super Safe' : res['risk_bucket'] == 'safe' ? 'Safe' : res['risk_bucket'] == 'risky' ? 'Risky' : res['risk_bucket']}</h3>
          <table style={{marginBottom:25}}>
            <tr>
              <th></th><td></td><th style={{textAlign:'center', fontStyle:'italic'}}>{res['history_exists'] > 0 ? "7 Day Change:" : ""}</th>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles you drove <br/>in the last 7 days.">Total Miles:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th>
              <td style={{textAlign:'center'}}>{res['recent_total_miles']}</td>
              <td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>
                {res['change_total_miles'] > 0 ? "(" + res['change_total_miles'] + ")" : res['change_total_miles']===0 ? "" : "(" + res['change_total_miles'] + ")"}</span>
              </td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many hours the car <br/> was moving in the <br/> last 7 days.">Driving Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_total_hours']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_total_hours'] > 0 ? "(" + res['change_total_hours'] + ")" : res['change_total_hours']===0 ? "" : "(" + res['change_total_hours'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many days the car <br/> was on a reservation in <br/> the last 7 days.">Shift Days:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_shift_days']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_shift_days'] > 0 ? "(" + res['change_shift_days'] + ")" : res['change_shift_days']===0 ? "" : "(" + res['change_shift_days'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How much you spent on <br/>Liability Insurance.">Spent on Liability Insurance:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>${res['recent_liability_dollars']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_liability_dollars'] > 0 ? "(" + Math.round(res['change_liability_dollars'],2) + ")" : res['change_liability_dollars']===0 ? "" : "(" + Math.round(res['change_liability_dollars'],2) + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How much you spent on <br/>Physical Damage Insurance.">Spent on Physical Damage Insurance:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>${res['recent_physical_dollars']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_physical_dollars'] > 0 ? "(" + Math.round(res['change_physical_dollars'],2) + ")" : res['change_physical_dollars']===0 ? "" : "(" + Math.round(res['change_physical_dollars'],2) + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many hard acceleration <br/>events were recorded.">Hard Acceleration Events:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_hard_acceleration_events_tier_1']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_hard_acceleration_events_tier_1'] > 0 ? "(" + res['change_hard_acceleration_events_tier_1'] + ")" : res['change_hard_acceleration_events_tier_1']===0 ? "" : "(" + res['change_hard_acceleration_events_tier_1'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many hard acceleration <br/>events were recorded.">Severe Hard Acceleration Events:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_hard_acceleration_events_tier_2']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_hard_acceleration_events_tier_2'] > 0 ? "(" + res['change_hard_acceleration_events_tier_2'] + ")" : res['change_hard_acceleration_events_tier_2']===0 ? "" : "(" + res['change_hard_acceleration_events_tier_2'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many hard braking <br/>events were recorded. Some <br/>hard braking is necessary, but <br/>excessive hard braking is dangerous <br/>and damages your car.">Hard Braking Events:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_hard_braking_events_tier_1']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_hard_braking_events_tier_1'] > 0 ? "(" + res['change_hard_braking_events_tier_1'] + ")" : res['change_hard_braking_events_tier_1']==0 ? "" : "(" + res['change_hard_braking_events_tier_1'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many severe hard braking <br/>events were recorded. Some <br/>hard braking is necessary, but <br/>excessive hard braking is dangerous <br/>and damages your car.">Severe Hard Braking Events:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_hard_braking_events_tier_2']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_hard_braking_events_tier_2'] > 0 ? "(" + res['change_hard_braking_events_tier_2'] + ")" : res['change_hard_braking_events_tier_2']==0 ? "" : "(" + res['change_hard_braking_events_tier_2'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many times you drove without resting <br/>for 16-20 hours.">Recent Tired Driving Events 16-20 Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_tired_driving_1_events']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_tired_driving_1_events'] > 0 ? "(" + res['change_tired_driving_1_events'] + ")" : res['change_tired_driving_1_events']===0 ? "" : "(" + res['change_tired_driving_1_events'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many times you drove without resting <br/>for over 20 hours.">Recent Tired Driving Events Over 20 Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_tired_driving_2_events']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_tired_driving_2_events'] > 0 ? "(" + res['change_tired_driving_2_events'] + ")" : res['change_tired_driving_2_events']===0 ? "" : "(" + res['change_tired_driving_2_events'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many times you were <br/>caught speeding.">Normal Speeding Events (90-95):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_speeding_1_events']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_speeding_1_events'] > 0 ? "(" + res['change_speeding_1_events'] + ")" : res['change_speeding_1_events']===0 ? "" : "(" + res['change_speeding_1_events'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many times you were <br/>caught speeding extra fast.">Worse Speeding Events (95-100):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_speeding_2_events']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_speeding_2_events'] > 0 ? "(" + res['change_speeding_2_events'] + ")" : res['change_speeding_2_events']===0 ? "" : "(" + res['change_speeding_2_events'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many times you were speeding <br/>so much it was very dangerous.">Terrible Speeding Events (100+):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_speeding_3_events']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_speeding_3_events'] > 0 ? "(" + res['change_speeding_3_events'] + ")" : res['change_speeding_3_events']===0 ? "" : "(" + res['change_speeding_3_events'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles that you drove were covered <br/>by your gig platform's Liability Insurance.">Gig Insured Miles (Liability):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_gig_insured_miles_liability']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_gig_insured_miles_liability'] > 0 ? "(" + res['change_gig_insured_miles_liability'] + ")" : res['change_gig_insured_miles_liability']===0 ? "" : "(" + res['change_gig_insured_miles_liability'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many miles that you drove were covered <br/>by your gig platform's Physical Damage Insurance.">Gig Insured Miles (Physical Damage):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_gig_insured_miles_physical']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_gig_insured_miles_physical'] > 0 ? "(" + res['change_gig_insured_miles_physical'] + ")" : res['change_gig_insured_miles_physical']===0 ? "" : "(" + res['change_gig_insured_miles_physical'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="Of the miles you drove for your gig, <br/>this is how many were NOT covered by their Liability Insurance.">Gig Uninsured Miles (Liability):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_uninsured_miles_liability']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_uninsured_miles_liability'] > 0 ? "(" + res['change_uninsured_miles_liability'] + ")" : res['change_uninsured_miles_liability']===0 ? "" : "(" + res['change_uninsured_miles_liability'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="Of the miles you drove for your gig, <br/>this is how many were NOT covered by their Physical Damage Insurance.">Gig Uninsured Miles (Physical Damage):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_uninsured_miles_physical']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_uninsured_miles_physical'] > 0 ? "(" + res['change_uninsured_miles_physical'] + ")" : res['change_uninsured_miles_physical']===0 ? "" : "(" + res['change_uninsured_miles_physical'] + ")"}</span></td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles you drove on the <br/>more expensive Personal Liability Insurance.">Personal Miles (Liability):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_personal_insured_miles_liability']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_personal_insured_miles_liability'] > 0 ? "(" + res['change_personal_insured_miles_liability'] + ")" : res['change_personal_insured_miles_liability']===0 ? "" : "(" + res['change_personal_insured_miles_liability'] + ")"}</span></td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many miles you drove on the <br/>more expensive Physical Damage Insurance.">Personal Miles (Physical Damage):  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center'}}>{res['recent_personal_insured_miles_physical']}</td><td style={{textAlign:'center'}}><span style={{fontStyle:'italic'}}>{res['change_personal_insured_miles_physical'] > 0 ? "(" + res['change_personal_insured_miles_physical'] + ")" : res['change_personal_insured_miles_physical']===0 ? "" : "(" + res['change_personal_insured_miles_physical'] + ")"}</span></td>
            </tr>
          </table>
          <h4 data-place="right" data-multiline="true" data-tip="How many risk driving events <br/>would occur per 1000 miles <br/>driven">Risky Driving per 1000 Miles</h4>
          <p>To normalize driver behavior data, it helps to take mileage into account. Here's a breakdown of risky driving events per 1000 miles driven, based on the data from the last 7 days:</p>
          <table style={{marginBottom:25}}>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many speeding events over <br/>90mph per 1000 miles driven.">Speeding:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_speeding_events_per_1000_miles_tier_1']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many speeding events over <br/>95mph per 1000 miles driven.">Speeding Worse:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_speeding_events_per_1000_miles_tier_2']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many speeding events over <br/>100mph per 1000 miles driven.">Speeding Worst:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_speeding_events_per_1000_miles_tier_3']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many hard brake events <br/>per 1000 miles driven.">Hard Brake:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_brake_events_per_1000_miles_tier_1']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many severe hard brake events <br/>per 1000 miles driven.">Severe Hard Brake:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_brake_events_per_1000_miles_tier_2']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many hard acceleration <br/>per 1000 miles driven.">Hard Accel:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_accelerate_events_per_1000_miles_tier_1']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many severe hard acceleration <br/>per 1000 miles driven.">Severe Hard Accel:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_accelerate_events_per_1000_miles_tier_2']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many tired driving events <br/>per 1000 miles driven.">Tired 16-20 Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_tired_events_per_1000_miles_tier_1']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many exhausted driving events <br/>per 1000 miles driven.">Tired 20+ Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_tired_events_per_1000_miles_tier_2']}</td>
            </tr>
          </table>
          <h4 data-place="right" data-multiline="true" data-tip="How many risk driving events <br/>would occur per 100 hours <br/>driven">Risky Driving per 100 Hours</h4>
          <p>To normalize driver behavior data, it helps to take hours driven into account. Here's a breakdown of risky driving events per 100 hours driven, based on the data from the last 7 days:</p>
          <table style={{marginBottom:25}}>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many speeding events over <br/>90mph per 100 hours driven.">Speeding:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_speeding_events_per_100_miles_tier_1']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many speeding events over <br/>95mph per 100 hours driven.">Speeding Worse:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_speeding_events_per_100_miles_tier_2']}</td>
            </tr>
            <tr data-place="right" data-multiline="true">
              <th data-tip="How many speeding events over <br/>100mph per 100 hours driven.">Speeding Worst:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_speeding_events_per_100_miles_tier_3']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many hard brake events <br/>per 100 hours driven.">Hard Brake:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_brake_events_per_100_miles_tier_1']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many severe hard brake events <br/>per 100 hours driven.">Severe Hard Brake:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_brake_events_per_100_miles_tier_2']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many hard acceleration <br/>per 100 hours driven.">Hard Accel:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_accelerate_events_per_100_miles_tier_1']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many severe hard acceleration <br/>per 100 hours driven.">Severe Hard Accel:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_hard_accelerate_events_per_100_miles_tier_2']}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many tired driving vents <br/>per 100 hours driven.">Tired 16-20 Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_tired_events_per_100_miles_tier_1']}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many exhausted driving vents <br/>per 100 hours driven.">Tired 20+ Hours:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{res['recent_tired_events_per_100_miles_tier_2']}</td>
            </tr>
          </table>
          <h4 data-place="right" data-multiline="true" data-tip="How many miles were driven <br/>with liability coverage <br/>from each platform">Liability Coverage: Gig vs. Personal Miles</h4>
          <p>Here's a breakdown of how many miles were driven with liability coverage from each gig or the more expensive personal coverage:</p>
          <table style={{marginBottom:25}}>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Uber's Liability coverage">Uber:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_liability_uber'])}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Lyft's Liability coverage">Lyft:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_liability_lyft'])}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Doordash's Liability coverage">Doordash:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_liability_doordash'])}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Amazon Flex's Liability coverage">Amazon Flex:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_liability_amazonflex'])}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Personal Liability coverage">Personal:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_liability_personal'])}</td>
            </tr>
          </table>
          <h4 data-place="right" data-multiline="true" data-tip="How many miles were driven <br/>with physical damage coverage <br/>from each platform">Physical Damage Coverage: Gig vs. Personal Miles</h4>
          <p>Here's a breakdown of how many miles were driven with physical damage coverage from each gig or the more expensive personal coverage:</p>
          <table style={{marginBottom:25}}>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Uber's Physical Damage coverage">Uber:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_physical_uber'])}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Lyft's Physical Damage coverage">Lyft:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_physical_lyft'])}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Doordash's Physical Damage coverage">Doordash:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_physical_doordash'])}</td>
            </tr>
            <tr style={{backgroundColor:'#e8e9ed'}}>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Amazon Flex's Physical Damage coverage">Amazon Flex:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_physical_amazonflex'])}</td>
            </tr>
            <tr>
              <th data-place="right" data-multiline="true" data-tip="How many miles were covered <br/>by Personal Physical Damage coverage">Personal:  <FaQuestionCircle style={{width:10, marginTop:-15}}/></th><td style={{textAlign:'center', paddingLeft:20}}>{Math.round(res['recent_gig_insured_miles_physical_personal'])}</td>
            </tr>
          </table>
          <h4 data-place="right" data-multiline="true" data-tip="How many miles were driven <br/>with Liability insurance covered <br/>by Gig versus Personal">Liability Gig vs. Personal miles</h4>
          <p>Try to maximize the number of miles driven under Gig Liability insurance.</p>
          <div style={{width:400, height:400}}>
            <PieChart
              data={[
                { title: 'Gig', value: Math.round(res['recent_gig_insured_miles_liability']/res['recent_total_miles']*100), color: '#a3d6f0' },
                { title: 'Personal', value: Math.round((res['recent_uninsured_miles_liability']+res['recent_personal_insured_miles_liability'])/res['recent_total_miles']*100), color: '#C13C37' }
              ]}
              label={({ dataEntry }) => dataEntry.title + " " +  dataEntry.value + "%"}
              labelStyle={{fontSize:5}}
            />
          </div>
          <br/>
          <h4 data-place="right" data-multiline="true" data-tip="How many miles were driven <br/>with Physical Damage insurance <br/>covered by Gig versus Personal">Physical Damage Gig vs. Personal miles</h4>
          <p>Try to maximize the number of miles driven under Gig Physical Damage insurance.</p>
          <div style={{width:400, height:400}}>
            <PieChart
              data={[
                { title: 'Gig', value: Math.round(res['recent_gig_insured_miles_physical']/res['recent_total_miles']*100), color: '#a3d6f0' },
                { title: 'Personal', value: Math.round((res['recent_uninsured_miles_physical']+res['recent_personal_insured_miles_physical'])/res['recent_total_miles']*100), color: '#C13C37' }
              ]}
              label={({ dataEntry }) => dataEntry.title + " " +  dataEntry.value + "%"}
              labelStyle={{fontSize:5}}
            />
          </div>
          <ReactTooltip />
          </div>}
        <br/><br/>
        <p>{res['history_exists'] > 0 ? "" : res['message']}</p>
        <br/><br/>
        {/*displayJson===true ? <div><p><Button onClick={() => toggleDisplayJson()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>Hide JSON</Button></p><p><a href='http://jsonprettyprint.net/' target='_blank'>PRETTY JSON</a></p><p style={{maxWidth:'500px', backgroundColor:'#eaebdf',overflow:'clip'}}>{JSON.stringify(res)}</p></div> : <p><Button onClick={() => toggleDisplayJson()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>View JSON</Button></p>*/}

        {is_loading ? <Loading /> : undefined}
    </main>
  );
}