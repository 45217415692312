import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';
import Homepage from "./routes/homepage";
import Vehicles from "./routes/vehicles";
import Vehicle from "./routes/vehicle";
import Users from "./routes/users";
import User from "./routes/user";
import Reservations from "./routes/reservations";
import Reservation from "./routes/reservation";
import Dispute from "./routes/dispute";
import CarMaintHist from "./routes/carmainthist";
import Recenttrips from "./routes/recenttrips";
import Fleetwidesummary from "./routes/fleetwidesummary";
import Recenttripscars from "./routes/recenttripscars";
import Recenttripsusers from "./routes/recenttripsusers";
import UploadLicense from './routes/uploadlicense';
import UploadLicenseBack from './routes/uploadlicenseback';
import GigIntegrations from './routes/gigintegrations';
import ChatMessages from './routes/chatmessages';
import Terms from './routes/terms';
//import Selfie from './routes/selfie';<Route path="selfie" element={<Selfie />} />
import FleetSuppApp from './routes/fleetsuppapp';
import UploadSchedule from './routes/uploadschedule';
import ScheduleUploads from './routes/scheduleuploads';
import Fleetschedule from './routes/fleetschedule';
import FleetAssignment from './routes/fleetassignment';
import AccountInfo from './routes/accountinfo';
import AccountInfoAlt from './routes/accountinfoalt';
import Checkr1 from './routes/checkr1';
import Checkr4 from './routes/checkr4';
import Billinghistory from './routes/billinghistory';
import Billingentry from './routes/billingentry';
import Fleetscheduleentry from './routes/fleetscheduleentry';
import Alerts from './routes/alerts';
import Vehiclesutilization from './routes/vehiclesutilization';
import Vehicleutilization from './routes/vehicleutilization';

//added here for email verification
import Profile from './routes/profile';


//const versionNumber = "1.3.01";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={<Homepage />}
          />
          <Route path="alerts" element={<Alerts />} />
          <Route path="users" element={<Users />} />
          <Route path="user/:userId" element={<User />} />
          <Route path="reservations" element={<Reservations />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="vehicle/:vehicleId" element={<Vehicle />} />
          <Route path="reservation/:resId" element={<Reservation />} />
          <Route path="dispute" element={<Dispute />} />
          <Route path="carMaintHist" element={<CarMaintHist />} />
          <Route path="recenttrips" element={<Recenttrips />} />
          <Route path="fleetwidesummary" element={<Fleetwidesummary />} />
          <Route path="recenttripscars" element={<Recenttripscars />} />
          <Route path="recenttripsusers" element={<Recenttripsusers />} />
          <Route path="uploadlicense" element={<UploadLicense />} />
          <Route path="uploadlicenseback" element={<UploadLicenseBack />} />
          <Route path="gigintegrations" element={<GigIntegrations />} />
          <Route path="dashboard" element={<ChatMessages />} />
          <Route path="terms" element={<Terms />} />
          <Route path="fleetsuppapp" element={<FleetSuppApp />} />
          <Route path="uploadschedule" element={<UploadSchedule />} />
          <Route path="scheduleuploads" element={<ScheduleUploads />} />
          <Route path="fleetschedule" element={<Fleetschedule />} />
          <Route path="fleetassignment" element={<FleetAssignment />} />
          <Route path="profile" element={<Profile />} />
          <Route path="accountinfo" element={<AccountInfo />} />
          <Route path="accountinfoalt" element={<AccountInfoAlt />} />
          <Route path="agreements/:docId" element={<Checkr1 />} />
          <Route path="esign" element={<Checkr4 />} />
          <Route path="billinghistory" element={<Billinghistory />} />
          <Route path="billingentry/:billingPeriodStartEnd" element={<Billingentry />} />
          <Route path="fleetscheduleentry/:entryIdentifier" element={<Fleetscheduleentry />} />
          <Route path="vehiclesutilization" element={<Vehiclesutilization />} />
          <Route path="vehicleutilization/:vehicleId" element={<Vehicleutilization />} />
          <Route
            path="*"
            element={<Homepage /> 
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();