import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Button, Text, View, TextField, Heading, Flex } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';

import { CognitoIdentityProviderClient, GetUserAttributeVerificationCodeCommand, GetUserCommand,VerifyUserAttributeCommand } from "@aws-sdk/client-cognito-identity-provider";

export default function Profile({isHomepage}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [new_email, setNewEmail] = useState("");
  const [email, setEmail] = useState("");
  const [email_verified, updateEmailVerified] = useState(false);
  const [verification_code, setVerificationCode] = useState("");
  const [last_error, setLastError] = useState("");
  const [show_update_email, updateShowUpdateEmail] = useState(false);
  const [verification_code_just_sent, updateVerificationCodeJustSent] = useState(false);

  async function getCurrentProfile() {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let d  = { "AccessToken":jwt } 
    const client = new CognitoIdentityProviderClient({ region: "us-east-2" });
    const command = new GetUserCommand(d);
    const response = await client.send(command);
    const e_v = response.UserAttributes.find(item => item.Name === "email_verified").Value === 'true'
    updateEmailVerified(e_v)
    const email = response.UserAttributes.find(item => item.Name === "email").Value
    setEmail(email)
    updateIsLoading(false)
  }

  function toggleShowUpdateEmail()
  {
    if(show_update_email)
    {
        updateShowUpdateEmail(false);
    } else {
        updateShowUpdateEmail(true);
    }
  }

  async function sendConfirmCode() {
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let d  = {
        "AccessToken":jwt,
        "AttributeName":"email"
    }
    const client = new CognitoIdentityProviderClient({ region: "us-east-2" });
    const command = new GetUserAttributeVerificationCodeCommand(d);
    try {
        await client.send(command);
        setLastError("")
        console.log('send confirm occurred');
        updateVerificationCodeJustSent(true);
    } catch(e) {
        console.log('failed with error', e.name);
        if (e.name === "LimitExceededException") {
            setLastError("Exceeded Limit of email change/verification attempts - please wait 15 minutes and try again")
        }
        else {
            setLastError("Unknown error trying to send code")
        }
    }
  }

  async function verifyCode() {
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let d  = {
        "AccessToken":jwt,
        "AttributeName":"email",
        "Code": verification_code
    }
    const client = new CognitoIdentityProviderClient({ region: "us-east-2" });
    const command = new VerifyUserAttributeCommand(d);
    try {
        await client.send(command);
        setLastError("")
        console.log('verification occurred');
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"user",
            "method":"update_email",
            "email":email
        }};
        const res = await API.get('adminWebTools','/ago',myInit);
        if(typeof isHomepage==='boolean')
        {
          if(isHomepage)
          {
            window.location.reload();
          }
        }
    } catch(e) {
        console.log('failed with error', e.name);
        if (e.name === "ExpiredCodeException" || e.name === "CodeMismatchException") {
            setLastError("Invalid or Expired Code - Try enter again or Send new code to your email")
        }
        else if (e.name === "InvalidParameterException") {
            setLastError("Enter Code before pressing submit")
        }
        else if (e.name === "LimitExceededException") {
            setLastError("Exceeded Limit of email change/verification attempts - please wait 15 minutes and try again")
        }
        else {
            setLastError("Unknown error trying to verify code")
        }
            
    } finally {
        getCurrentProfile()
    }
  }

  async function changeEmail() {
    const user = await Auth.currentAuthenticatedUser();
    try {
        await Auth.updateUserAttributes(user, {
            'email': new_email
        });
        setLastError("")
        console.log('EMAIL CHANGE OCCURRED  ');
    } catch(e) {
        console.log('failed with error', e.name);
        if (e.name === "InvalidParameterException") {
            setLastError("Enter Valid Email Format")
        }
        else if (e.name === "LimitExceededException") {
            setLastError("Exceeded Limit of email change/verification attempts - please wait 15 minutes and try again")
        }
        else {
            setLastError("Unknown error trying to verify code")
        }
    } finally {
        await sendConfirmCode()
        getCurrentProfile()
    }
  }

  async function checkFirstEmailVerifyAttempt() {
    //console.log("check and send first confirm code")
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let user = await Auth.currentAuthenticatedUser(); 
    const myInit = {queryStringParameters:{ 
        "back_end": process.env.REACT_APP_BACKEND,
        "jwt":jwt,
        "api_endpoint":"user",
        "method":"check_email_verify_attempt",
        "cognito_id": user.attributes.sub,
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        if (res.status === "success" && res.send === true) {
            sendConfirmCode()
        } 
    } catch(e) {
        // do nothing - just don't send the code
    }
  }

  useEffect(() => {
    getCurrentProfile();
    if (email_verified === false) {
        checkFirstEmailVerifyAttempt();
    }
  },[])

  return (
    <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction='column'      //direction={{ base: 'column', large: 'row' }}
        //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
        //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
        //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
        wrap="wrap" //nowrap
        gap="1rem"
    >
        <View style={{padding:"1rem"}}>
        {
            is_loading ? <Loading /> : 
            <Flex 
                direction='column'
            >
                <Heading level={2}>Email Address</Heading>
                {email_verified === false ? 
                    <Text>Please verify your email before continuing.</Text>
                : 
                    <Text>Thank you, your email address is verified.</Text>
                }
                {email_verified ? undefined :
                    <View>
                        <TextField
                            descriptiveText="Enter verification code received in email"
                            placeholder="123456"
                            onChange={(e) => setVerificationCode(e.currentTarget.value)}
                            outerEndComponent={<Button onClick={() => verifyCode()}>Submit</Button>}
                        />

                        <Text>   </Text>
                        {verification_code_just_sent ? <p>We re-sent your verification code. Please check your email.</p>: <Text></Text>}
                        <Button onClick={() => sendConfirmCode()}>
                            Send/Resend Verification Code To Email
                        </Button>
                    </View>
                }
                {last_error === "" ? undefined :
                    <Text fontStyle="italic" color="red"> Last Error: {last_error}</Text>
                }
                <Text>Your email address:  {email}</Text>
                <Text><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => toggleShowUpdateEmail(true)}>Want to change your email address?</Button></Text>
                {show_update_email ? 
                <TextField
                    descriptiveText="Change email"
                    placeholder={email}
                    onChange={(e) => setNewEmail(e.currentTarget.value)}
                    outerEndComponent={<Button onClick={() => changeEmail()}>Submit</Button>}
                />
                : undefined}
            </Flex>
        }
        </View>
    </Flex>
  );
}
