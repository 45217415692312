import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text, View, Flex, Divider} from '@aws-amplify/ui-react';
import Loadingmini from '../components/loadingmini';
import { Heading, Button } from '@aws-amplify/ui-react';
import Userinfo from './userinfo';
import Userinsurancedata from './userinsurancedata';
import Userinsurancebuckets from './userinsurancebuckets';
import Userriskydriving from './userriskydriving';
import Usercommercialplatforms from './usercommercialplatforms';
import Usercommercialplatformaccounts from './usercommercialplatformaccounts';
import Usercommercialplatformactivity from './usercommercialplatformactivity';
import Userstatushistory from "./userstatushistory";
import Userchathistory from "./userchathistory";
import Userreservations from "./userreservations";
import Usercredits from "./usercredits";
import Userdocs from "./userdocs";
import Userdeposits from "./userdeposits";
import Fleetselector from "../components/fleetselector";


const roundBack = (date,hours) => {
  date.setHours(date.getHours() - hours);
  date.setMinutes(0, 0, 0);
  return date;
}



export default function User({paramUserId, paramFleet}) {
  let params = useParams();
  var tuserId = false
  var tfleet = false
  if(paramUserId)
  {
    tuserId = paramUserId;
  } else {
    tuserId = params.userId;
  }
  if(paramFleet)
  {
    tfleet = paramFleet;
  } else {
    tfleet = params.vehicleId;
  }
  const [userId, updateUserId] = React.useState(tuserId);
  const [user, updateUser] = useState({status:"If this message stays - email us at info@joinago.com",rows:[]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [commercialPlatformActivityMaxDate, setCommercialPlatformActivityMaxDate] = useState(roundBack(new Date(),-0));
  const [commercialPlatformActivityMaxDateIsDefault, setCommercialPlatformActivityMaxDateIsDefault] = useState(true);
  const [showDataPacket, updateShowDataPacket] = React.useState(false);
  const [jwt, setJwt] = React.useState(false);
  const [fleet_codes, updateFleetCodes] = React.useState(false);
  const [selected_fleet, updateSelectedFleet] = React.useState(tfleet);
  const [displayAdministrativeFunctions, updateDisplayAdministrativeFunctions] = React.useState(false);
  const [displaySuperAdministrativeFunctions, updateDisplaySuperAdministrativeFunctions] = React.useState(false);

  
  async function selectNewFleet(val)
  {
      updateSelectedFleet(val)
      fetchUserData(val)
  }
  const dateString = (d) => {
    var s = d.getFullYear() + "-" +
    ("0" + (d.getMonth()+1)).slice(-2) + "-" +
    ("0" + d.getDate()).slice(-2)
    return s;
  }

  function selectCommercialPlatformActivityMaxDate(date)
  {
    setCommercialPlatformActivityMaxDateIsDefault(false);
    setCommercialPlatformActivityMaxDate(date);
    fetchUserData()
  }

  const fetchUserData = async (tSelectedFleet) => {
    var thisFleet = ""
    if(!tSelectedFleet)
    {
        thisFleet = selected_fleet
    } else {
        if(tSelectedFleet==="")
        {
            thisFleet = selected_fleet
        } else {
            thisFleet = tSelectedFleet
        }
    }
    var thisUserId = userId;
    if(paramUserId > 0)
    {
      thisUserId = paramUserId;
      updateUserId(paramUserId);
    }
    updateIsLoading(true)
    /*
    let currentAuthUser = await Auth.currentAuthenticatedUser()
    if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"])
    {
        if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='admin'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='support'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='fleet')
        {
            updateDisplayAdministrativeFunctions(true)
        }
    }
    */
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    if(accessToken.payload["cognito:groups"])
      {
          if(accessToken.payload["cognito:groups"][0]==='admin'||accessToken.payload["cognito:groups"][0]==='support'||accessToken.payload["cognito:groups"][0]==='fleet')
          {
              updateDisplayAdministrativeFunctions(true)
              if(accessToken.payload["cognito:groups"][0]==='admin')
              {
                  updateDisplaySuperAdministrativeFunctions(true)
              }
          }
      }
    var thisJwt = await accessToken.getJwtToken()
    setJwt(thisJwt)
    if(commercialPlatformActivityMaxDateIsDefault)
    {
      if(thisFleet)
      {
        var initProps = { 
          back_end: process.env.REACT_APP_BACKEND, 
          api_endpoint:"drivers",
          jwt:thisJwt,
          driver_id: thisUserId,
          fleet_code: thisFleet
        }
      } else {
        var initProps = { 
          back_end: process.env.REACT_APP_BACKEND, 
          api_endpoint:"drivers",
          jwt:thisJwt,
          driver_id: thisUserId
        }
      }
    } else {
      //console.log('commercialPlatformActivityMaxDate:'+commercialPlatformActivityMaxDate);
      var dateStringResult = dateString(commercialPlatformActivityMaxDate);
      //console.log('dateStringResult:'+dateStringResult);
      if(thisFleet)
      {
        var initProps = { 
          back_end: process.env.REACT_APP_BACKEND, 
          api_endpoint:"users",
          jwt:thisJwt,
          driver_id: thisUserId,
          commercialPlatformActivityMaxDate:dateStringResult,
          fleet_code:thisFleet
        }
      } else {
        var initProps = { 
          back_end: process.env.REACT_APP_BACKEND, 
          api_endpoint:"users",
          jwt:thisJwt,
          driver_id: thisUserId,
          commercialPlatformActivityMaxDate:dateStringResult
        }
      }
    }
    const myInit = {queryStringParameters:initProps};
    
    //console.log(JSON.stringify('myInit: '+JSON.stringify(myInit))); //temp

    try {
      //console.log("driver_id:  " + thisUserId);
      const res = await API.get('adminWebTools','/ago',myInit);
      updateUser(res);
      updateFleetCodes(res.fleet_codes);
      updateSelectedFleet(res.fleet_code);
      updateIsLoading(false)
      return
    }
    catch (e) {
        const res = {status:"Error retrieving Users or User not permissioned - email us at info@joinago.com",rows:[]};
        updateUser(res);
        updateIsLoading(false)
        return
    }
  }

  useEffect(() => {
    fetchUserData()
  },[params,paramUserId])

  
  return (
    is_loading ? <Loadingmini /> : 
    <Flex //  https://ui.docs.amplify.aws/react/components/flex#demo
      direction="column"
    >
      <View style={{padding:"1rem"}}>
          <Flex>
            {
              user === false ? <Heading level={1}>Driver {userId}</Heading> :
              user.resp === false ? <Heading level={1}>Driver {userId}</Heading> :
              typeof(user.resp) === 'undefined' ?  <Heading level={1}>Driver {userId}</Heading> :
              user.resp[0] === false || user.resp[0] === null ? <Heading level={1}>Driver {userId}</Heading> :
              typeof(user.resp[0]) === 'undefined' ?  <Heading level={1}>Driver {userId}</Heading> :
              'name_first' in user.resp[0] === false ? <Heading level={1}>Driver {userId}</Heading> :
              user.resp[0]["name_first"] === "" ? <Heading level={1}>Driver {userId}</Heading> :
              <Heading level={1}>{user.resp[0]["name_first"]} {user.resp[0]["name_last"]}</Heading>
            }
          </Flex>
          <Flex direction="column">
              <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                <View>
                  <Button onClick={() => fetchUserData()}>
                      PRESS TO GET LATEST UPDATES
                  </Button>
                </View>
                <Fleetselector fleet_codes={fleet_codes} selected_fleet={selected_fleet} updateParentSelectedFleet={selectNewFleet}/>
              </Flex>
          </Flex>
          <Divider />
          {
            jwt === false ? undefined :
            selected_fleet ?
            <Flex direction="column">
                <Flex wrap="wrap" justifyContent="center" gap="1rem">
                  {
                    user.resp[0] ?
                    <View>
                      <Userinfo userJwt={jwt} user={user} getUpdatedUserData={() => fetchUserData()} fleetCode={selected_fleet} displayAdministrativeFunctions={displayAdministrativeFunctions} />
                      <Userstatushistory userJwt={jwt} refreshJwt={() => fetchUserData()} userId={userId} fleetCode={selected_fleet} displayAdministrativeFunctions={displayAdministrativeFunctions}/>
                      {
                        displayAdministrativeFunctions === false ? undefined :
                        <View>
                          <Userreservations userJwt={jwt} refreshJwt={() => fetchUserData()} userId={userId} fleetCode={selected_fleet} />
                          <Userchathistory userJwt={jwt} refreshJwt={() => fetchUserData()} userId={userId} fleetCode={selected_fleet} />
                          <Usercredits userJwt={jwt} refreshJwt={() => fetchUserData()} userId={userId} fleetCode={selected_fleet} />
                          <Userdeposits userJwt={jwt} refreshJwt={() => fetchUserData()} userId={userId} fleetCode={selected_fleet} />
                        </View>
                      }
                      <Userinsurancedata user={user} displayAdministrativeFunctions={displayAdministrativeFunctions}/>
                      <Userinsurancebuckets user={user} displayAdministrativeFunctions={displayAdministrativeFunctions} />
                      <Userriskydriving user={user} />
                      <Usercommercialplatforms user={user} />
                      <Usercommercialplatformaccounts user={user} />
                      <Usercommercialplatformactivity user={user} selectCommercialPlatformActivityMaxDate={selectCommercialPlatformActivityMaxDate} commercialPlatformActivityMaxDate={commercialPlatformActivityMaxDate} />
                      <Userdocs userJwt={jwt} userId={userId} fleetCode={selected_fleet} />
                    </View>
                    :
                    <View>
                      <Heading level={4}>Driver Not Found</Heading>
                      <Text>Has this driver joined the selected fleet?</Text>
                    </View>
                  }
                </Flex>
              </Flex>
            : <Text>Loading...</Text>
          }
          {user === false ? undefined:
            user.resp === false ? undefined:
            typeof(user.resp) === 'undefined' ?  undefined:
            user.resp[0] === false ? undefined:
            <Flex direction="column">
              <Divider />
              {
                displaySuperAdministrativeFunctions===false?undefined:
                showDataPacket ?
                  <Flex direction="column">
                    <Heading level={2}>Data Packet</Heading>
                    <pre id="json"><p>{JSON.stringify(user,null, 4)}</p></pre>
                    <Button onClick={() => updateShowDataPacket(false)}>
                      Hide Data Packet
                    </Button>
                  </Flex>
                : 
                  <Button onClick={() => updateShowDataPacket(true)}>
                    User Data Packet
                  </Button>
              }
              <br/><br/><br/><br/>
            </Flex>
          }
      </View>
    </Flex>
  );
}