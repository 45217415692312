import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import MyArgyle from '../components/argyle.js';
import { Heading } from '@aws-amplify/ui-react';
import { API, Auth } from 'aws-amplify';

export default function GigIntegrations({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [count, setCount] = useState(0);

  async function getIntegrations() {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"user",
            "method":"driver_integration"
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateRes(res)
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
    }
    updateIsLoading(false)
  }

  useEffect(() => {
    getIntegrations();
  },[count])

  function renderFormattedIntegrations()
  {
    if(!res['formatted_integrations']){
      return;
    }
    return res['formatted_integrations'].map(function(object, index){
      return(<tr><td style={{border:'1px solid black', paddingLeft:'10px', paddingRight:'10px'}}>{object.partner}</td><td style={{paddingLeft:'10px', paddingRight:'10px', border:'1px solid black'}}>{object.connection_status}</td><td style={{paddingLeft:'10px', paddingRight:'10px', border:'1px solid black'}}>{object.connection_status=='success' ? undefined : object.connection_message}</td></tr>)
    })
  }

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Update Integrations</Heading>
        {res['status']=="success" ? <p>Click 'Open Account Integration' to update your gig platform integrations.<br/><br/><table style={{}}><tr><th style={{border:'1px solid black', paddingLeft:'10px', paddingRight:'10px', backgroundColor:'#e8e9ed'}}>Platform</th><th style={{border:'1px solid black', paddingLeft:'10px', paddingRight:'10px', backgroundColor:'#e8e9ed'}}>Status</th><th style={{border:'1px solid black', paddingLeft:'10px', paddingRight:'10px', backgroundColor:'#e8e9ed'}}>Message</th></tr>{renderFormattedIntegrations()}</table></p> : <p>{res['status']}</p>}
        <br/>
        <MyArgyle/>
        {is_loading ? <Loading /> : undefined}
    </main>
  );
}