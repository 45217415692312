import React, { useState } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, View, Text, Button, Heading} from '@aws-amplify/ui-react';

export default function Vehicleinsurance({vehicle}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [insurance_url, updateUrl] = React.useState(false);
  const [myerror, updateMyerror] = React.useState(false);

  async function getImageUrl() {
    updateIsLoading(true)
    console.log('generating insurance card for car ' + vehicle['resp'][0]['car_id'] + "...");
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:jwt,
        api_endpoint:"user",
        method:"get_insurance_card",
        vehicle_id: vehicle['resp'][0]['car_id']
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res))
        if('url' in res === false)
        {
          console.log('error: url not found');
          updateMyerror("there was an error processing your request")
          updateIsLoading(false)
          return
        }
        console.log('url: '+res['url'])
        updateUrl(res['url'])
        updateIsLoading(false)
        return
    }
    catch (e) {
        console.log('error: '+JSON.stringify(e))
        updateIsLoading(false)
        return
    }
  }

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{width:"100%"}}>
          <Flex direction="column" gap="1rem" >
            <Flex direction="column">
                { myerror ? <View>
                        <Heading level={4}>Whoops!</Heading>
                        <Text>We ran into an error processing your request:</Text>
                        <Text>{myerror}</Text>
                        <Button onClick={() => getImageUrl()}>TRY AGAIN</Button>
                    </View>
                  : insurance_url ? 
                    <Text><a href={insurance_url} target='_blank'>CLICK TO DOWNLOAD INSURANCE</a></Text>
                  : <Button onClick={() => getImageUrl()}>VIEW INSURANCE</Button>
                }
            </Flex>
          </Flex>
        </View>
      </Flex>
  );
}