import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import { FaWindowClose } from 'react-icons/fa';
import { Heading } from '@aws-amplify/ui-react';

export default function FleetAssignment({isHomepage}) {
  let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"success",rows:[]})
  const [count, setCount] = useState(0);
  const [fleetcode, setFleetcode] = useState("");
  const [fleetcode_match, setFleetcodeMatch] = useState(false);
  const [fleetcode_match_name, setFleetcodeMatchName] = useState("");
  const [current_fleets, updateCurrentFleets] = useState([])

  async function getCurrentFleets() {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"fleetassignment"
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateCurrentFleets(res['fleets'])
    }
    catch (e) {
        const res = {status:"API error - email us at info@joinago.com: "+toString(e)}
        updateRes(res)
    }
    updateIsLoading(false)
  }

  async function checkFleetcode() {
    if(fleetcode.length==4)
    {
      updateIsLoading(true)
      try {
          let session = await Auth.currentSession()      
          let accessToken = session.getAccessToken()
          let jwt = accessToken.getJwtToken()
          const myInit = {queryStringParameters:{ 
              "back_end": process.env.REACT_APP_BACKEND,
              "jwt":jwt,
              "fleetcode":fleetcode,
              "api_endpoint":"fleetassignment"
          }};
          const res = await API.get('adminWebTools','/ago',myInit)
          updateRes(res)
          setFleetcodeMatch(res['fleetcode_match'])
          setFleetcodeMatchName(res['fleetcode_match_name'])
      }
      catch (e) {
          const res = {status:"API error - email us at info@joinago.com: "+toString(e)}
          updateRes(res)
      }
      updateIsLoading(false)
    } else {
      const res = {status:"success"}
      updateRes(res)
    }
  }

  async function confirmFleetcode() {
    if(fleetcode.length==4)
    {
      updateIsLoading(true)
      try {
          let session = await Auth.currentSession()      
          let accessToken = session.getAccessToken()
          let jwt = accessToken.getJwtToken()
          const myInit = {queryStringParameters:{ 
              "back_end": process.env.REACT_APP_BACKEND,
              "jwt":jwt,
              "fleetcode":fleetcode,
              "confirmed":true,
              "api_endpoint":"fleetassignment"
          }};
          const res = await API.get('adminWebTools','/ago',myInit)
          updateRes(res)
          
          if(typeof isHomepage==='boolean')
          {
            if(isHomepage)
            {
              window.location.reload();
            }
          } else {
            setCount(count+1)
          }
      }
      catch (e) {
          //const res = {status:"API error - email us at info@joinago.com: "+toString(e)}
          //updateRes(res)
      }
      updateIsLoading(false)
    }
  }

  async function checkUrlForFleetCode()
  {
    var fleetCode = window.location.search;
    console.log('fleetCode start=' + fleetCode);
    window.location.pathname.replace("/","");
    console.log('fleetCode after step 2 = ' + fleetCode);
    fleetCode = fleetCode.replace("?fleet=","");
    console.log('fleetCode after step 3 = ' + fleetCode);
    fleetCode = fleetCode.replace("fleetassignment","");
    console.log('fleetCode after step 4 = ' + fleetCode);
    fleetCode = fleetCode.replace("/","");
    console.log('fleetCode complete = ' + fleetCode);
    setFleetcode(fleetCode);
  }

  function renderCurrentFleets()
  {
    if(!current_fleets){
      return;
    }
    return current_fleets.map(function(object, index){
      return(<p>You are already a member of the {object.display_name} fleet.</p>)
    })
  }
  useEffect(() => {
    getCurrentFleets();
  },[count])

  useEffect(() => {
    checkUrlForFleetCode();
  },[count])
  
  useEffect(() => {
    checkFleetcode();
  },[fleetcode])


  var FleetCodeConfirmButton = <p><button onClick={() => confirmFleetcode()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}}>Join Fleet: {fleetcode_match_name}</button></p>

  return (
    <main style={{ padding: "1rem" }}>
        <Heading level={2}>Join a Fleet</Heading>
        {current_fleets.length > 0 ? renderCurrentFleets() : undefined}
        <p>To join a fleet, enter your 4-digit fleet code below. Your 4-digit fleet code can be obtained from the owner of the car you're renting.</p>
        <p><input type='text' name='fleet_code' value={fleetcode} onChange={e => setFleetcode(e.target.value)}/></p><br/>
        {fleetcode_match ? FleetCodeConfirmButton : undefined}
        <br/>
        <p>If you are the OWNER of a fleet, please sign out now and contact support to configure your fleet login.</p>
        {is_loading ? <Loading /> : undefined}
        {res["status"]==='success' ? undefined : res['status']}
        <br/><br/>
    </main>
  );
}




